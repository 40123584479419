import { CQGConstants } from "../constants/CQGConstants";
import { OrderState } from "../models/OrderState";
import { OrdersManager } from "../services/OrdersManager";

export const makeOrder = (position: any) => {
  let orderData = {
    type: CQGConstants.getOrderTypeByString(position.orderType),
    displayOrderLimitPrice: position.rawLimitPrice,
    displayOrderStopPrice: position.rawStopPrice,
    duration: position.timeInForce,
    side: position.side === "BUY" ? 1 : 2,
    size: Math.abs(position.size),
    goodThruDate: new Date(),
    profitLossStrategy: position.profitLossStrategy,
    putCall: position.putCall,
    strikePrice: position.strikePrice,
    tradelog: position.tradelog,
  };

  const order = OrdersManager.createOrder(position.account, position.instrument, orderData);

  return order;
};

export const prepareAmendOrder = (orderState: OrderState, position: any) => {
  orderState.size = Math.abs(position.size);
  orderState.quantity = Math.abs(position.size);
  orderState.limitPrice = position.limitPrice;
  orderState.duration = position.duration;
  orderState.profitLossStrategy = position.profitLossStrategy;
  orderState.strikePrice = position.strikePrice;
  orderState.stopPrice = position.stopPrice;
  return orderState;
};
