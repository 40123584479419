import React from 'react';
import usePositions from '../widgets/positions/usePositions';
import './positions.css';
import FlattenPosition from './FlattenModal';
import { Row } from '../../types';
import { getPrice } from '../../utils/getPrice';
import { convertSymbolToYear, YearFormat } from '../tree-view/utils/convertSymbolToDate';

type FixedLastColumnTableProps = {
  dataRows: Row[];
}

const FixedLastColumnTable = ({ dataRows }: FixedLastColumnTableProps) => {
  const { handleFlattenPosition,
    closeFlattenDialog,
    openSingleFlattenDialog,
    handleConfirmFlatten,
    selectedPosition,
  } = usePositions();
  return (
    <>
      {
        dataRows?.map((row: Row) => (
          <div
            key={row.id}
            className='flex w-full justify-between row py-2'
            onClick={() => handleFlattenPosition(row)}
          >
            <div className='tableData'>
            <span className='text-xs symbolName'>{row.contract}</span>
            <span className='text-xs justify-end symbolName'>{row.strike === 0 ? "" : row.strike}</span>
            <span className='text-xs justify-end symbolName'>{row.direction}</span>
            <span className='text-xs justify-end'>{getPrice(row.realizedPL, row.unrealizedPL)}</span>
            <span className='text-xs sm-info'>{row.symbol} - {convertSymbolToYear(row.symbol, row.month, YearFormat.SHORT)}</span>
            <span className='text-xs justify-end symbolName'>{row.cp}</span>
            <span className='text-xs justify-end symbolName'>{row.size}</span>
            <span className='text-xs justify-end symbolName'>{row.averagePX}</span>

            </div>
          </div>))
      }
      <FlattenPosition
        handleConfirmFlatten={handleConfirmFlatten}
        data={selectedPosition}
        open={openSingleFlattenDialog}
        onClose={closeFlattenDialog}
      />
      </>
  );
};

export default FixedLastColumnTable;
