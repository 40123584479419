import { Position } from "../models/Position";
import * as _ from "../../vendors/underscore-esm";
import { Account } from "../models/Account";
import { PurchaseAndSalesGroup } from "../models/PurchaseAndSalesGroup";
import { MatchedTrade } from "../models/MatchedTrade";
import { Instrument } from "../models/Instrument";
import { DisplayUtil } from "../utils/DisplayUtil";
import { isNullOrUndefined } from "../utils/lib";
import { AggregatedPosition } from "../models/AggregatedPosition";
import { CurrencyRatesManager } from "../services/CurrencyRatesManager";
import { numberNullable } from "../types/Types";
import { CQGEnvironment } from "../services/CQGEnvironment";
import { OrderState } from "../models/OrderState";
import * as Order2 from "../proto/order_2";
import { OrdersManager } from "../services/OrdersManager";
import { CQGConstants } from "../constants/CQGConstants";
import { CQGService } from "../services/CQGService";

export class PositionsController {
  static getAggregatedPositions = (account: Account, accountPositions: Position[]) => {
    let aggregated: AggregatedPosition[] = this.filterAggregatePositions(account, accountPositions);
    // let hasOpenPosition = this.getOpenPositions(aggregated).length > 0;

    return _.sortBy(aggregated, (agg: AggregatedPosition) => {
      return -Math.abs(agg.size);
    });
  };

  static getOpenPositions = (aggregated: AggregatedPosition[]) => {
    return _.filter(aggregated, (p: AggregatedPosition) => {
      return this.isOpenPosition(p);
    });
  };

  private static isOpenPosition = (position: AggregatedPosition) => {
    return position.size && position.size !== 0;
  };

  private static filterAggregatePositions = (currentAccount: Account, allPositions: Position[]) => {
    let byContractId: { [key: number]: Position[] } = _.groupBy(allPositions, (position: Position) => {
      return position.getInstrument()?.contractId;
    });

    let aggregatedPositions = [];

    for (let contractId in byContractId) {
      if (isNullOrUndefined(contractId)) continue;

      let positionsBySymbol = byContractId[contractId];
      let totalPositions = 0;
      let aggregatedPosition = new AggregatedPosition(currentAccount);
      let displayLong = 0;
      let displayShort = 0;
      let displayOteMvo = 0;
      let displayUpl = 0;
      let displayMvo = 0;
      let displayRpl = null;
      let totalPrice = 0;
      let size = 0;
      let buys = 0;
      let sells = 0;
      let instrument!: Instrument;
      let account!: Account;
      let currencyRate: numberNullable = null;

      for (let pos in positionsBySymbol) {
        var position = positionsBySymbol[pos];
        var thisPositionSize = 0;

        if (!account) {
          account = position.getAccount();
        }

        if (position.displayOteMvo) displayOteMvo += position.displayOteMvo;
        if (position.displayUpl) displayUpl += position.displayUpl;
        if (position.displayMvo) displayMvo += position.displayMvo;

        if (position.displayLong) {
          size += position.displayLong;
          thisPositionSize = position.displayLong;
          displayLong += position.displayLong;

          if (position.tradeUtcTime) {
            buys += position.displayLong;
          }
        }

        if (position.displayShort) {
          size -= position.displayShort;
          thisPositionSize = position.displayShort;
          displayShort += position.displayShort;

          if (position.tradeUtcTime) {
            sells += position.displayShort;
          }
        }

        if (position.price) {
          if (!totalPrice) {
            totalPrice = 0;
          }
          totalPrice += position.price * thisPositionSize;
          totalPositions += thisPositionSize;
        }
        if (isNullOrUndefined(instrument)) {
          instrument = position.getInstrument()!;
        }
      }

      aggregatedPosition.contractId = instrument?.contractId;
      aggregatedPosition.displaySymbol = instrument?.displayName;
      aggregatedPosition.displayLong = size > 0 ? size : null;
      aggregatedPosition.displayShort = size < 0 ? -size : null;
      aggregatedPosition.displayOteMvo = displayOteMvo;
      aggregatedPosition.displayUpl = displayUpl;
      aggregatedPosition.displayMvo = displayMvo;
      aggregatedPosition.displayPrice = totalPrice
        ? DisplayUtil.toDisplayPrice(totalPrice / totalPositions, instrument)
        : null;
      aggregatedPosition.instrument = instrument;
      aggregatedPosition.account = account;
      aggregatedPosition.displayRpl = 0;
      aggregatedPosition.size = size;
      aggregatedPosition.buys = buys;
      aggregatedPosition.sells = sells;

      if (account) {
        var groups = _.filter(account.getPurchaseAndSalesGroups(), (group: PurchaseAndSalesGroup) => {
          return group.getInstrument()?.contractId == instrument.contractId;
        });

        _.forEach(groups, (group: PurchaseAndSalesGroup) => {
          aggregatedPosition.displayRpl += group.realizedProfitLoss;
          _.forEach(group.getMatchedTrades(), (matchedTrade: MatchedTrade) => {
            if (matchedTrade.isShort && matchedTrade.tradeUtcTime) {
              aggregatedPosition.sells += matchedTrade.size;
            } else if (!matchedTrade.isShort && matchedTrade.tradeUtcTime) {
              aggregatedPosition.buys += matchedTrade.size;
            }
          });
        });

        if (instrument) {
          currencyRate = CQGEnvironment.Instance.currencyRatesManager.getCurrencyRate(account, instrument.currency!);
        }

        currencyRate = currencyRate ? currencyRate : 1;
      }

      if (currencyRate) {
        aggregatedPosition.displayRpl = aggregatedPosition.displayRpl * currencyRate;
        aggregatedPosition.displayOteMvo = aggregatedPosition.displayOteMvo * currencyRate;
      }

      aggregatedPositions.push(aggregatedPosition);
    }

    return aggregatedPositions;
  };

  static makeFlattenOrder = (position: AggregatedPosition) => {
    const orderData = {
      type: Order2.Order_OrderType.ORDER_TYPE_MKT,
      duration: Order2.Order_Duration.DURATION_DAY,
      side: position.size < 0 ? Order2.Order_Side.SIDE_BUY : Order2.Order_Side.SIDE_SELL,
      size: Math.abs(position.size),
      goodThruDate: new Date(),
    };

    const order = OrdersManager.createOrder(position.account, position.instrument!, orderData);

    order.orderData = orderData;
    order.account = position.account;

    return order;
  };

  static flattenPosition = (position: AggregatedPosition) => {
    if (!position || _.isUndefined(position.size) || _.isNull(position.size) || position.size == 0) {
      return;
    }

    if (position.account && position.instrument) {
      const flattenOrder = this.makeFlattenOrder(position);
      const orderData = {
        isAmendOrder: false,
        isFlattenOrder: true,
        instrument: position.instrument,
        side: flattenOrder.orderData.side,
        qty: flattenOrder.orderData.size,
        type: flattenOrder.orderData.type,
        requiresLimitPrice: false,
        requiresStopPrice: false,
        tif: CQGConstants.getOrderDuration(flattenOrder.duration),
        account: flattenOrder.account,
        requiresDate: false,
        requiresTime: false,
        durationTime: flattenOrder.goodThruDate,
        tradelog: {},
        mainOrderTradeLog: {}, // TODO: Debug as mainOrderTradeLog has no other reference?
      };

      this.cancelOpenOrders(position);

      const order = OrdersManager.createOrder(position.account, position.instrument, {
        type: orderData.type,
        duration: flattenOrder.duration,
        side: orderData.side,
        size: orderData.qty,
        goodThruDate: orderData.durationTime,
        tradelog: orderData.mainOrderTradeLog,
      });

      this.placeOrderWithDelay(order, position.account);
    }
  };

  private static cancelOpenOrders = (position: AggregatedPosition) => {
    var cancellables = this.getCancellableOrders(position, position.instrument);
    if (cancellables.length > 0) {
      CQGService.cancelOrders(cancellables);
    }
  };

  private static getCancellableOrders = (position: AggregatedPosition, instrument?: Instrument) => {
    let cancellables = _.filter(position.account.getOrders(), (order: OrderState) => {
      return order.canBeCancelled;
    });

    if (this.isValid(instrument) && this.isValid(instrument?.contractId)) {
      cancellables = _.filter(cancellables, (order: OrderState) => {
        return (
          this.isValid(order.getInstrument()) &&
          this.isValid(order.getInstrument().contractId) &&
          order.getInstrument().contractId == instrument?.contractId
        );
      });
    }

    return cancellables;
  };

  private static isValid = (value: any) => {
    return !_.isNull(value) && !_.isUndefined(value);
  };

  private static placeOrderWithDelay(order: OrderState, account: Account) {
    setTimeout(() => {
      CQGEnvironment.Instance.cqgService.placeOrder(order, account);
    }, 2000);
  }

  static flattenAllPositions = (positions: AggregatedPosition[]) => {
    this.proceedWithFlattenAllPositions(positions);
  };

  private static proceedWithFlattenAllPositions = (positions: AggregatedPosition[]) => {
    const openPositions = this.getOpenPositions(positions);
    _.forEach(openPositions, (position: AggregatedPosition) => {
      this.cancelOpenOrders(position);
      this.placeOrderWithDelay(this.makeFlattenOrder(position), position.account);
    });
  };
}
