import { Tabs, Tab } from "@mui/material";
import { TradeTicketTab } from "../../../types";
import { tabStyles } from "../styles";
import OptionsTab from "./OptionsTab";
import { useMemo } from "react";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { SimulatorOptionsControllers } from "../../../cqg-api/controllers/SimulatorOptionsControllers";

interface FuturesOptionsTabProps {
  activeTab: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  amendOrder: OrderState | undefined;
  controller: SimulatorOptionsControllers | null;
  disableFuturesTab?: boolean;
}

const FuturesOptionsTab = ({ activeTab, handleTabChange, amendOrder, controller, disableFuturesTab }: FuturesOptionsTabProps) => {
  const hasOptions = useMemo(
    () => controller?.strikeOptions?.length && controller?.strikeOptions?.length > 0 && !amendOrder,
    [amendOrder, controller?.strikeOptions?.length],
  );

  return (
    <Tabs sx={tabStyles} value={activeTab} onChange={handleTabChange} className="tabs">
      <Tab label="Futures" value="futures" disabled={disableFuturesTab} />
      {hasOptions ? (
        <Tab label="Options" value="options" />
      ) : activeTab === TradeTicketTab.Futures ? (
        <OptionsTab isLoading={!!amendOrder ? "" : controller?.loadStatus!} />
      ) : (
        <Tab label="Options" value="options" />
      )}
    </Tabs>
  );
};

export default FuturesOptionsTab;
