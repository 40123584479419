const PlusIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1666 5.66634H6.16659V9.66634H4.83325V5.66634H0.833252V4.33301H4.83325V0.333008H6.16659V4.33301H10.1666V5.66634Z"
      fill="#081D37"
      fillOpacity="0.56"
    />
  </svg>
);

export default PlusIcon;
