import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";

const Goals = ({ isMobileView }: { isMobileView: boolean }) => {
  return (
    <Box
      component="section"
      className={clsx({
        "trade-plan-section": true,
        "trade-plan-section--mobile": isMobileView,
      })}
    >
      <Stack className="trade-plan-stack" sx={{ flexDirection: "row", gap: { xs: 1, xl: 10 } }}>
        <Typography className="trade-plan-text" sx={{ fontSize: "12px" }}>
          "Plan your trade and trade your plan” just got a lot easier to do and review. Take a few minutes right now to
          define how much to risk and what inputs to use in your trading. Then, follow-up regularly with the
          automatically pre-populated notes about each trade in your Trade Log, making adjustments to your Trade Plan as
          you learn more about your unique skill set, approach and tolerance for trading risk.
        </Typography>

        <Box className="trade-plan-goals">
          <Typography
            className="trade-plan-goals-title"
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              letterSpacing: "1px",
              fontFamily: "Averta-Regular",
              lineHeight: "17.6px",
            }}
          >
            GOALS OF THIS TRADE PLAN:
          </Typography>
          <Typography className="trade-plan-goals-item" sx={{ fontSize: "12px" }}>
            1. Why did I place the trade?
          </Typography>
          <Typography className="trade-plan-goals-item" sx={{ fontSize: "12px" }}>
            2. Why did I put my stop where I did?
          </Typography>
          <Typography className="trade-plan-goals-item" sx={{ fontSize: "12px" }}>
            3. Did my trade make money?
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default Goals;
