import { toast } from "react-toastify";
import { CircleChecked } from "../../../images/Icons";
import "./notification.css";
import { InstrumentsManager } from "../../../cqg-api/services/InstrumentsManager";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { Order_Type, OrderSide } from "../../../types";
import { OrderChain_OrderType } from "../../../cqg-api/proto/traderouting_1";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { Instrument } from "../../../cqg-api/models/Instrument";

interface AttributeGridProps {
  order: OrderState;
}

const Message = {
  FILLED: "FILLED"
};

export default class Notification {
  static success(message: string) {
    toast.success(`${message}`, {});
  }
  static error(message: string) {
    const toastId = "error-toast";
    if (!toast.isActive(toastId)) {
      toast.error(`${message}`, {
        toastId: toastId,
      });
    }
  }
  static warning(message: string) {
    toast.warn(message, {});
  }
  static info(message: string) {
    toast.info(message, {});
  }

  static orderInfo(createOrderState: OrderState, message?: string) {
    const instrument = InstrumentsManager.getInstrument(createOrderState.instrument.contractId!);
    const orderSideColor = createOrderState.displaySide === OrderSide.Sell ? "#D32F2F" : "#4CAF50";
    const fillInfo = message
      ? <AttributeGrid order={createOrderState} />
      : `${createOrderState.filledSize ?? 0} / ${createOrderState.displaySize} | ${Message.FILLED}`;

    const priceInfo = message
      ? createOrderState.limitPrice
        ? `Price: ${DisplayUtil.toDisplayPrice(createOrderState.limitPrice, instrument!)}`
        : ""
      : `Avg. Price: ${DisplayUtil.toDisplayPrice(createOrderState.avgFillPrice!, instrument!) ?? createOrderState.displayType}`;

    toast(
      <div>
        <div style={{ display: "flex", gap: "10px", fontFamily: "Averta-Regular" }}>
          <div>
            <CircleChecked />
          </div>
          <div className="notificationContent">
            <div className="notificationTitle">Order {message ? message : "Filled"}</div>
            <div className="notificationText">
              {createOrderState?.displayContract} |{" "}
              <span style={{ color: orderSideColor }}>
                {createOrderState?.displaySide}
              </span>{" "}
              {fillInfo}
            </div>
            <div className="notificationText">
              {priceInfo}
            </div>
          </div>
        </div>
      </div>,
      {},
    );
  }

  static orderAmendInfo(createOrderState: OrderState, message?: string) {
    const instrument = InstrumentsManager.getInstrument(createOrderState.instrument.contractId!)!;
    const orderSideColor = createOrderState.displaySide === OrderSide.Sell ? "#D32F2F" : "#4CAF50";
    const fillInfo = <AttributeGrid order={createOrderState as any} />

    toast(
      <div>
        <div style={{ display: "flex", gap: "10px", fontFamily: "Averta-Regular" }}>
          <div>
            <CircleChecked />
          </div>
          <div className="notificationContent">
            <div className="notificationTitle">Order {message}</div>
            <div className="notificationText">
              {createOrderState?.displayContract} |{" "}
              <span style={{ color: orderSideColor }}>
                {createOrderState?.displaySide}
              </span>{" "}
              {fillInfo}
            </div>
            <PriceInfo orderState={createOrderState} instrument={instrument} />
          </div>
        </div>
      </div>,
      {},
    );
  }
}

const AttributeGrid: React.FC<AttributeGridProps> = ({ order }) => {
  let orderType = Order_Type.MARKET;

  if (order.orderType === OrderChain_OrderType.LMT)
    orderType = Order_Type.LIMIT;

  if (order.orderType === OrderChain_OrderType.STP)
    orderType = Order_Type.STOP;

  return (
    <>
      <span> | {order.size}</span>
      <span> | {orderType}</span>
    </>
  );
};

const PriceInfo: React.FC<{ orderState: OrderState, instrument: Instrument }> = ({ orderState, instrument }) => {
  let price = orderState.limitPrice;

  if (orderState.orderType === OrderChain_OrderType.STP)
    price = orderState.stopPrice;

  let displayPrice = DisplayUtil.toDisplayPrice(price!, instrument);
  return <span>Price: {displayPrice}</span>;
};
