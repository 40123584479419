import { DockviewApi } from 'dockview';

interface WidgetDataValue {
  widgetName: string;
  data: any;
  contractMonth?: string;
}
export class PanelHelper{
  static panelDataUpdate = (dockviewApi: DockviewApi | undefined, widgetDataValue: WidgetDataValue | null) => {
    if (!widgetDataValue || !dockviewApi) return;
  
      const targetPanel = dockviewApi.panels.find((panel) => panel.params?.widget.id === widgetDataValue.widgetName);
  
      if (targetPanel && targetPanel.params) {  
        targetPanel.api.updateParameters({
          widget: {
            ...targetPanel.params.widget,
            widgetData: {
              ...targetPanel.params.widget.widgetData,
              data: widgetDataValue.data,
              ...(widgetDataValue.contractMonth && { contractMonth: widgetDataValue.contractMonth }), 
            },
          },
          workspaceId: targetPanel.params.workspaceId,
        });
      } else {
        console.log(`Panel title "${widgetDataValue.widgetName}" not found`);
      }
  };
};
