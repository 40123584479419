import { Mode_Type } from "../../../../types";
import TradeLogComponent from "../../components/TradeLog/TradeLogComponent";
import TradePlan from "../../components/tradePlan/TradePlan";

const Pages = ({
  onMenuItemClick,
  mode,
}: {
  onMenuItemClick: (component: JSX.Element | null) => void;
  mode: Mode_Type;
}) => {

  const handlePageClick = (text: string) => {
    let component = null;
    if (text === "Trade Log") {
      component = <TradeLogComponent onHandleClose={enableMenuItems} />;
    }
    if (text === "Trade Plan") {
      component = <TradePlan onHandleClose={enableMenuItems} />;
    }
    onMenuItemClick(component);
  };

  const enableMenuItems = () => {
    onMenuItemClick(null);
  };

  return (
    <div>
      {mode === Mode_Type.CHALLENGE_MODE && (
        <div className="mobile-profile-pages">
          <div>DASHBOARD</div>
        </div>
      )}
      <div className="mobile-profile-pages">
        <div>EDUCATION</div>
      </div>
      <div className="mobile-profile-pages">
        <div onClick={() => handlePageClick("Trade Plan")}>TRADE PLAN</div>
      </div>
      <div className="mobile-profile-pages">
        <div onClick={() => handlePageClick("Trade Log")}>TRADE LOG</div>
      </div>
    </div>
  );
};

export default Pages;
