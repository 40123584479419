import { Box, Link, Stack, Typography } from "@mui/material";
import ControlledRadioGroup from "../RadioGroup";
import TechnicalApproach from "../TechnicalApproach";
import ControlledCheckboxGroup from "../CheckboxGroup";
import { Control } from "react-hook-form";
import { TradeLogProps } from "../../../../../types";

interface TradingDecisionProps {
  control: Control<TradeLogProps, any>;
  handleApproachChange: (value: string) => void;
  approach: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  type: string;
}

const TradingDecision = ({ control, handleApproachChange, approach, setType, type }: TradingDecisionProps) => {
  return (
    <Box component="section" className="section-outer">
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: { xs: "flex-start", md: "flex-start" },
          gap: { xs: 1, md: 2, lg: 10 },
        }}
      >
        <Box className="section-left" sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box className="section-sub-left">
            <Typography className="section-title">Section B:</Typography>
            <Typography className="section-sub-title">Trading Decision Inputs</Typography>
          </Box>
          <Box className="section-sub-right">
            <Typography className="section-middle" fontSize="12px">
              What information do you use to make a trading decision? If you look to the news and statistics about a
              market's supply and demand, then you trade on fundamentals.
              <br /> If you look at price charts and indicators derived from the market's movement, then you trade on
              technicals. Or, you could use a bit of both. Choose from some of the most popular methods below to get
              started in your Trade Plan.
            </Typography>
            <Link href="#" underline="none">
              Learn more in Trading and Analysis.
            </Link>
          </Box>
        </Box>
        <Box>
          <Box marginBottom="32px">
            <ControlledRadioGroup
              control={control}
              title="Please select a type of decision inputs"
              options={["Fundamental", "Technical"]}
              name="approach"
              onChange={handleApproachChange}
            />
          </Box>
          {approach === "Fundamental" && (
            <ControlledCheckboxGroup
              control={control}
              title="What is the foundation of your analysis?"
              options={["Economic Reports", "Geopolitical Events", "Change in Supply", "Change in Demand"]}
              name="approachFundamentalAnalysis"
            />
          )}

          {approach === "Technical" && (
            <TechnicalApproach control={control} setType={setType} type={type} isOther={true} />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default TradingDecision;
