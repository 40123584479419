import { useState, useEffect, useCallback } from "react";

type VisibleRows = { first: HTMLElement | null; last: HTMLElement | null };

const useVisibleRows = (containerRef: React.RefObject<HTMLElement>) => {
  const [visibleRows, setVisibleRows] = useState<VisibleRows>({ first: null, last: null });

  const getVisibleRows = (container: HTMLElement): VisibleRows => {
    const containerRect = container.getBoundingClientRect();
    const tableRows = Array.from(container.querySelectorAll("tr"));

    const visibleRows = tableRows.filter((row) => {
      const rect = row.getBoundingClientRect();
      return rect.bottom >= containerRect.top && rect.top <= containerRect.bottom;
    });

    return visibleRows.length > 0
      ? { first: visibleRows[2] || null, last: visibleRows[visibleRows.length - 2] || null }
      : { first: null, last: null };
  };

  const updateVisibleRows = useCallback(() => {
    if (containerRef.current) {
      setVisibleRows(getVisibleRows(containerRef.current));
    }
  }, [containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => updateVisibleRows();

    container.addEventListener("scroll", handleScroll);

    const resizeObserver = new ResizeObserver(() => updateVisibleRows());
    resizeObserver.observe(container);

    updateVisibleRows(); // Initial update

    return () => {
      container.removeEventListener("scroll", handleScroll);
      resizeObserver.disconnect();
    };
  }, [containerRef, updateVisibleRows]);

  return { visibleRows };
};

export default useVisibleRows;
