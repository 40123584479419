import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Grid } from "@mui/material";
import "./Trade.css";
import ConfirmTradeDialog from "./ConfirmTradeDialog";
import SelectedContractInfo from "./SelectedContractInfo";
import { OrderState } from "../../cqg-api/models/OrderState";
import { makeOrder } from "../../cqg-api/controllers/tradingTicketController";
import { Instrument } from "../../cqg-api/models/Instrument";
import { useSelector } from "react-redux";
import { subscribeToContracts } from "../../utils/subscriptions";
import { useRealTimeMarketData } from "../../cqg-api/hooks/ServiceHooks";
import workspacesSelectors from "../../redux/workspaces/workspacesSelector";
import { createOrderStateObject } from "./utils";
import SimulatorOptions from "./OptionProcessor";
import PutCallSelector from "./TradeComponent/PutCallSelector";
import {
  gridItemStyles,
  StyledButton,
  StyledFormControl,
  StyledBlueBorderButton,
  StyledRedTextButton,
  ButtonNamedStyled,
  inputStyleBordered,
} from "./styles";
import SideController from "./components/SideController";
import { IFormInput } from "./types";
import { AvailableProductsContext } from "../../App";
import { SimulatorOptionsControllers } from "../../cqg-api/controllers/SimulatorOptionsControllers";
import { TradeTicketTab } from "../../types";
import Quantity from "./components/Quantity";
import OrderTypeSelect from "./components/OrderType";
import LimitPrice from "./components/LimitPrice";
import StopPrice from "./components/StopPrice";
import TakeProfitandLoss from "../widgets/priceLadder/OrderSettingsDialog/TakeProfitAndLoss/TakeProfitandLoss";
import TimeInForce from "./components/TimeInForce";
import useCreateOrder from "./hooks/useCreateOrder";
import useOrderTypeChange from "./hooks/useOrderTypeChange";
import useHandleFutureOrOptionTabChange from "./hooks/useHandleFutureOrOptionTabChange";
import useCustomizeTradeTicketUI from "./hooks/useCustomizeTradeTicketUI";
import useHandleStrikeAndOptionChange from "./hooks/useHandleStrikeAndOptionChange";
import { useTradeProps } from "./hooks/useTradeProps";
import useAmendOrder from "./hooks/useAmendOrder";
import useCreateDefaultFormValues from "./hooks/useCreateDefaultFormValues";
import ContractSelect from "./components/ContractSelect";
import FuturesOptionsTab from "./components/FuturesOptionsTab";
import FormErrors from "./components/FormErrors";

type TradeFormProps = {
  contracts?: Instrument[];
  workspaceId: number;
  externalData: any;
  expired?: boolean;
};

const TradeForm: React.FC<TradeFormProps> = (props) => {
  const defaultContract = useSelector(workspacesSelectors.getWorkspaces)[props.workspaceId]?.ticketInstrument;
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);
  const contracts = useMemo(() => {
    let contract: any;
    if(defaultContract && defaultContract.isOption()) {
      contract = monthlyContractsByDisplayNameMap[defaultContract?.cqgSymbol];
    } else {
     contract = monthlyContractsByDisplayNameMap[defaultContract?.displayName];
    }

    return contract?.monthlyContracts ?? [];
  }, [defaultContract?.displayName, monthlyContractsByDisplayNameMap]);
  const [disableFuturesTab, setDisableFuturesTab] = useState<boolean | undefined>(undefined);

  const { amendOrder, showCancelButton, showCancelOrderButton, canChangeInstrument, orderData, widgetData } =
    useAmendOrder(props.externalData);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: useCreateDefaultFormValues(defaultContract, orderData, amendOrder, props.externalData, widgetData),
  });

  const contractValue = watch("contract");
  const limitPrice = watch("limitPrice");
  const orderSide = watch("side");
  const orderType = watch("orderType");
  const rawLimitPrice = watch("rawLimitPrice");
  const rawStopPrice = watch("rawStopPrice");
  const putCallValue = watch("putCall");

  const [activeTab, setActiveTab] = useState("futures");
  const [createOrderState, setCreateOrderState] = useState<OrderState>();
  const [hasLoadError, setHasLoadError] = useState(false);
  const [controller, setController] = useState<SimulatorOptionsControllers | null>(null);

  const selectedInstrument = useMemo(
    () => contracts?.find((contract: Instrument) => contract.displayName === contractValue),
    [contracts, contractValue],
  );

  const [selectedContract, setSelectedContract] = useState<Instrument | null>(selectedInstrument);

  const { isEnabledLmtPrice, isEnabledStpPrice, getDefaultLimitPrice, getDefaultStopPrice } = useOrderTypeChange(
    selectedContract,
    amendOrder,
    orderType,
    orderSide,
    setValue,
    selectedContract?.lastPrice,
    defaultContract?.lastPrice,
    props.externalData,
    widgetData,
  );
  const { showConfirmTradeDialog, setShowConfirmTradeDialog, createOrder, cancelOrder, hideTradeTicket } =
    useCreateOrder(props.workspaceId);
  const { selectedStrike, setSelectedStrike, handleStrikeChange, handleOptionChange } = useHandleStrikeAndOptionChange(
    putCallValue,
    setSelectedContract,
    setValue,
    selectedContract,
  );
  useHandleFutureOrOptionTabChange(
    selectedInstrument,
    selectedContract,
    limitPrice,
    activeTab,
    setValue,
    getDefaultLimitPrice,
    getDefaultStopPrice,
    putCallValue,
    selectedStrike,
    contractValue,
    reset,
  );
  useCustomizeTradeTicketUI();

  useEffect(() => {
    if(defaultContract && defaultContract?.isOption()) {
      const contract = contracts.find((cont: Instrument) => cont.month === defaultContract.month)
      setValue("contract", contract.displayName);
      setTimeout(() => {
        setActiveTab("options");
        setValue('putCall', defaultContract.putCallAbbrev());
        setSelectedStrike(defaultContract.strikePrice);
      }, 100);
    } else {
      setValue("contract", defaultContract?.displayName);
    }
  }, [defaultContract, setValue, setActiveTab, setSelectedStrike]);

  useRealTimeMarketData();

  useEffect(() => {
    if (selectedInstrument && activeTab === TradeTicketTab.Futures) {
      subscribeToContracts([selectedInstrument.contractId] as number[]);
      setSelectedContract(selectedInstrument);
      setValue("limitPrice", 0);
    }
    if(activeTab === TradeTicketTab.Options) {
      setValue("limitPrice", 0);
    }
  }, [activeTab, selectedInstrument, setValue]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
    if (newValue === "futures") {
      setSelectedStrike(null);
    }
    if (newValue === "options") {
      setSelectedContract(null);
    }
  };

  useEffect(() => {
    const newController = new SimulatorOptionsControllers({
      addOrUpdate: (contractMetadata) => {},
      instruments: selectedInstrument,
    });
    setController(newController);
  }, [selectedInstrument]);

  useEffect(() => {
    if (controller && selectedInstrument) {
      controller.onShowOptions({ show: true, instrument: selectedInstrument });
    }
  }, [controller, selectedInstrument]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const instrument = activeTab === TradeTicketTab.Options ? selectedContract : selectedInstrument;
    const orderState = createOrderStateObject(
      data,
      selectedStrike,
      instrument,
      orderSide,
      selectedInstrument?.correctPriceScale,
    );
    setShowConfirmTradeDialog(true);
    const newOrder = makeOrder(orderState);
    setCreateOrderState(newOrder);
  };

  useEffect(() => {
    if (widgetData?.data?.putCall) {
      const { putCall, instrument, strikePrice, displayContract, limitPrice } = widgetData.data;
      setDisableFuturesTab(true);
      setActiveTab("options");
      setSelectedContract(instrument);
      setSelectedStrike(strikePrice);
      setValue('putCall', putCall);
      setValue('contract', displayContract);
      setValue('limitPrice', limitPrice);
    }
  }, [setSelectedStrike, setValue, widgetData?.data]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: "10px", maxWidth: "500px", color: "#222", overflowX: "hidden" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props?.expired ? (
            <div style={inputStyleBordered}><span style={{color: "red"}}>Invalid contract: {contractValue}</span></div>
          ) : (
            <ContractSelect control={control} contracts={contracts} canChangeInstrument={canChangeInstrument} widgetData={widgetData} />
          )}
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
          <SelectedContractInfo selectedContract={selectedContract} activeTab={activeTab} putCallValue={putCallValue} />
        </Grid>
        <Grid item xs={12}>
          <FormErrors errors={errors} />
        </Grid>
        <FuturesOptionsTab
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          amendOrder={amendOrder}
          controller={controller}
          disableFuturesTab={disableFuturesTab}
        />
        <Grid item xs={6} sx={gridItemStyles}>
          <SideController control={control} side={errors.side} setValue={setValue} disabled={!!amendOrder} />
        </Grid>
        <Grid item xs={6} sx={gridItemStyles}>
          <Quantity control={control} setValue={setValue} errors={errors} isLabel={true} />
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <TimeInForce control={control} isLabel={true} />
          </StyledFormControl>
        </Grid>
        <OrderTypeSelect control={control} activeTab={activeTab} amendOrder={amendOrder} />
        <LimitPrice
          control={control}
          watch={watch}
          errors={errors}
          isEnabledLmtPrice={isEnabledLmtPrice}
          activeTab={activeTab}
          setValue={setValue}
          selectedContract={selectedContract}
          selectedInstrument={selectedInstrument}
          rawLimitPrice={rawLimitPrice}
          limitPrice={limitPrice}
        />
        <StopPrice
          control={control}
          watch={watch}
          errors={errors}
          isEnabledStpPrice={isEnabledStpPrice}
          activeTab={activeTab}
          setValue={setValue}
          selectedInstrument={selectedInstrument}
          rawStopPrice={rawStopPrice}
        />
        {activeTab === TradeTicketTab.Futures && (
          <TakeProfitandLoss
            control={control}
            setValue={setValue}
            watch={watch}
            selectedContract={selectedInstrument}
            limitPrice={limitPrice}
          />
        )}
        <SimulatorOptions
          instrument={selectedInstrument}
          putCall={putCallValue}
          selectedStrike={selectedStrike}
          onStrikeChange={() => {}}
          onError={() => {}}
          hide
        />
        {activeTab === TradeTicketTab.Options && (
          <>
            <Grid item xs={6}>
              <StyledFormControl fullWidth>
                <label className="label">Put Call</label>
                <PutCallSelector
                  control={control}
                  putCallValue={putCallValue}
                  onOptionChange={handleOptionChange}
                  disabled={widgetData?.data?.putCall ? true : hasLoadError}
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={6}>
              <label className="label">Strike Price</label>
              <SimulatorOptions
                instrument={selectedInstrument}
                putCall={putCallValue}
                selectedStrike={selectedStrike}
                onStrikeChange={handleStrikeChange}
                onError={(hasError) => setHasLoadError(hasError)}
                widgetData={widgetData}
              />
            </Grid>
          </>
        )}
        {showCancelButton && (
          <Grid item xs={6} sx={gridItemStyles}>
            <StyledBlueBorderButton
              fullWidth
              onClick={() => {
                hideTradeTicket();
              }}
            >
              CANCEL
            </StyledBlueBorderButton>
          </Grid>
        )}
        <Grid item xs={!!showCancelButton ? 6 : 12} sx={gridItemStyles}>
          {!amendOrder && (
            <StyledButton fullWidth disabled={!selectedInstrument} type={!!selectedInstrument ? "submit" : "button"} orderSide={orderSide}>
              {orderSide ? orderSide.toUpperCase() : "SUBMIT"}
            </StyledButton>
          )}

          {!!amendOrder && (
            <ButtonNamedStyled fullWidth type="submit" styleName="AMEND">
              AMEND
            </ButtonNamedStyled>
          )}
        </Grid>

        {showCancelOrderButton && (
          <Grid item xs={12} sx={gridItemStyles}>
            <StyledRedTextButton
              fullWidth
              onClick={() => {
                cancelOrder(amendOrder);
              }}
            >
              CANCEL ORDER
            </StyledRedTextButton>
          </Grid>
        )}
      </Grid>
      <ConfirmTradeDialog
        open={showConfirmTradeDialog}
        onClose={() => setShowConfirmTradeDialog(false)}
        cancel={() => setShowConfirmTradeDialog(false)}
        confirm={() => createOrder(amendOrder, createOrderState)}
        title={!!amendOrder ? "Confirm Order Amend" : "Confirm Order"}
        trade={useTradeProps({
          watch,
          activeTab,
          selectedStrike,
          selectedContract,
          selectedInstrument,
        })}
      />
    </form>
  );
};

export default TradeForm;
