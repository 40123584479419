import clsx from "clsx";
import "./orders.css";
import OrdersList from "./OrdersList";
import FilterOrders from "./Filter";
import useOrders from "./useOrders";
import ConfirmCancelDialog from "../shared/ConfirmCancelDialog";

const OrdersWidget = () => {
  const {
    activeFilter,
    dataRows,
    setFilter,
    hasWorkingOrders,
    loading,
    openDialog,
    setOpenDialog,
    handleCancelAllOrders,
  } = useOrders();
  return (
    <div style={{ height: "100vh" }}>
      <div className="positions-header">
        <FilterOrders value={activeFilter} selectFilter={(val: number) => setFilter(val)} />
        <div
          className={clsx({
            "mobile-button cancel-all-orders": true,
            disabled: !hasWorkingOrders,
          })}
          onClick={hasWorkingOrders ? () => setOpenDialog(true) : undefined}
        >
          CANCEL ALL
        </div>
      </div>
      <OrdersList dataRows={dataRows} />
      {openDialog && (
        <ConfirmCancelDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={handleCancelAllOrders}
          loading={loading}
          title="Confirm Order Cancellation"
          message="Are you sure you want to cancel all orders?"
        />
      )}
    </div>
  );
};
export default OrdersWidget;
