import { useMemo } from "react";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";

const UserInfo = () => {
  const currentUser = useGetCurrentUser();
  const firstName = currentUser?.firstName || "";
  const lastName = currentUser?.lastName || "";
  const userName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
  const profileInitial = useMemo(() => `${firstName.charAt(0).toUpperCase()}`, [firstName]);
  return (
    <div className="profile-name-container">
      <div className="profile-icon">{profileInitial}</div>
      <div className="profile-text">
        <span className="profile-name">{userName}</span>
      </div>
    </div>
  );
};

export default UserInfo;
