import React, { CSSProperties } from "react";
import { Controller, Control } from "react-hook-form";
import { IFormInput } from "../types";

interface PutCallSelectorProps {
  control:  Control<IFormInput, any>;
  putCallValue?: "P" | "C";
  onOptionChange: (option: "P" | "C") => void;
  disabled?: boolean;
}

const PutCallSelector: React.FC<PutCallSelectorProps> = ({
  control,
  putCallValue,
  onOptionChange,
  disabled,
}) => {
  const disabledStyle: CSSProperties | undefined = disabled
    ? { pointerEvents: "none", opacity: 0.5 }
    : undefined;

  return (
    <Controller
      name="putCall"
      control={control}
      rules={{
        required: 'Put/Call or Strike is invalid',
      }}
      render={({ field }) => (
        <div className="timeInForce">
          <button
            type="button"
            onClick={() => onOptionChange("P")}
            className={`timeInDay ${putCallValue === "P" ? "active" : ""}`}
            style={disabledStyle}
            disabled={disabled}
          >
            PUT
          </button>
          <button
            type="button"
            onClick={() => onOptionChange("C")}
            className={`timeInDay ${putCallValue === "C" ? "active" : ""}`}
            style={disabledStyle}
            disabled={disabled}
          >
            CALL
          </button>
        </div>
      )}
    />
  );
};

export default PutCallSelector;
