import { InstrumentMarketData } from "../../../types";
import InstrumentModal from "./MarketMobileModal";
import { useState } from "react";
import "./simpleMobileWidget.css";
import { subscribeToContracts } from "../../../utils/subscriptions";
import RenderColorCell from "./RenderColorCell";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { Contract } from "./types";
interface SimpleMobileWidgetProps {
  rows: InstrumentMarketData[];
}

const SimpleMobileWidget = ({ rows }: SimpleMobileWidgetProps) => {
  const [selectedInstrument, setSelectedInstrument] = useState<InstrumentMarketData | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (instrument: InstrumentMarketData) => {
    const contractIds = Array.isArray(instrument?.monthlyContracts)
      ? instrument?.monthlyContracts.map((contract: Contract) => contract.contractId)
      : [];
  
    subscribeToContracts(contractIds);
    setSelectedInstrument(instrument);
    setModalOpen(true);
  };
  

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const changeCell = (row: InstrumentMarketData) => {
    const stateOfRow = Array.isArray(row?.monthlyContracts)
      ? (row?.monthlyContracts[0] ?? null)
      : (row?.monthlyContracts ?? null);

    const color = stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";

    if (stateOfRow?.labelPriceNetChange) {
      return (
        <div style={{ color }}>
          <div>{stateOfRow?.labelPriceNetChange?.toFixed(2)}</div>
          <div>{`(${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</div>
        </div>
      );
    }

    return <span></span>;
  };

  const getContractName = (instrument: any) => {
    return (
      <span className="contract-name">
        {`${instrument?.monthlyContracts[0]?.displayName} - ${instrument?.monthlyContracts[0]?.month.toUpperCase()} ${instrument?.monthlyContracts[0]?.title.substr(instrument?.monthlyContracts[0]?.title.length - 2)}`}
      </span>
    );
  };

  return (
    <div className="simple-table">
      {rows.map((row) => (
        <div key={row.id} className="flex w-full justify-between row py-2" onClick={() => handleOpenModal(row)}>
          <div className="tableDataMarket">
            <div className="column column1">
              <span className="text-sm">{row?.name}</span>
              <span className="text-xs">{getContractName(row)}</span>
            </div>
            <div className="column column2">
              <span className="text-xs">
                {row?.monthlyContracts && Array.isArray(row?.monthlyContracts) && row?.monthlyContracts[0] ? (
                  <RenderColorCell
                    value={DisplayUtil.toDisplayPrice(row?.monthlyContracts[0]?.lastPrice, row?.monthlyContracts[0])}
                  />
                ) : (
                  <span>No Price Available</span>
                )}
              </span>
              <span className="text-xs">
                {row?.monthlyContracts && Array.isArray(row?.monthlyContracts) && row?.monthlyContracts[0] ? (
                  row?.monthlyContracts[0]?.labelTotalVolume
                ) : (
                  <span>No Volume Available</span>
                )}
              </span>
            </div>
            <div className="column column3">
              <span className="text-xs sm-info">{changeCell(row)}</span>
            </div>
          </div>
        </div>
      ))}
      {selectedInstrument && (
        <InstrumentModal
          open={modalOpen}
          onClose={handleCloseModal}
          instrument={selectedInstrument}
          contract={Array.isArray(selectedInstrument?.monthlyContracts) ? selectedInstrument?.monthlyContracts : []}
        />
      )}
    </div>
  );
};

export default SimpleMobileWidget;
