import { IconButton } from "@mui/material";
import { settingsIconStyles } from "./PriceLadderStyles";
import SettingsIcon from "@mui/icons-material/Settings";

const OrderSettings = ({
  onClick,
  validOco,
}: {
  onClick: () => void;
  validOco: () => boolean;
}) => {
  const handleOpenOrderSettingsClick = () => {
    onClick();
  };

  const isValidOco = validOco();

  return (
    <>
      <IconButton onClick={() => handleOpenOrderSettingsClick()}>
        <SettingsIcon style={settingsIconStyles(isValidOco)} />
      </IconButton>
    </>
  );
};

export default OrderSettings;
