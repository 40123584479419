import { Control, Controller, UseFormWatch } from "react-hook-form";
import { toggleButtonStyles } from "../../../../TradeTicketDrawer/styles";
import { Grid, Switch } from "@mui/material";
import Tick from "./Tick";
import Price from "./Price";
import ProfitLoss from "./ProfitLoss";
import Percentage from "./Percentage";
import { useCallback, useEffect } from "react";
import { DisplayUtil } from "../../../../../cqg-api/utils/DisplayUtil";
import { Instrument } from "../../../../../cqg-api/models/Instrument";
import useTickChange from "./hooks/useTickChange";
import usePriceChange from "./hooks/usePriceChange";
import useProfitLossChange from "./hooks/useProfitLossChange";
import usePercentageChange from "./hooks/usePercentageChange";
import { OrderSide, OrderType } from "../../../../../types";
import { IFormInput } from "../../../../TradeTicketDrawer/types";

interface TakeProfitandLossSectionProps {
  control: Control<IFormInput, any>;
  setValue: any;
  watch: UseFormWatch<IFormInput>;
  orderSide: OrderSide;
  label: string;
  sectionKey: "takeProfit" | "stopLoss";
  selectedContract: Instrument;
  limitPriceValue?: number;
}

const TakeProfitandLossSection = ({
  control,
  setValue,
  watch,
  orderSide,
  label,
  sectionKey,
  selectedContract,
  limitPriceValue,
}: TakeProfitandLossSectionProps) => {
  const isSelected = watch(`oco.${orderSide}.${sectionKey}.isSelected`);
  const limitPrice =
    limitPriceValue ??
    (parseFloat(DisplayUtil.toDisplayPrice(Number(selectedContract?.lastPrice), selectedContract)?.toString() || "") ||
      0);
  const tickSize = selectedContract?.rawTickSize ?? 0;
  const quantity = watch("quantity");
  const orderType = watch("orderType");

  const getSign = useCallback(
    (isProfit: boolean) => (orderSide === OrderSide.Buy ? (isProfit ? 1 : -1) : isProfit ? -1 : 1),
    [orderSide],
  );

  const initializeData = useCallback(
    (isProfit: boolean, ticks: number) => {
      if (!selectedContract) return;

      const sign = getSign(isProfit);
      const startPrice = DisplayUtil.rawStepPrice(limitPrice, selectedContract, sign * ticks);
      const diff = Math.abs(limitPrice - startPrice);
      const pnl = (selectedContract.multiplier ?? 0) * quantity * diff;
      const percent = (diff / limitPrice) * 100;

      const data = {
        price: startPrice,
        ticks,
        pnl: Math.round(pnl * 100) / 100,
        percent: Math.round(percent * 100) / 100,
        displayPrice: parseFloat(DisplayUtil.toDisplayPrice(startPrice, selectedContract)?.toString() || "") || 0,
      };

      const key = isProfit ? "takeProfit" : "stopLoss";
      setValue(`oco.${orderSide}.${key}.tick`, data.ticks);
      setValue(`oco.${orderSide}.${key}.price`, data.displayPrice);
      setValue(`oco.${orderSide}.${key}.${isProfit ? "profit" : "loss"}`, data.pnl);
      setValue(`oco.${orderSide}.${key}.${isProfit ? "profitPercentage" : "lossPercentage"}`, data.percent);
    },
    [getSign, limitPrice, quantity, selectedContract, setValue, orderSide],
  );

  const handlers = {
    onTickChange: useTickChange(limitPrice, quantity, orderSide, sectionKey, selectedContract, setValue, getSign),
    onPriceChange: usePriceChange(limitPrice, quantity, orderSide, sectionKey, selectedContract, setValue),
    onProfitLossChange: useProfitLossChange(
      limitPrice,
      quantity,
      orderSide,
      sectionKey,
      selectedContract,
      setValue,
      getSign,
    ),
    onPercentageChange: usePercentageChange(
      limitPrice,
      quantity,
      orderSide,
      sectionKey,
      selectedContract,
      setValue,
      getSign,
    ),
  };

  const handleSwitchChange = (checked: boolean) => {
    if (!checked) return;

    const isProfit = sectionKey === "takeProfit";
    const ticks = isProfit ? 75 : 25;
    initializeData(isProfit, ticks);
  };

  useEffect(() => {
    if (limitPriceValue) {
      let price = selectedContract
        ? DisplayUtil.rawStepPrice(
            limitPrice ?? 0,
            selectedContract,
            getSign(sectionKey === "takeProfit") * (watch(`oco.${orderSide}.${sectionKey}.tick`) ?? 0),
          )
        : 0;

      const displayPrice = parseFloat(DisplayUtil.toDisplayPrice(price, selectedContract)?.toString() || "0") || 0;
      setValue(`oco.${orderSide}.${sectionKey}.price`, displayPrice);
    }
  }, [getSign, limitPrice, limitPriceValue, orderSide, sectionKey, selectedContract, setValue, watch]);

  useEffect(() => {
    if (limitPriceValue) {
      let price = selectedContract
        ? DisplayUtil.rawStepPrice(
            limitPrice ?? 0,
            selectedContract,
            getSign(sectionKey === "takeProfit") * (watch(`oco.${orderSide}.${sectionKey}.tick`) ?? 0),
          )
        : 0;
      const pnl =
        quantity && selectedContract?.multiplier
          ? Math.round(selectedContract.multiplier * quantity * Math.abs(limitPrice - price) * 100) / 100
          : 0;
      const isProfit = sectionKey === "takeProfit" ? "profit" : "loss";
      setValue(`oco.${orderSide}.${sectionKey}.${isProfit}`, pnl);
    }
  }, [getSign, limitPrice, limitPriceValue, orderSide, quantity, sectionKey, selectedContract, setValue, watch]);

  return (
    <>
      <Grid item xs={12} sx={toggleButtonStyles}>
        <label>{label}</label>
        <Controller
          name={`oco.${orderSide}.${sectionKey}.isSelected`}
          disabled={!watch("side") || orderType === OrderType.Stl || orderType === OrderType.Stp}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              sx={{ marginRight: "-12px" }}
              onChange={(e) => {
                field.onChange(e.target.checked);
                handleSwitchChange(e.target.checked);
              }}
            />
          )}
        />
      </Grid>
      {isSelected && (
        <>
          <Tick
            control={control}
            setValue={setValue}
            name={`oco.${orderSide}.${sectionKey}.tick`}
            onTickChange={handlers.onTickChange}
          />
          <Price
            control={control}
            setValue={setValue}
            name={`oco.${orderSide}.${sectionKey}.price`}
            tickSize={tickSize}
            onPriceChange={handlers.onPriceChange}
            selectedContract={selectedContract}
          />
          <ProfitLoss
            control={control}
            setValue={setValue}
            name={`oco.${orderSide}.${sectionKey}.${sectionKey === "takeProfit" ? "profit" : "loss"}`}
            orderSide={orderSide}
            onProfitLossChange={handlers.onProfitLossChange}
          />
          <Percentage
            control={control}
            setValue={setValue}
            name={`oco.${orderSide}.${sectionKey}.${sectionKey === "takeProfit" ? "profitPercentage" : "lossPercentage"}`}
            onPercentageChange={handlers.onPercentageChange}
          />
        </>
      )}
    </>
  );
};

export default TakeProfitandLossSection;
