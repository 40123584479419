import { Grid, TextField } from "@mui/material";
import { defaultBorderColor, gridItemStyles, inputStyles, redErrorColor } from "../styles";
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import MinusIcon from "../MinusIcon";
import { insertNumberInput, numbersWithDecimalRules } from "../utils";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import PlusIcon from "../PlusIcon";
import { TradeTicketTab } from "../../../types";
import { IFormInput } from "../types";

interface StopPriceProps {
  control: Control<IFormInput, any>;
  watch: UseFormWatch<IFormInput>;
  errors: FieldErrors<IFormInput>;
  isEnabledStpPrice: boolean;
  activeTab: string;
  setValue: UseFormSetValue<IFormInput>;
  selectedInstrument: any;
  rawStopPrice: number;
}

const StopPrice = ({
  control,
  watch,
  errors,
  isEnabledStpPrice,
  activeTab,
  setValue,
  selectedInstrument,
  rawStopPrice,
}: StopPriceProps) => {
  const updateStopPrice = (step: number, fieldName: "stopPrice") => {
    const sPrice = DisplayUtil.rawStepPrice(Number(rawStopPrice), selectedInstrument, step);
    const stopPrice = parseFloat(DisplayUtil.toDisplayPrice(sPrice, selectedInstrument)?.toString() || "") || 0;

    if (stopPrice >= 0) {
      setValue("rawStopPrice", sPrice);
      setValue(fieldName, stopPrice);
    }
  };

  const handleInputChange = (e: any) => {
    insertNumberInput(e, true);
    setValue("rawStopPrice", Number(e.target.value));
  };

  return (
    <Grid item xs={6} sx={gridItemStyles} style={{ marginBottom: "8px" }}>
      <label className="label">Stop Price</label>
      <div className="control" style={{ borderColor: errors.stopPrice ? redErrorColor : defaultBorderColor }}>
        <Controller
          name="stopPrice"
          control={control}
          rules={{
            required: ["STP", "STL"].includes(watch("orderType")) ? "Stop Price is required" : false,
            pattern: numbersWithDecimalRules.pattern,
          }}
          render={({ field }) => (
            <button
              type="button"
              className="minusButton"
              disabled={!isEnabledStpPrice}
              onClick={() => updateStopPrice(-1, field.name)}
            >
              <MinusIcon />
            </button>
          )}
        />
        <Controller
          name="stopPrice"
          control={control}
          rules={numbersWithDecimalRules}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={activeTab === TradeTicketTab.Options ? "" : field.value}
              disabled={!isEnabledStpPrice}
              onInput={handleInputChange}
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />
        <Controller
          name="stopPrice"
          control={control}
          render={({ field }) => (
            <button
              type="button"
              className="minusButton"
              disabled={!isEnabledStpPrice}
              onClick={() => updateStopPrice(1, field.name)}
            >
              <PlusIcon />
            </button>
          )}
        />
      </div>
    </Grid>
  );
};

export default StopPrice;
