import { TextField } from "@mui/material";
import { defaultBorderColor, inputStyles, redErrorColor } from "../styles";
import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import MinusIcon from "../MinusIcon";
import PlusIcon from "../PlusIcon";
import { onlyNumbersRules } from "../../widgets/priceLadder/utils";
import { IFormInput } from "../types";
import { insertNumberInput } from "../utils";
import clsx from "clsx";

interface QuantityProps {
  control: Control<IFormInput, any>;
  setValue: UseFormSetValue<IFormInput>;
  errors: FieldErrors<IFormInput>;
  isLabel?: boolean;
  isSmallPanel?: boolean;
}

const Quantity = ({ control, setValue, errors, isLabel, isSmallPanel }: QuantityProps) => {
  const handleDecrement = (field: any) => {
    const currentValue = field.value - 1;
    if (currentValue >= 1) {
      setValue(field.name, currentValue);
    } else if (isNaN(currentValue)) {
      setValue(field.name, 1);
    }
  };

  const handleIncrement = (field: any) => {
    const currentValue = parseInt(field.value) + 1;
    if (currentValue <= 10) {
      setValue(field.name, currentValue);
    } else if (isNaN(currentValue)) {
      setValue(field.name, 1);
    }
  };

  const handleInputChange = (e: any) => {
    const val = e.target.value;
    if (val <= 10 && val >= 1) {
      insertNumberInput(e);
    } else {
      e.target.value = val.slice(0, val.length - 1);
    }
  };

  return (
    <>
      {isLabel && <label className="label">Quantity (1-10)</label>}

      <div
        className={clsx({
          control: true,
          "price-ladder-width": !isLabel,
        })}
        style={{ borderColor: errors.quantity ? redErrorColor : defaultBorderColor }}
      >
        <Controller
          name="quantity"
          control={control}
          rules={{
            required: "Quantity is required",
            min: { value: 1, message: "Minimum quantity is 1" },
          }}
          render={({ field }) => (
            <button
              type="button"
              className={ isSmallPanel ? "minusButtonNoPadding" : "minusButton"}
              onClick={() => handleDecrement(field)}
            >
              <MinusIcon />
            </button>
          )}
        />

        <Controller
          name="quantity"
          rules={onlyNumbersRules}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              onInput={handleInputChange}
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />

        <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <button
              type="button"
              className={ isSmallPanel ? "minusButtonNoPadding" : "minusButton"}
              onClick={() => handleIncrement(field)}
            >
              <PlusIcon />
            </button>
          )}
        />
      </div>
    </>
  );
};

export default Quantity;
