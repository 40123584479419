import * as WebAPI from "../proto/webapi_2";
import * as UserSession from "../proto/user_session_2";
import * as Metadata from "../proto/metadata_2";
import * as MarketData from "../proto/market_data_2";
import * as Historical2 from "../proto/historical_2";
import * as Order2 from "../proto/order_2";
import * as TradingAccount from "../proto/trading_account_2";

import * as _ from "../../vendors/underscore-esm";

import { isArrayWithValues } from "../utils/lib";
import { CQGEnvironment } from "./CQGEnvironment";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";
import { InstrumentsManager } from "./InstrumentsManager";
import { SymbolResolutionSubscription, SymbolResolutionSubscriptions } from "./SymbolResolutionSubscriptions";
import { InstrumentSubscriptions } from "./InstrumentSubscriptions";
import { Instrument } from "../models/Instrument";
import { WSErrorCodes } from "../constants/WSErrorCodes";
import { TimeUtil } from "../utils/TimeUtil";
import { OrderState } from "../models/OrderState";
import { SymbolsByUnderlyingSubscriptions, UnderlyingSubscription } from "./SymbolsUnderlyingSubscriptions";
import { ChildSymbolsSubscription, ChildSymbolsSubscriptions } from "./ChildSymbolsSubscriptions";
import { AtTheMoneyStrikeSubscriptions } from "./AtTheMoneySrikeSubscriptions";
import { AccountSummariesManager } from "./AccountSummariesManager";
import { Account } from "../models/Account";
import Notification from "../../../src/components/shared/notification/Notification";

export class CQGService {
  private socket?: WebSocket;
  private isLoggedIn = false;
  private socketUrl = "wss://api.cqg.com";
  private isUserLogout = false;
  private static instance: CQGService | null = null;
  public onOpen?: (event: Event) => void;
  public onMessage?: (event: WebAPI.ServerMsg) => void;
  public onClose?: (event: CloseEvent) => void;
  public onError?: (event: Event) => void;
  private env!: CQGEnvironment;
  private serviceMessageEventManager!: CQGServiceMessageEventManager;

  constructor(env: CQGEnvironment, serviceMessageEventManager: CQGServiceMessageEventManager) {
    if (CQGService.instance) {
      return CQGService.instance;
    }

    this.env = env;
    this.serviceMessageEventManager = serviceMessageEventManager;
    CQGService.instance = this;

    this.initSocket();
  }

  private initSocket() {
    console.log("Connecting to WebSocket ...");
    this.socket = new WebSocket(this.socketUrl);
    this.socket.onopen = this.onWsOpen.bind(this);
    this.socket.onmessage = this.onWsMessage.bind(this);
    this.socket.onclose = this.onWsClose.bind(this);
    this.socket.onerror = this.onWsError.bind(this);
  }

  private closeSocket() {
    console.log("Closing connection ...");
    this.socket?.close();
    delete this.socket;
  }

  private onWsOpen(event: Event) {
    console.log("WebSocket connection opened");

    this.initiateLogon();

    if (this.onOpen) this.onOpen(event);
  }

  private async onWsMessage(event: MessageEvent) {
    const data = await event.data.arrayBuffer();
    const msg = WebAPI.ServerMsg.decode(new Uint8Array(data));

    logIncomingMessage(msg);

    if (!!msg.logonResult) {
      if (msg.logonResult?.resultCode === 0) {
        this.isLoggedIn = true;
        console.log("Logged in successfully!");

        this.env.setLogonResult(msg.logonResult);

        this.serviceMessageEventManager.serviceReadyEventEmit(msg.logonResult);
      }

      if (!!msg.logonResult && msg.logonResult?.resultCode !== 0) {
        console.log("Login failed!", msg.logonResult);
        this.isLoggedIn = false;
        this.socket?.close();
      }

      return;
    }

    if (msg?.loggedOff) {
      console.log("Logged Off ...", msg?.loggedOff);
      this.isLoggedIn = false;
      return;
    }

    if (!!msg?.collateralStatuses && isArrayWithValues(msg.collateralStatuses)) {
      this.serviceMessageEventManager.collateralStatusesEventEmit(msg.collateralStatuses);
    }

    if (!!msg?.tradeSnapshotCompletions && isArrayWithValues(msg.tradeSnapshotCompletions)) {
      // TODO: To be reviewed -
      // There are few instances where tradeSnapshotCompletions comes first and then Orders or Positios are coming.
      // Adding dealy to make sure Orders or Positios are processed first and the scope completion.
      setTimeout(() => {
        this.serviceMessageEventManager.tradeSnapshotCompletionsEventEmit(msg.tradeSnapshotCompletions);
      }, 100);
    }

    if (!!msg?.positionStatuses && isArrayWithValues(msg.positionStatuses)) {
      this.serviceMessageEventManager.positionStatusesEventEmit(msg.positionStatuses);
    }

    if (!!msg?.informationReports && isArrayWithValues(msg.informationReports)) {
      this.serviceMessageEventManager.informationReportsEventEmit(msg.informationReports);
    }

    if (!!msg?.marketDataSubscriptionStatuses && isArrayWithValues(msg.marketDataSubscriptionStatuses)) {
      this.serviceMessageEventManager.marketDataSubscriptionStatusesEventEmit(msg.marketDataSubscriptionStatuses);
    }

    if (!!msg?.realTimeMarketData && isArrayWithValues(msg.realTimeMarketData)) {
      this.serviceMessageEventManager.realTimeMarketDataEventEmit(msg.realTimeMarketData);
    }

    if (!!msg?.orderStatuses && isArrayWithValues(msg.orderStatuses)) {
      this.serviceMessageEventManager.orderStatusesEventEmit(msg?.orderStatuses);
    }

    if (!!msg?.tradeSubscriptionStatuses && isArrayWithValues(msg.tradeSubscriptionStatuses)) {
      this.serviceMessageEventManager.tradeSubscriptionStatusEventEmit(msg.tradeSubscriptionStatuses);
    }

    if (!!msg?.timeBarReports && isArrayWithValues(msg.timeBarReports)) {
      _.forEach(msg.timeBarReports, (report: Historical2.TimeBarReport) => {
        var request = this._timeBarRequestIdToRequest[report.requestId];

        if (!_.isUndefined(request)) {
          _.forEach(
            this._timeBarWatchers,
            (
              watcher: (
                report: Historical2.TimeBarReport,
                contractId: number | undefined,
                barUnit: number | undefined,
                unitNumber: number | undefined,
              ) => void,
            ) => {
              watcher(
                report,
                request.timeBarParameters?.contractId,
                request.timeBarParameters?.barUnit,
                request.timeBarParameters?.unitNumber,
              );
            },
          );
        } else {
          //$log.warn( "TimeBarRequest not found for request id: " + report.request_id );
        }
      });

      this.serviceMessageEventManager.timeBarReportsEventEmit(msg.timeBarReports);
    }

    if (this.onMessage) this.onMessage(msg);
  }

  private onWsClose(event: CloseEvent) {
    const error = WSErrorCodes[event.code];
    console.log("WebSocket connection closed. Reason:", error, event);

    this.isLoggedIn = false;

    this.serviceMessageEventManager.connectionCloseEventEmit();

    if (!this.isUserLogout) {
      this.closeSocket();

      // If any cleanup is required outside.
      if (this.onClose) this.onClose(event);

      // console.log("Reconnecting ...");
      // this.initSocket();
    }
  }

  private onWsError(event: Event) {
    console.error("WebSocket error:", event);
    if (this.onError) this.onError(event);
  }

  public send(message: WebAPI.ClientMsg): Error | void {
    logOutgoingMessage(message);
    // console.log("Sending message:", message);
    const encoded = WebAPI.ClientMsg.encode(message).finish();
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(encoded);
    } else {
      return new Error("Connection is not ready");
    }
  }

  public close() {
    this.closeSocket();
  }

  public get isReady(): boolean {
    return this.isLoggedIn && this.socket?.readyState === WebSocket.OPEN;
  }

  private initiateLogon() {
    let clMsg = WebAPI.ClientMsg.create();
    let logonMsg = UserSession.Logon.create();
    logonMsg.userName = this.env.accountAuthInfo?.username;
    logonMsg.password = this.env.accountAuthInfo?.password;
    logonMsg.clientAppId = "CMEInstitute";
    logonMsg.clientVersion = "1.1.5038.15046";
    clMsg.logon = logonMsg;
    this.send(clMsg);
  }

  public setupWebsocketConnection() {
     this.initSocket();
  }

  //#region Market Watchers

  // TODO: Inspect and refactor this section.
  private marketWatchers: ((insts: Instrument[]) => void)[] = [];
  private _timeBarWatchers: ((
    report: Historical2.TimeBarReport,
    contractId: number | undefined,
    barUnit: number | undefined,
    unitNumber: number | undefined,
  ) => void)[] = [];
  private _timeBarRequestIdToRequest: { [key: number]: Historical2.TimeBarRequest } = {};

  // TODO: This is is not as per event model.
  registerMarketWatcher = (watcher: (insts: Instrument[]) => void) => {
    if (!this.marketWatchers.includes(watcher)) {
      this.marketWatchers.push(watcher);
    }
  };

  unregisterMarketWatcher = (watcher: never) => {
    if (this.marketWatchers.includes(watcher)) {
      this.marketWatchers = this.marketWatchers.filter((item) => item !== watcher);
    }
  };

  // TODO: This is is not as per event model.
  registerTimeBarWatcher = (
    watcher: (
      report: Historical2.TimeBarReport,
      contractId: number | undefined,
      barUnit: number | undefined,
      unitNumber: number | undefined,
    ) => void,
  ) => {
    if (!this._timeBarWatchers.includes(watcher)) {
      this._timeBarWatchers.push(watcher);
    }
  };

  processMarketDataSubscriptionStatus = (marketDataSubscriptionStatus: MarketData.MarketDataSubscriptionStatus[]) => {
    InstrumentSubscriptions.Instance.processMarketDataSubscriptionStatus(marketDataSubscriptionStatus);
  };

  processRealTimeMarketData = (realTimeMarketData: MarketData.RealTimeMarketData[]): Instrument[] => {
    var instruments = InstrumentsManager.processRealTimeMarketData(realTimeMarketData);

    _.forEach(this.marketWatchers, (watcher: (insts: Instrument[]) => void) => {
      watcher(instruments);
    });

    return instruments;
  };

  subscribeTimeBars = (contractId: number, barUnit: number, unitsNumber: number, startDate: number) => {
    //$log.debug("cqgService.subscribeTimeBars: " + contractId + "|" + barUnit + "|" + unitsNumber + "|" + startDate );

    var request = Historical2.TimeBarRequest.create();
    request.requestId = CQGServiceMessageManager.nextRequestId();
    request.requestType = Historical2.TimeBarRequest_RequestType.REQUEST_TYPE_SUBSCRIBE;

    request.timeBarParameters = {
      contractId: contractId,
      barUnit: barUnit,
      fromUtcTime: startDate - TimeUtil.getBaseTime(),
      tickTypes: [],
    };

    if (unitsNumber > 1) {
      request.timeBarParameters!.unitNumber = unitsNumber;
    }

    var clMsg = WebAPI.ClientMsg.create();
    clMsg.timeBarRequests.push(request);

    this._timeBarRequestIdToRequest[request.requestId] = request;

    CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
  };

  unsubscribeTimeBars = (contractId: number, barUnit: number, unitsNumber: number, startDate: number) => {
    //$log.debug("cqgService.unsubscribeBars: " + contractId + "|" + barUnit + "|" + unitsNumber + "|" + startDate );

    const requestId = _.findKey(this._timeBarRequestIdToRequest, (request: Historical2.TimeBarRequest) => {
      return (
        request.timeBarParameters?.contractId === contractId &&
        request.timeBarParameters.fromUtcTime === startDate - TimeUtil.getBaseTime() &&
        (barUnit > 1 ? request.timeBarParameters.barUnit === barUnit : true)
      );
    }) as any as number;
    // TODO: check above statement `as any as number`

    if (_.isUndefined(requestId)) {
      //$log.debug( "cqgService.unsubscribeTimeBars ==> Cannot unsubscribe. Request not found!");
      return;
    } else {
      //$log.debug( "Unsubscribe ==> Found RequestId: " + requestId );
    }

    const request = Historical2.TimeBarRequest.create();
    request.requestId = requestId;
    request.requestType = Historical2.TimeBarRequest_RequestType.REQUEST_TYPE_DROP;

    const clMsg = WebAPI.ClientMsg.create();
    clMsg.timeBarRequests.push(request);

    CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);

    delete this._timeBarRequestIdToRequest[requestId];
  };

  static resolveSymbols = (symbols: string | string[]) => {
    if (!Array.isArray(symbols)) {
      symbols = [symbols];
    }

    var subscriptions: SymbolResolutionSubscription[] = [];
    var newSubscriptions: SymbolResolutionSubscription[] = [];

    symbols.forEach((symbol) => {
      var subscription = SymbolResolutionSubscriptions.getBySymbol(symbol);
      if (!subscription) {
        subscription = SymbolResolutionSubscriptions.add(symbol, CQGServiceMessageManager.nextRequestId());
        newSubscriptions.push(subscription);
      }
      subscriptions.push(subscription);
    });

    if (newSubscriptions.length > 0) {
      var clMsg = WebAPI.ClientMsg.create();
      newSubscriptions.forEach((subscription) => {
        var informationRequest = WebAPI.InformationRequest.create();
        informationRequest.id = subscription.id;
        informationRequest.subscribe = true;

        var resolutionRequest = Metadata.SymbolResolutionRequest.create();
        resolutionRequest.symbol = subscription.symbol;

        informationRequest.symbolResolutionRequest = resolutionRequest;
        clMsg.informationRequests.push(informationRequest);
      });

      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
    return subscriptions;
  };

  // TODO: This function requires spliting and relocating to appropriate places.
  subscribeToInstruments = (contractIds: number | number[], level: number) => {
    if (!Array.isArray(contractIds)) {
      contractIds = [contractIds];
    }

    let requests: MarketData.MarketDataSubscription[] = [];
    _.forEach(_.uniq(contractIds), (contractId: number) => {
      const subscription = InstrumentSubscriptions.Instance.getByContractId(contractId);
      if (subscription) {
        const subscrResult = subscription.addConsumer(level);
        if (subscrResult.newLevel > subscrResult.oldLevel) {
          requests.push({
            contractId: contractId,
            level: level,
          });
        }
      } else {
        InstrumentSubscriptions.Instance.add(contractId, level);
        requests.push({
          contractId: contractId,
          level: level,
        });
      }
    });

    if (requests.length > 0) {
      var clMsg = this.createMarketDataSubscriptionRequest(requests);
      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
  };

  unsubscribeFromInstruments = (contractIds: number | number[], level: number) => {
    if (!Array.isArray(contractIds)) {
      contractIds = [contractIds];
    }
    let requests: MarketData.MarketDataSubscription[] = [];
    _.forEach(_.uniq(contractIds), (contractId: number) => {
      let subscription = InstrumentSubscriptions.Instance.getByContractId(contractId);
      if (!subscription) {
        console.warn("Instrument not found for unsubscription: %d:%d.", contractId, level);
        return;
      }
      let subscrResult = subscription.removeConsumer(level);
      if (subscrResult.oldLevel !== subscrResult.newLevel) {
        requests.push({
          contractId: contractId,
          level: subscrResult.newLevel,
        });
        if (subscrResult.newLevel === MarketData.MarketDataSubscription_Level.LEVEL_NONE) {
          InstrumentSubscriptions.Instance.remove(contractId);
        }
      }
    });

    if (requests.length > 0) {
      const clMsg = this.createMarketDataSubscriptionRequest(requests);
      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    }
  };

  subscribeToAccountSummaryAndCurrencyRates = () => {
     const clMsg = WebAPI.ClientMsg.create();
     if(!CQGEnvironment.Instance.accountSummariesManager.isSubscribed())
     {
       const reqId = CQGServiceMessageManager.nextRequestId()
       const lastStBalancesRequest = createLastStBalancesSubscriptionRequest(reqId);
       clMsg.informationRequests.push(lastStBalancesRequest);
     }
    if(!CQGEnvironment.Instance.currencyRatesManager.isSubscribed())
    {
      const reqId = CQGServiceMessageManager.nextRequestId()
      const currencyRatesRequest = createCurrencyRatesSubscriptionRequest(reqId);
      clMsg.informationRequests.push(currencyRatesRequest);
    }
     if(clMsg.informationRequests.length > 0)
     {
       CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
     }
  };

  createMarketDataSubscriptionRequest = (requests: MarketData.MarketDataSubscription[]) => {
    if (!Array.isArray(requests)) requests = [requests];

    let clMsg = WebAPI.ClientMsg.create();

    _.forEach(requests, (item: MarketData.MarketDataSubscription) => {
      let request = MarketData.MarketDataSubscription.create();
      request.contractId = item.contractId;
      request.level = item.level;
      clMsg.marketDataSubscriptions.push(request);
    });

    return clMsg;
  };

  placeOrder = (newOrder: OrderState, account:Account) =>  {
    var orderRequest = this.createNewOrderRequest(newOrder);
    let clMsg = WebAPI.ClientMsg.create();
    clMsg.orderRequests.push(orderRequest);
    CQGEnvironment.Instance.ordersManager.newOrder(newOrder, account, orderRequest.requestId)
    CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    Notification.orderInfo(newOrder, "Placed");
  };

  createNewOrderRequest = (order: OrderState) => {
    var newOrder = Order2.NewOrder.create();
    newOrder.order = order.toWebApiOrder();
  
    let orderRequest = Order2.OrderRequest.create();
    orderRequest.requestId = CQGServiceMessageManager.nextRequestId();
    orderRequest.newOrder = newOrder;
  
    return orderRequest;
  }

  modifyOrder = (modifyOrders: OrderState[] | OrderState) => {
    if(!Array.isArray(modifyOrders)) {
      modifyOrders = [modifyOrders];
    }
    var clMsg = WebAPI.ClientMsg.create();
    _.forEach(modifyOrders, function(order:OrderState) {
      var modifyOrder = order.toWebApiModifyOrder();
      var orderRequest = Order2.OrderRequest.create();
      orderRequest.requestId = CQGServiceMessageManager.nextRequestId();
      
      orderRequest.modifyOrder = modifyOrder;
      clMsg.orderRequests.push(orderRequest);
    })
    CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
  }

  static cancelOrders = (cancelOrders: OrderState[] | OrderState) => 
  {
        if(!Array.isArray(cancelOrders))
        {
          cancelOrders = [cancelOrders];
        }
        var clMsg = WebAPI.ClientMsg.create();
        _.forEach(cancelOrders, (order: OrderState) => {
          var cancelOrder = order.toWebApiCancelOrder();
          var orderRequest = Order2.OrderRequest.create();
          orderRequest.requestId = CQGServiceMessageManager.nextRequestId();
          orderRequest.cancelOrder = cancelOrder;

          clMsg.orderRequests.push(orderRequest);

          order.setModified();
        });

        console.log("Cancelling orders" , cancelOrders.map(co => `${co?.displayContract} | ${co?.displaySide} | ${co?.limitPrice}`));
        CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
  };

  static requestSymbolsByUnderlying(contractId: number): Promise<any> {
    let subscription = SymbolsByUnderlyingSubscriptions.Instance.SubscriptionManager.getSubscription(contractId.toString());
  
    if (!subscription) {
      const underlyingRequest: Metadata.OptionMaturityListRequest = {
        underlyingContractId: contractId,
      };
  
      const request: WebAPI.InformationRequest = {
        id: CQGServiceMessageManager.nextRequestId(),
        optionMaturityListRequest: underlyingRequest,
      };
  
      const informationRequests = [request];
      const clMsg = WebAPI.ClientMsg.create();
      clMsg.informationRequests = informationRequests;
  
      subscription = new UnderlyingSubscription(contractId.toString(), request.id.toString());
      SymbolsByUnderlyingSubscriptions.Instance.SubscriptionManager.add(contractId.toString(), request.id.toString());
        
      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
  
      const result = subscription.getPromise();
      
      return result;
    } else {
      console.debug(`Serving SymbolsByUnderlying from cache for contractId: ${contractId}`);
      return subscription.getPromise();
    }
  }


  static async requestChildSymbols(parentSymbolId: number): Promise<ChildSymbolsSubscription> {
    let subscription = ChildSymbolsSubscriptions.Instance.SubscriptionManager.getSubscription(parentSymbolId.toString());

    if (!subscription) {
      const childSymbolsRequest: Metadata.InstrumentGroupRequest = {
        instrumentGroupId: parentSymbolId.toString(),
      };

      const request: WebAPI.InformationRequest = {
        id: CQGServiceMessageManager.nextRequestId(),
        instrumentGroupRequest: childSymbolsRequest,
      };

      const informationRequests = [request];
      const clMsg = WebAPI.ClientMsg.create();
      clMsg.informationRequests = informationRequests;

      subscription = ChildSymbolsSubscriptions.Instance.SubscriptionManager.add(parentSymbolId.toString(), request.id.toString());

      console.log(`Fetching ChildSymbols for parentSymbolId: ${parentSymbolId}`, subscription);

      CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);
    } else {
      console.log(`Serving ChildSymbols from cache for parentSymbolId: ${parentSymbolId}`, subscription);
    }

    return new Promise<ChildSymbolsSubscription>((resolve, reject) => {
      subscription.getPromise()
      .then(() => {
        if (subscription.childSymbols && subscription.childSymbols.length > 0) {
          resolve(subscription);
        } else {
          reject(`No child symbols found for parentSymbolId: ${parentSymbolId}`);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
    });
  }

  static async requestAtTheMoneyStrike(parentSymbolId: string): Promise<any> {
    const atmRequest: MarketData.AtTheMoneyStrikeRequest = {
        optionMaturityId: parentSymbolId
    };

    const request: WebAPI.InformationRequest = {
        id: CQGServiceMessageManager.nextRequestId(),
        atTheMoneyStrikeRequest: atmRequest
    };

    const informationRequests = [request];
    const clMsg = WebAPI.ClientMsg.create();
    clMsg.informationRequests = informationRequests;

    const subscription = AtTheMoneyStrikeSubscriptions.Instance.SubscriptionManager.add(parentSymbolId, request.id.toString());

    console.log(`Fetching AtTheMoneyStrike for parentSymbolId: ${parentSymbolId}`, subscription);

    CQGEnvironment.Instance.serviceMessageManager?.sendMessage(clMsg);

    try {
        const atmStrike = await subscription.promise;
        return atmStrike;
    } catch (error) {
        console.error(`Error fetching AtTheMoneyStrike: ${error}`);
        throw error;
    }
  }

}

// TODO: Temporary functions
const logIncomingMessage = (msg: WebAPI.ServerMsg) => {
  // console.log("Service message: ", getMsgString(msg));
};

const logOutgoingMessage = (msg: WebAPI.ClientMsg) => {
  // console.log("Sending message: ", getMsgString(msg));
};

const getMsgString = (msg:any) =>{
  const jsonString = JSON.stringify(msg, (key, value) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return undefined;
    }
    return value; 
  });

  return jsonString;
}

const createLastStBalancesSubscriptionRequest = (requestId: number) => {
    const lastStBalancesSubscription = CQGEnvironment.Instance.accountSummariesManager.subscribe(requestId);
    const lastStBalancesRequest = WebAPI.InformationRequest.create();
    lastStBalancesRequest.id = lastStBalancesSubscription.id;
    lastStBalancesRequest.subscribe = true;
    const t = TradingAccount;
    lastStBalancesRequest.lastStatementBalancesRequest = TradingAccount.LastStatementBalancesRequest.create();
    return lastStBalancesRequest;
}

const createCurrencyRatesSubscriptionRequest = (requestId: number) => {
    var currencyRatesSubscription = CQGEnvironment.Instance.currencyRatesManager.subscribe(requestId);
    var currencyRatesRequest = WebAPI.InformationRequest.create();
    currencyRatesRequest.id = currencyRatesSubscription.id;
    currencyRatesRequest.subscribe = true;
    currencyRatesRequest.currencyRatesRequest = TradingAccount.CurrencyRatesRequest.create();
    return currencyRatesRequest;
}