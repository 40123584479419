import { useCallback, useState } from "react";
import axios, { AxiosError } from "axios";
import Notification from "../../components/shared/notification/Notification";
import { current } from "immer";
import { useGetCurrentUser } from "./authentication";
import { chartSetting } from "../../components/widgets/tradingChart/chartTypes";

export const useFetchCharts = () => {
    const [error, setError] = useState<Error | AxiosError>();
    const [loaded, setLoaded] = useState(false);
    const currentUser = useGetCurrentUser();

    const loadCharts = useCallback(async () => {
        try {
            const response = await axios.get(`/chart_settings?member_id=${currentUser.id}`)
            return response.data
        } catch (error: any) {
            setError(error)
            Notification.error(`Could not load charts, ${error?.message}`);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { error, loaded, loadCharts };
};

export const useCreateChart = () => {
    const [error, setError] = useState<Error | AxiosError>();
    const [loaded, setLoaded] = useState(false);
    const currentUser = useGetCurrentUser();

    const createChartAsync = useCallback(async (
        chartData: chartSetting,
    ) => {
        try {
            const setting = JSON.stringify(chartData);
            const response = await axios.post(`/chart_settings?member_id=${currentUser.id}`, {
                 chart_name: chartData.name, symbol: chartData.symbol, settings: setting
                });
            Notification.success(`Chart layout '${chartData.name}' saved`);
            return response.data;
        } catch (error: any) {
            setError(error);
            Notification.error(`Could not create save, ${error?.message}`);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { error, loaded, createChartAsync };
};

export const useUpdateChart = () => {
    const [error, setError] = useState<Error | AxiosError>();
    const [loaded, setLoaded] = useState(false);

    const updateChartAsync = useCallback(async (
        chartData: chartSetting,
    ) => {
        try {
            const setting = JSON.stringify(chartData);
            const response = await axios.post(`/chart_settings/update/${chartData.id}`, {
                 chart_name: chartData.name, settings: setting
                });
            Notification.success(`Chart Layout renamed to '${chartData.name}'`);
            return response.data;
        } catch (error: any) {
            setError(error);
            Notification.error(`Could not create save, ${error?.message}`);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { error, loaded, updateChartAsync };
};

export const useGetChart = () => {
    const [error, setError] = useState<Error | AxiosError>();
    const [loaded, setLoaded] = useState(false);

    const getChartAsync = useCallback(async (
        chartId: string,
    ) => {
        try {
            const response = await axios.get(`/chart_settings/${chartId}`);
            return JSON.parse(response.data.content)["content"];
        } catch (error: any) {
            setError(error);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { error, loaded, getChartAsync };
};

export const useRemoveChart = () => {
    const [error, setError] = useState<Error | AxiosError>();
    const [loaded, setLoaded] = useState(false);

    const removeChartAsync = useCallback(async (
        chartId: string,
    ) => {
        try {
            const response = await axios.post(`/chart_settings/delete/${chartId}`);
            Notification.success(`Chart layout deleted`);
            return response.data;
        } catch (error: any) {
            setError(error);
        } finally {
            setLoaded(true);
        }
    }, []);

    return { error, loaded, removeChartAsync };
};