import clsx from "clsx";
import { OrderSide } from "../../../../types";
import { StopLoss, TakeProfit } from "../types";

interface OCOPriceIndicatorProps {
  ocoOrderSide: OrderSide | undefined;
  ocoSettings: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
  isProfitInView: boolean | "" | null;
  isLossInView: boolean | "" | null;
  displayPrice: number | null;
  firstVisiblePrice: any;
  lastVisiblePrice: any;
  profitPrice: string | null;
  lossPrice: string | null;
  orderSide: OrderSide;
}

const OCOPriceIndicator = ({
  ocoOrderSide,
  ocoSettings,
  isProfitInView,
  isLossInView,
  displayPrice,
  firstVisiblePrice,
  lastVisiblePrice,
  profitPrice,
  lossPrice,
  orderSide,
}: OCOPriceIndicatorProps) => {
  return (
    <>
      {ocoOrderSide &&
        ocoSettings[ocoOrderSide]?.takeProfit?.isSelected &&
        isProfitInView &&
        ocoOrderSide !== orderSide &&
        displayPrice === firstVisiblePrice && (
          <span
            className={clsx({
              "tooltiptext-visible": true,
              "tooltiptext-buy": ocoOrderSide !== OrderSide.Sell,
              "tooltiptext-takeprofit": true,
            })}
          >
            {ocoOrderSide === OrderSide.Buy ? `{${profitPrice}}` : `{${lossPrice}}`}
          </span>
        )}
      {ocoOrderSide &&
        ocoSettings[ocoOrderSide]?.stopLoss?.isSelected &&
        isLossInView &&
        ocoOrderSide !== orderSide &&
        displayPrice === lastVisiblePrice && (
          <span
            className={clsx({
              "tooltiptext-visible": true,
              tooltiptext: true,
              "tooltiptext-buy": ocoOrderSide !== OrderSide.Sell,
            })}
          >
            {ocoOrderSide === OrderSide.Buy ? `{${lossPrice}}` : `{${profitPrice}}`}
          </span>
        )}
    </>
  );
};

export default OCOPriceIndicator;
