import axios, { AxiosError } from "axios";
import { useCallback, useState } from "react";
import Notification from "../../components/shared/notification/Notification";
import { TradeLog } from "./types";
import { useGetCurrentUser } from "./authentication";

export const useTradeLog = () => {
  const [tradelog, setTradeLog] = useState<TradeLog[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const currentUser = useGetCurrentUser();

  const loadTradeLogData = useCallback(async () => {
    try {
      const response = await axios.get(`tradelog/simulator/${currentUser.id}`);
      setTradeLog(response.data);
      return response.data;
    } catch (error: any) {
      setError(error);
      Notification.error(
        `Could not load trade log data, ${error?.message}`
      );
      return [];
    } finally {
      setLoaded(true);
    }
  }, [currentUser.id]);

  return { tradelog, error, loaded, loadTradeLogData, setTradeLog };
};

export const saveTradeLog = async (formData: any): Promise<void> => {
  try {
    const payload = {
      order_update: {
        id: formData.id,
        comments: formData.comments,
        tradeLogStopLoss: formData.useStopLoss,
        tradeLogApproach: formData.approach,
        tradeLogRatio: formData.ratio,
        tradeLogTrend: formData.trend,
        tradePlanApproachFundamentalAnalysis: formData.approach === "Fundamental" 
          ? JSON.stringify(formData.approachFundamentalAnalysis) 
          : null,
        tradePlanTechnicalChartType: formData.approach === "Technical" && formData.approachTechnicalChartType !== "Indicator" 
          ? formData.approachTechnicalChartType
          : null,
        tradePlanTechnicalIndicator: formData.approach === "Technical" && formData.approachTechnicalType !== "Chart" 
          ? JSON.stringify(formData.approachTechnicalIndicator) 
          : null,
        tradePlanTechnicalIndicatorOther: formData.approach === "Technical" && formData.approachTechnicalIndicator?.includes("Other") 
          ? formData.approachTechnicalIndicatorOther 
          : null,
        tradePlanTechnicalType: formData.approach === "Technical" 
          ? formData.approachTechnicalType 
          : null,
      }
    };
    const response = await axios.post(`futures_challenge/order_updates/${payload.order_update.id}`, payload);
    return response.data;
  } catch (error: any) {
    console.error("Error saving trade log:", error);
    throw error;
  }
};
