import { Grid, TextField } from "@mui/material";
import { defaultBorderColor, gridItemStyles, inputStyles, redErrorColor } from "../styles";
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import MinusIcon from "../MinusIcon";
import { insertNumberInput } from "../utils";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import PlusIcon from "../PlusIcon";
import { OrderType, TradeTicketTab } from "../../../types";
import { IFormInput } from "../types";
import { Instrument } from "../../../cqg-api/models/Instrument";

interface LimitPriceProps {
  control: Control<IFormInput, any>;
  watch: UseFormWatch<IFormInput>;
  errors: FieldErrors<IFormInput>;
  isEnabledLmtPrice: boolean;
  activeTab: string;
  setValue: UseFormSetValue<IFormInput>;
  selectedContract: Instrument | null;
  selectedInstrument: any;
  rawLimitPrice: number;
  limitPrice: number;
}

const LimitPrice = ({
  control,
  watch,
  errors,
  isEnabledLmtPrice,
  activeTab,
  setValue,
  selectedContract,
  selectedInstrument,
  rawLimitPrice,
  limitPrice,
}: LimitPriceProps) => {
  const updateLimitPrice = (field: any, instrumentOrContract: any, step: number) => {
    const lprice = DisplayUtil.rawStepPrice(Number(rawLimitPrice), instrumentOrContract, step);
    const newLimitPrice = parseFloat(DisplayUtil.toDisplayPrice(lprice, instrumentOrContract)?.toString() || "") || 0;

    if (newLimitPrice >= 0) {
      setValue("rawLimitPrice", lprice);
      setValue(field.name, newLimitPrice);
    }
  };

  const handleInputChange = (e: any) => {
    insertNumberInput(e, true);
    if (e.target.value === "") {
      setValue("rawLimitPrice", e.target.value);
    } else {
      setValue("rawLimitPrice", Number(e.target.value));
    }
  };

  const isOrderTypeValid = () => ["STL", "LMT"].includes(watch("orderType"));

  return (
    <Grid item xs={6} sx={gridItemStyles}>
      <label className="label">Limit Price</label>
      <div className="control" style={{ borderColor: errors.limitPrice ? redErrorColor : defaultBorderColor }}>
        <Controller
          name="limitPrice"
          control={control}
          rules={{
            required: isOrderTypeValid() ? "Limit Price is required" : false,
          }}
          render={({ field }) => (
            <button
              type="button"
              className="minusButton"
              disabled={!isEnabledLmtPrice}
              onClick={() =>
                updateLimitPrice(
                  field,
                  activeTab === TradeTicketTab.Options ? selectedContract : selectedInstrument,
                  -1,
                )
              }
            >
              <MinusIcon />
            </button>
          )}
        />
        <Controller
          name="limitPrice"
          control={control}
          rules={{
            validate: (value) => {
              const isMktOrder = watch("orderType") === OrderType.Mkt;
              const isStpOrder = watch("orderType") === OrderType.Stp;
              return ((isMktOrder || isStpOrder) && value === 0) || value > 0 || "Limit price is invalid";
            },
            pattern: {
              value: /^[0-9]*\.?[0-9]*$/,
              message: "Please enter a valid number",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={activeTab === TradeTicketTab.Options && watch("orderType") === OrderType.Mkt ? "" : limitPrice}
              onInput={handleInputChange}
              disabled={!isEnabledLmtPrice}
              fullWidth
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />
        <Controller
          name="limitPrice"
          control={control}
          render={({ field }) => (
            <button
              type="button"
              className="minusButton"
              disabled={!isEnabledLmtPrice}
              onClick={() =>
                updateLimitPrice(field, activeTab === TradeTicketTab.Options ? selectedContract : selectedInstrument, 1)
              }
            >
              <PlusIcon />
            </button>
          )}
        />
      </div>
    </Grid>
  );
};

export default LimitPrice;
