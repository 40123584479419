import { Grid, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import MinusIcon from "../../../../TradeTicketDrawer/MinusIcon";
import PlusIcon from "../../../../TradeTicketDrawer/PlusIcon";
import { onlyNumbersRules } from "../../utils";
import { inputStyles } from "../../../../TradeTicketDrawer/styles";
import { IFormInput } from "../../../../TradeTicketDrawer/types";

interface TickProps {
  control: Control<IFormInput, any>;
  setValue: (name: string, value: number) => void;
  name: any;
  onTickChange: (tick: number) => void;
}

const Tick = ({ control, setValue, name, onTickChange }: TickProps) => {
  const handleTickChange = (adjustment: number, currentValue: number) => {
    const newValue = currentValue + adjustment;
    setValue(name, newValue);
    onTickChange(newValue);
  };

  return (
    <Grid item xs={6} sx={{ padding: 0 }}>
      <label className="label">Ticks</label>
      <div className="control">
        <Controller
          name={name}
          control={control}
          rules={onlyNumbersRules}
          render={({ field }) => (
            <>
              <button type="button" className="minusButton" onClick={() => handleTickChange(-1, field.value)}>
                <MinusIcon />
              </button>

              <TextField
                {...field}
                type="number"
                fullWidth
                value={field.value}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  field.onChange(newValue);
                  onTickChange(newValue);
                }}
                variant="outlined"
                sx={inputStyles}
              />

              <button type="button" className="minusButton" onClick={() => handleTickChange(1, field.value)}>
                <PlusIcon />
              </button>
            </>
          )}
        />
      </div>
    </Grid>
  );
};

export default Tick;
