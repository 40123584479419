import Link from "@mui/material/Link";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";

const apiUrl = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL;

const linkStyles = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  fontFamily: "Averta-Regular",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "16px",
  letterSpacing: "1.5px",
  border: "none",
  "&:hover": { border: "none", backgroundColor: "transparent" },
};

const iconStyles = {
  fontSize: "16px",
  marginRight: "4px",
};

const StyledLink = ({ isMobileView }: { isMobileView: boolean }) => {
  const currentUser = useGetCurrentUser();
  return (
    <Link href={`${apiUrl}/trading_tools/simulator/member/${currentUser?.id}/download_trade_log.csv`} sx={linkStyles}>
      <FileDownloadIcon
        sx={{ ...iconStyles, height: isMobileView ? "20px" : undefined, width: isMobileView ? "20px" : undefined }}
      />
      {!isMobileView && "DOWNLOAD"}
    </Link>
  );
};

export default StyledLink;
