// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: grid;
  height: 100vh;
  width: 98vw;
  background: #F1F5F7;
  grid-template-rows: 44px 1fr;
}
main {
  display: flex;
  width: 98vw;
  height: calc(100vh - 44px);
  margin-top: 44px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/components/Layout/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,WAAW;EACX,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[".layout {\n  display: grid;\n  height: 100vh;\n  width: 98vw;\n  background: #F1F5F7;\n  grid-template-rows: 44px 1fr;\n}\nmain {\n  display: flex;\n  width: 98vw;\n  height: calc(100vh - 44px);\n  margin-top: 44px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
