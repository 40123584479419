import { useEffect, useRef, useId, useState, useCallback } from "react";
import { cqgUDFDataFeed } from '../../../cqg-api/services/CQGUDFDataFeed';
import { useChartConfig, generateMultiChartLayout, chartLayout } from "./chartConfig";
import { WidgetAction, WidgetType } from "../../../../src/types";
import { useDispatch } from "react-redux";
import workspacesActions from "../../../../src/redux/workspaces/workspacesActions";
import { setTradeTicketValue } from "../../../../src/redux/products/chartWidget";
import { OrderChain_OrderType, OrderChain_Side } from "../../../../src/cqg-api/proto/traderouting_1";

const TradingChart = (props) => {
  const chartContainerRef = useRef();
  const [tvWidget, setTvWidget] = useState(null);

  const containerId = useId();
  const dispatch = useDispatch();

  const workspaceId = props.workspaceId;

  const onPlaceOrder = useCallback((order, instrument) => {
    if (workspaceId) {
      const chartWidgetValue = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        widgetData: {
          action: WidgetAction.CreateOrder,
          data: {
            size: order.qty,
            side: order.side === OrderChain_Side.BUY ? OrderChain_Side.BUY : OrderChain_Side.SELL,
            limitPrice: order.price,
            orderType: order.type === OrderChain_OrderType.MKT ? OrderChain_OrderType.LMT : order.type === OrderChain_OrderType.LMT ? OrderChain_OrderType.MKT : order.type,
          },
        },
      };

      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: instrument,
        }),
      );

      dispatch(setTradeTicketValue(chartWidgetValue));
    }
  }, [workspaceId]);

  const chartConfig = useChartConfig(cqgUDFDataFeed, props.symbols[0], containerId, onPlaceOrder, props.widget);

  const createTradingwidget = useCallback(async () => {
    const strLayout = window.localStorage.getItem(`chart-${props.widget?.id}`);
    if (strLayout) {
      chartConfig.saved_data = JSON.parse(strLayout);
    }

    const widget = new window.TradingView.widget(chartConfig);
    setTvWidget(widget);
  }, [cqgUDFDataFeed, props.symbols, containerId, onPlaceOrder]);

  useEffect(() => {
    if (!window.Trading && !tvWidget) {
      const script = document.createElement('script');
      script.src = "/charting_library/charting_library.min.js"; // path to the Trading library
      script.onload = () => {
        createTradingwidget()
      };
      document.body.appendChild(script);
    } else {
      createTradingwidget();
    }
  }, []);

  useEffect(() => {
    let saveInterval = null;
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const size = props.symbols.length;
  
          if (size === 7 || size > 8) {
            console.log(`Trading chart does not support ${size} layout.`);
          } else {
            tvWidget.setLayout(chartLayout(size));
            generateMultiChartLayout(tvWidget, size, props.symbols)
          }
        });

        // TODO: Could not find any event which tells if any indicator is added or removed from the chart.
        // This is a workaround and should be improved.
        saveInterval = setInterval(() => {
          tvWidget.save(layout=>{
            const strLayout = JSON.stringify(layout);
            window.localStorage.setItem(`chart-${props.widget?.id}`, strLayout);
          });
        }, 2000);
      });
    }

    return () => {
      if (tvWidget)
        tvWidget.remove();

      clearInterval(saveInterval);
    }
  }, [tvWidget])

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      id={"TVChartContainer" + containerId}
      ref={chartContainerRef}
      className={"TVChartContainer"}
    />
  );
};

export default TradingChart;
