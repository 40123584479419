import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const inputValueStyle = {
  fontFamily: "Averta-Regular",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "0.15px",
  color: "rgba(8, 29, 55, 0.87)",
};

interface HeaderFilterDropdownProps {
  listItems: {
    label: string;
    value: string;
    options: string[];
    key: string;
  }[];
  handleFilterChange: (key: "product" | "month") => (event: SelectChangeEvent<string>) => void;
  filters: {
    product: string;
    month: string;
  };
}

const HeaderFilterDropdown = ({ listItems, handleFilterChange, filters }: HeaderFilterDropdownProps) => {
  return (
    <>
      {listItems.map(({ label, value, options, key }) => (
        <FormControl key={key} size="small" sx={{ display: "flex", flexDirection: "column" }}>
          <Select
            value={value}
            onChange={handleFilterChange(key as keyof typeof filters)}
            sx={{ width: "195px", height: "30px", ...inputValueStyle }}
            displayEmpty
            MenuProps={{ PaperProps: { style: { maxHeight: 250, overflow: "auto" } } }}
          >
            <MenuItem value="">{label}</MenuItem>
            {options.map((option, index) =>
              option ? (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ) : null,
            )}
          </Select>
        </FormControl>
      ))}
    </>
  );
};

export default HeaderFilterDropdown;
