import { SvgIconComponent } from "@mui/icons-material";
import { SerializedDockview } from "dockview";
import ReactGridLayout from "react-grid-layout";
import { Instrument } from "./cqg-api/models/Instrument";

export type DemoGridLayoutProps = {
  width?: number;
  defaultLayout: ReactGridLayout.Layout[];
  onLayoutChanged: (layout: ReactGridLayout.Layout[]) => void;
  selectedWorkspace?: Workspace;
};

export type WorkspaceData = {
  title?: string;
  description?: string;
  serializedDockview?: SerializedDockview;
  widgets: Widget[];
};

export type Workspace = {
  title: string;
  data?: SerializedDockview;
  description?: string;
  id?: number;
  memberId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isDefault?: boolean;
};
export type DefaultWorkspaceData = {
  workspace: ReactGridLayout.Layout[];
  workspaceId: number;
};
export type DefaultWorkspace = DefaultWorkspaceData | null;

export type Widget = {
  id?: string;
  title: string;
  key: number;
  icon?: SvgIconComponent | string;
  widgetType: WidgetType | string;
  widgetId?: number;
  workspaceId?: number;
  contractIds?: string[];
  widgetName?: string;
  widgetData?: {
    action: WidgetAction,
    data: any,
    contractMonth?: any
  };
  expired?: boolean;
};

export type User = {
  accessToken?: string;
  name?: string;
  id?: string;
  accounts: Account[];
  firstName?: string;
  lastName?: string;
};

export enum AccountType {
  Challenge = "challenge",
  Simulator = "simulator",
}

export type Account = {
  accountId: number;
  accountType: AccountType;
  password: string;
  username: string;
};

export type Product = {
  id: string;
  cmeSymbol: string;
  description: string;
  name: string;
  tickSize: string;
  shortName: string;
  assetClass?: any;
  cqgSymbol: string;
};

export type ProductWithContracts = Product & {
  monthlyContracts?: Instrument[];
};

export type Watchlist = {
  id: number;
  workspaceId: number;
  associatedProducts: string;
  title: string;
};

export type InstrumentMarketData = Product & {
  askPrice?: number | string | null;
  askQty?: number | string | null;
  bidPrice?: number | string | null;
  bidQty?: number | string | null;
  lastPrice?: number | string | null;
  lastQty?: number | string | null;
  change?: number | null;
  changePercentage?: string | null;
  price?: number | string | null;
  title?: string | null;
  tradeVolume?: number | string | null;
  monthlyContracts?: Instrument | undefined;
};

export enum WidgetType {
  AccountInfo = "Account Info",
  Watchlist = "Watchlists",
  AvailableProducts = "Markets",
  Orders = "Orders",
  Positions = "Positions",
  EnergyChart = "Energy chart",
  BarChart = "Bar chart",
  LineChart = "Line chart",
  DoubleBarChart = "Donut chart",
  TradeLogs = "Performance",
  Chart = "Chart",
  Performance = "Performance",
  News = "News",
  PriceLadder = "Price Ladder",
  TradeTicket = "Trade Ticket",
}

export enum WidgetAction {
  AmendOrder = "AmendOrder",
  CreateOrder = "CreateOrder",
  PositionTrade = "PositionTrade"
}

export type DockerviewPanelProps = {
  title?: string;
  widget: Widget;
  workspace?: Workspace;
  workspaceId?: number;
};

export const WORKSPACES = "WORKSPACES";

export type AssetProductsDictionary = {
  [assetClassName: string]: ProductWithContracts[];
};

export enum TreeViewMode {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export const CONTRACT_ID_NAME = "displayName";

export enum positionsFilter {
  All = "All",
  Open = "Open",
  Closed = "Closed",
}

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderType {
  Mkt = "MKT",
  Lmt = "LMT",
  Stp = "STP",
  Stl = "STL",
  Cross = "CROSS",
  Unrecognized = "UNRECOGNIZED",
}

export enum PositionDisplayEnum {
  LongShort,
  PlusMinus,
}
export enum PositionDisplay {
  Long = "Long",
  Short = "Short",
}

export type TradeLogProps = {
  capitalRiskPerTrade: string,
  pctCapitalRiskPerTrade: string,
  pctCapitalToMargin: string,
  approach: string,
  trend: string,
  ratio: string,
  useStopLoss: string,
  tradePlanSymbols: TradePlanSymbolType[],
  approachFundamentalAnalysis: [],
  approachTechnicalType: string,
  approachTechnicalChartType: string,
  approachTechnicalIndicator: [],
  approachTechnicalIndicatorOther: string;
  comments: string;
}

export type SymbolType = { symbol: string; symbolId: number | string; name: string };

export type TradePlanSymbolType = {
    symbolId?: string; cmeSymbolId: number; cmeSymbol: SymbolType 
};

export enum TradeTicketTab {
  Futures = 'futures',
  Options = 'options'
}

export enum Order_Type {
  MARKET = "MARKET",
  LIMIT = "LIMIT",
  STOP = "STOP",
};
export type Row = {
  id: string;
  contract: string;
  symbol: string;
  month: string;
  buys: number;
  sells: number;
  cp: string;
  direction: string;
  strike: number;
  relizedPL: string;
  unrealizedPL: string;
  averagePX: string;
  realizedPL: string;
  size: number;
}
export enum Mode_Type {
  PRACTICE_MODE = "Practice Mode",
  CHALLENGE_MODE = "Challenge Mode",
}

export type OrderRow = {
  id: string;
  contract: string;
  status: number;
  side: string;
  symbol: string;
  month: string;
  buys: string;
  cp: string;
  direction: string;
  type: string;
  strike: number;
  relizedPL: string;
  unrealizedPL: string;
  averagePX: string;
  realizedPL: string;
  qty: string;
  fillPX: string;
  limitPX: string;
}
export enum OrderOption {
  Call = "CALL",
  Put = "PUT",
}
