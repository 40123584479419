import { useCallback, useMemo } from "react";
import { DisplayUtil } from "../../../../../../cqg-api/utils/DisplayUtil";

interface Strategy {
  price: number;
  ticks: number;
  percent: number;
  displayPrice: number;
}

const useProfitLossChange = (
  limitPrice: number,
  quantity: number,
  side: string,
  sectionKey: string,
  selectedContract: any,
  setValue: (key: string, value: any) => void,
  getSign: (isProfit: boolean) => number,
) => {
  const calculateStrategy = useMemo(
    () =>
      (
        profitLoss: number,
        limitPrice: number,
        quantity: number,
        selectedContract: any,
        sectionKey: string,
      ): Strategy => {
        const sign = getSign(sectionKey === "takeProfit");
        let price = limitPrice + (profitLoss / (quantity * selectedContract?.multiplier || 1)) * sign;

        // Adjust price to nearest tick
        price = DisplayUtil.rawStepPrice(price, selectedContract, 0);
        const displayPrice = parseFloat(DisplayUtil.toDisplayPrice(price, selectedContract)?.toString() || "0") || 0;

        const ticks = selectedContract
          ? DisplayUtil.priceToTicks(Math.abs(limitPrice - price), selectedContract, true)
          : 0;

        const percent = limitPrice ? Math.round((Math.abs(limitPrice - price) / limitPrice) * 100 * 100) / 100 : 0;

        return { price, ticks, percent, displayPrice };
      },
    [getSign],
  );

  const onProfitLossChange = useCallback(
    (profitLoss: number) => {
      const strategy = calculateStrategy(profitLoss, limitPrice, quantity, selectedContract, sectionKey);

      const percentKey = sectionKey === "takeProfit" ? "profitPercentage" : "lossPercentage";

      setValue(`oco.${side}.${sectionKey}.tick`, strategy.ticks);
      setValue(`oco.${side}.${sectionKey}.price`, strategy.displayPrice);
      setValue(`oco.${side}.${sectionKey}.${percentKey}`, strategy.percent);
    },
    [calculateStrategy, limitPrice, quantity, selectedContract, sectionKey, setValue, side],
  );

  return onProfitLossChange;
};

export default useProfitLossChange;
