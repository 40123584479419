import { Control, Controller } from "react-hook-form";
import clsx from "clsx";
import { IFormInput } from "../types";

interface TimeInForceProps {
  control: Control<IFormInput, any>;
  isLabel?: boolean;
}

const TimeInForce = ({ control, isLabel }: TimeInForceProps) => {
  return (
    <>
      {isLabel && <label className="label">Time-in-force</label>}
      <Controller
        name="timeInForce"
        control={control}
        render={({ field: { value, onChange } }) => (
          <div
            className={clsx({
              timeInForce: isLabel,
              "price-ladder-timeInForce": !isLabel,
            })}
          >
            <button type="button" onClick={() => onChange(1)} className={`timeInDay ${value === 1 && "active"}`}>
              DAY
            </button>
            <button type="button" onClick={() => onChange(2)} className={`timeInDay ${value === 2 && "active"}`}>
              GTC
            </button>
          </div>
        )}
      />
    </>
  );
};

export default TimeInForce;
