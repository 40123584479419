const lossStyles = { color: '#C62828' };
const profitStyles = { color: '#00695C' };

export function formatNumber(num: number): string {
  const val = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(num));
  if (isNaN(Number(num))) return '0.00';
  return val;
}
export const getPrice = (profit: string, loss: string) => {
  if (parseFloat(profit) < 0) {
    return <span style={lossStyles}>
      -${formatNumber(Math.abs(parseFloat(profit)))}
    </span>
  }
  if (parseFloat(profit) > 0) {
    return <span style={profitStyles}>
      ${formatNumber(Math.abs(parseFloat(profit)))}
    </span>
  }
  if (parseFloat(loss) < 0) {
    return <span style={lossStyles}>
      -${formatNumber(Math.abs(parseFloat(loss)))}
    </span>
  }
  return <span style={profitStyles}>
    ${formatNumber(Math.abs(parseFloat(loss)))}
  </span>
}
