// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTableContainer-root::-webkit-scrollbar,
::-webkit-scrollbar-track {
  width: 10px;
  background: #ebedee;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 8px;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.MuiTableContainer-root::-webkit-scrollbar-corner {
  background-color: transparent;
}
.MuiTableContainer-root::-webkit-scrollbar:horizontal {
  height: 10px;
}
.trade-log-table-container::-webkit-scrollbar,
::-webkit-scrollbar-track {
  width: 0 !important;
}
.MuiTableContainer-root::-webkit-scrollbar:horizontal {
  height: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/TradeLog/index.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd;AACA;;EAEE,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".MuiTableContainer-root::-webkit-scrollbar,\n::-webkit-scrollbar-track {\n  width: 10px;\n  background: #ebedee;\n}\n.MuiTableContainer-root::-webkit-scrollbar-thumb {\n  background: #bdbdbd;\n  border-radius: 8px;\n}\n.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {\n  background: #888;\n}\n.MuiTableContainer-root::-webkit-scrollbar-corner {\n  background-color: transparent;\n}\n.MuiTableContainer-root::-webkit-scrollbar:horizontal {\n  height: 10px;\n}\n.trade-log-table-container::-webkit-scrollbar,\n::-webkit-scrollbar-track {\n  width: 0 !important;\n}\n.MuiTableContainer-root::-webkit-scrollbar:horizontal {\n  height: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
