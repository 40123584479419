import { useCallback, useMemo } from "react";
import { CQGConstants } from "../../../cqg-api/constants/CQGConstants";
import { OrderType } from "../../../types";
import { ocoInitial } from "../../widgets/priceLadder/types";
import { getDefaultQty, getDefaultSide, getDefaultTIF } from "../utils";
import { OrderState } from "../../../cqg-api/models/OrderState";

const useCreateDefaultFormValues = (
  defaultContract: any,
  orderData: any,
  amendOrder: any,
  externalData: any,
  widgetData: any,
) => {
  const getDefaultOrderType = useCallback(() => {
    if (widgetData?.data) {
      const order = widgetData?.data as OrderState;
      return CQGConstants.getOrderType(order.orderType);
    }
    if (!!orderData && orderData.orderType) {
      return CQGConstants.getOrderType(orderData.orderType);
    }
    return OrderType.Lmt;
  }, [orderData, widgetData?.data]);

  return useMemo(
    () => ({
      contract: defaultContract?.displayName,
      side: getDefaultSide(amendOrder, orderData),
      orderType: getDefaultOrderType(),
      timeInForce: getDefaultTIF(amendOrder),
      quantity: getDefaultQty(amendOrder, orderData),
      limitPrice: externalData ? widgetData?.data?.limitPrice : (defaultContract?.lastPrice ?? 0),
      stopPrice: externalData ? widgetData?.data?.stopPrice : (defaultContract?.lastPrice ?? 0),
      rawLimitPrice: externalData ? widgetData?.data?.limitPrice : (defaultContract?.lastPrice ?? 0),
      rawStopPrice: externalData ? widgetData?.data?.stopPrice : (defaultContract?.lastPrice ?? 0),
      putCall: undefined,
      strikePrice: "",
      oco: ocoInitial,
    }),
    [
      amendOrder,
      defaultContract?.displayName,
      defaultContract?.lastPrice,
      externalData,
      getDefaultOrderType,
      orderData,
      widgetData?.data?.limitPrice,
      widgetData?.data?.stopPrice,
    ],
  );
};

export default useCreateDefaultFormValues;
