import React from "react";
import { FormControl, Select, MenuItem, FormHelperText, SelectChangeEvent, Typography } from "@mui/material";

interface SelectFieldProps {
  label: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  options: string[];
  helperText?: string;
}
const inputValueStyle = {
  fontFamily: "Averta-Regular",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "0.15px",
  color: "rgba(8, 29, 55, 0.87)",
};
const labelStyle = {
  margin: "0 auto",
  width: "100%",
  height: "10px",
  fontFamily: "Averta-Regular",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  display: "flex",
  alignItems: "left",
  letterSpacing: "0.17px",
  color: "rgba(8, 29, 55, 0.87)",
  flex: "none",
  order: 0,
  flexGrow: 1,
};
const SelectField: React.FC<SelectFieldProps> = ({ label, value, onChange, options, helperText }) => (
  <FormControl
    fullWidth
    margin="dense"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0px",
      height: "64px",
    }}
  >
    <Typography variant="body2" sx={labelStyle}>
      {label}
    </Typography>
    <Select
      value={value}
      onChange={onChange}
      sx={{ width: "100%", height: "36px", ...inputValueStyle }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>

    {helperText && (
      <FormHelperText sx={{ padding: "3px 14px 0px", display: "none", width: "220px", height: "20px" }}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);

export default SelectField;
