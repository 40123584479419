import { useState, useEffect, useCallback } from "react";
import { Workspace } from "../../../../../types";
import {
  useFetchWorkspaces,
  useFetchWorkspaceById,
  useDeleteWorkspace,
  useSelectedWorkspace,
  useUpdateWorkspace,
  useWorkspaceUpdater,
  useCreateDuplicateWorkspace,
} from "../../../../../hooks/api/workspace";
import { useDispatch } from "react-redux";
import watchlistActions from "../../../../../redux/watchlist/watchlistActions";

interface WorkspaceManager {
  activeWorkspace: Workspace | undefined;
  workSpaces: Workspace[];
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  onNewSpaceCreated: (workspace: Workspace) => void;
  onActiveWorkSpaceChange: (workspace: Workspace) => void;
  onRenameWorkspace: () => void;
  workspaceLoading: boolean;
  deleteWorkspaceLoading: boolean;
  onSetDefaultWorkspace: (Workspace: Workspace) => void;
  handleRenameWorkspace: (workspace: Workspace, newTitle: string) => Promise<void>;
  updateWorkspaceLoaded: boolean;
  onWorkSpaceUpdated: (workspace: Workspace) => void;
  onDuplicateWorkspace: (workspace: Workspace) => void;
  workspaceTabsLimit: number;
  loadWorkspaceOnToolsSelection: (value: boolean) => void;
}

export const useWorkspaceManager = (currentUser: any): WorkspaceManager => {
  const dispatch = useDispatch();
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace | undefined>();
  const { workSpaces, loadWorkSpaces } = useFetchWorkspaces();
  const { fetchWorkspaceById, loaded: workspaceLoading, workspace } = useFetchWorkspaceById();
  const { deleteWorkspaceAsync, deleteWorkspaceLoading } = useDeleteWorkspace();
  const { updateSelectedWorkspaceAsync } = useSelectedWorkspace();
  const { updateDefaultWorkspaceAsync } = useUpdateWorkspace();
  const { updateWorkspaceAsync, updateWorkspaceLoaded } = useWorkspaceUpdater();
  const { createDuplicateWorkspaceAsync } = useCreateDuplicateWorkspace();

  useEffect(() => {
    loadWorkSpaces();
  }, [loadWorkSpaces]);

  useEffect(() => {
    if (workSpaces.length > 0 && !activeWorkspace) {
      const defaultWorkspace = workSpaces.find((workspace) => workspace.isDefault);
      if (defaultWorkspace) {
        setActiveWorkspace(defaultWorkspace);
      } else {
        setActiveWorkspace(workSpaces[0]);
      }
    }
  }, [activeWorkspace, workSpaces]);
  
  useEffect(() => {
    if (workspace?.id) setActiveWorkspace(workspace);
  }, [workspace]);

  useEffect(() => {
    const userFavoriteWorkspaceId = currentUser?.selectedWorkspace?.id;
    if (userFavoriteWorkspaceId) fetchWorkspaceById(userFavoriteWorkspaceId);
  }, [currentUser?.selectedWorkspace?.id, fetchWorkspaceById]);

  useEffect(() => {
    if (activeWorkspace?.id) updateSelectedWorkspaceAsync(activeWorkspace.id);
  }, [activeWorkspace?.id, updateSelectedWorkspaceAsync]);

  const onDeleteWorkspace = async (workspace: Workspace) => {
    await deleteWorkspaceAsync(workspace.id as number);
    await loadWorkSpaces();
    if (activeWorkspace?.id === workspace.id) setActiveWorkspace(undefined);
  };

  const onNewSpaceCreated = useCallback(
    (workspace: Workspace) => {
      dispatch(watchlistActions.dispatchClearWatchlist());
      setActiveWorkspace(workspace);
      loadWorkSpaces();
    },
    [dispatch, loadWorkSpaces],
  );

  const onActiveWorkSpaceChange = (workspace: Workspace) => {
    if (activeWorkspace?.id !== workspace.id && workspace.id) {
      dispatch(watchlistActions.dispatchClearWatchlist());
      fetchWorkspaceById(workspace.id.toString());
    }
  };

  const onRenameWorkspace = () => loadWorkSpaces();

  const onSetDefaultWorkspace = async (workspace: Workspace) => {
    await updateDefaultWorkspaceAsync(workspace, (workspace) => {
      setActiveWorkspace(workspace);
    });
    loadWorkSpaces();
  };

  const handleRenameWorkspace = async (workspace: Workspace, newTitle: string) => {
    if (workspace) {
      try {
        await updateWorkspaceAsync({ ...workspace, title: newTitle });
      } catch (error) {
        console.error(error);
      }
      loadWorkSpaces();
    }
  };

  const onWorkSpaceUpdated = useCallback(
    (workspace: Workspace) => {
      setActiveWorkspace(workspace);
      loadWorkSpaces();
    },
    [loadWorkSpaces],
  );

  const onDuplicateWorkspace = async (workspace: Workspace) => {
    await createDuplicateWorkspaceAsync(workspace, async (response: Workspace) => {
      setActiveWorkspace(response);
      loadWorkSpaces();
    });
  };

  const workspaceTabsLimit = parseInt(process.env.REACT_APP_MAX_WORKSPACE_TABS || "10", 10);

  const loadWorkspaceOnToolsSelection = useCallback((value: boolean) => {
    if (value) {  
      if (activeWorkspace?.id) {
        fetchWorkspaceById(activeWorkspace.id.toString());
      }
    }
  },[activeWorkspace?.id, fetchWorkspaceById]);
  

  return {
    activeWorkspace,
    workSpaces,
    onDeleteWorkspace,
    onNewSpaceCreated,
    onActiveWorkSpaceChange,
    onRenameWorkspace,
    workspaceLoading,
    deleteWorkspaceLoading,
    onSetDefaultWorkspace,
    handleRenameWorkspace,
    updateWorkspaceLoaded,
    onWorkSpaceUpdated,
    onDuplicateWorkspace,
    workspaceTabsLimit,
    loadWorkspaceOnToolsSelection
  };
};
