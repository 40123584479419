import { Box, Tab, Tabs } from "@mui/material";
import { mobileTabs } from "../mockTabs";
import { componentFactoryMobile } from "../../dockview/utils";
import { useCallback, useState } from "react";

const tabStyle = {
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-around",
  },
  "& .MuiTab-root": {
    borderBottom: "2px solid #d1d1d1",
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "1px",
  },
  "& button.Mui-selected": {
    color: "#081D37",
  },
};

const MobileTabs = () => {
  const [tabValue, setTabValue] = useState<string>("Markets");
  const [selectedTab, setSelectedTab] = useState<any>(mobileTabs[0]);
  const handleTabValueChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleTabClick = useCallback((tab: any) => {
    setSelectedTab(tab);
  }, []);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs sx={tabStyle} value={tabValue} onChange={handleTabValueChange} variant="scrollable" scrollButtons="auto">
        {mobileTabs?.map((tab) => {
          return (
            <Tab
              key={tab.key}
              sx={{ textTransform: "none" }}
              onClick={() => handleTabClick(tab)}
              label={tab?.displayName}
              value={tab?.displayName}
              disabled={!tab?.isActive}
            />
          );
        })}
      </Tabs>
      {selectedTab && componentFactoryMobile(selectedTab, 1)}
    </Box>
  );
};

export default MobileTabs;
