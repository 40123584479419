import Alert from "@mui/material/Alert";
import { FieldErrors } from "react-hook-form";
import { IFormInput } from "../types";

const FormErrors = ({ errors }: { errors: FieldErrors<IFormInput> }) => {
  return (
    Object.keys(errors).length > 0 && (
      <Alert severity="error">
        {Object.values(errors).map((error: any) => (
          <div key={error?.message}>{error?.message}</div>
        ))}
      </Alert>
    )
  );
};

export default FormErrors;
