import clsx from "clsx";
import { OrderSide } from "../../../../types";
import { Depth, StopLoss, TakeProfit } from "../types";
import OCOPriceIndicator from "./OCOPriceIndicator";

interface DottedOrderChipProps {
  orderSide: OrderSide;
  isSelected: boolean | undefined;
  quantityValue: number;
  ocoOrderSide: OrderSide | undefined;
  ocoSettings: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
  depth: Depth;
  profitPrice: string | null;
  lossPrice: string | null;
  hoveredMouseDepth:
    | (Depth & {
        orderSide: OrderSide;
      })
    | null;
  visibleRows: any;
}

const DottedOrderChip = ({
  orderSide,
  isSelected,
  quantityValue,
  ocoOrderSide,
  ocoSettings,
  depth,
  profitPrice,
  lossPrice,
  hoveredMouseDepth,
  visibleRows,
}: DottedOrderChipProps) => {
  const firstVisiblePrice = visibleRows?.first?.dataset.key;
  const lastVisiblePrice = visibleRows?.last?.dataset.key;

  const isProfitInView =
    ocoOrderSide === OrderSide.Buy
      ? profitPrice && hoveredMouseDepth && firstVisiblePrice < profitPrice
      : lossPrice && hoveredMouseDepth && firstVisiblePrice < lossPrice;
  const isLossInView =
    ocoOrderSide === OrderSide.Buy
      ? lossPrice && hoveredMouseDepth && lastVisiblePrice > lossPrice
      : profitPrice && hoveredMouseDepth && lastVisiblePrice > profitPrice;
  return (
    <span
      className={clsx({
        [`price-ladder-pre-order-${orderSide}`]: isSelected,
        tooltip: true,
      })}
    >
      {isSelected && quantityValue}
      <OCOPriceIndicator
        ocoOrderSide={ocoOrderSide}
        ocoSettings={ocoSettings}
        isProfitInView={isProfitInView}
        isLossInView={isLossInView}
        displayPrice={depth.displayPrice}
        firstVisiblePrice={firstVisiblePrice}
        lastVisiblePrice={lastVisiblePrice}
        profitPrice={profitPrice}
        lossPrice={lossPrice}
        orderSide={orderSide}
      />
    </span>
  );
};

export default DottedOrderChip;
