import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Home from "../components/home/Home";
import PermissionChecker from "../redux/auth/PermissionChecker";
import WidgetHome from "../components/mobileView/WidgetHome";

function PrivateRoute({
  component: Component,
  currentUser,
  ...rest
}: any) {
  const location = useLocation();
  const isWidgetsPath = location.pathname.startsWith('/widgets');
  return (
    <Route
      {...rest}
      render={(props: any) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/auth/signin",
                state: { from: location },
              }}
            />
          );
        }
        if (isWidgetsPath) {
          return (
            <WidgetHome {...props}>
              <Component {...props} />
            </WidgetHome>
          );
        }
        return (
          <Home {...props}>
            <Component {...props} />
          </Home>
        );
      }}
    />
  );
}

export default PrivateRoute;
