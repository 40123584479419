import * as _ from "../../vendors/underscore-esm";
import * as Order2 from "../../../src/cqg-api/proto/order_2";
import { TradePlan } from "../../components/home/components/tradePlan/TradePlanType";
import { CQGEnvironment } from "./CQGEnvironment";
import { OrderState } from "../models/OrderState";
import { CQGConstants } from "../constants/CQGConstants";
import * as Shared from "../proto/common/shared_1";

class TradePlanManager {
  tradePlan: TradePlan;
  challengeAccount: any;
  checkRiskMargin: boolean;
  checkRiskCapital: boolean;
  refreshNotificationsCallbacks: (() => void)[];

  constructor() {
    this.tradePlan = {} as TradePlan;
    this.challengeAccount = null;
    this.checkRiskMargin = true;
    this.checkRiskCapital = true;
    this.refreshNotificationsCallbacks = [];
  }

  initializeTradePlan(tradePlan: any, challengeAccount: any) {
    this.tradePlan = tradePlan;
    this.challengeAccount = challengeAccount;
  }

  async createTradingTipNotification(text: string) {
    try {
      console.log(`Creating new trading tip notification: ${text}`);
      const response = await fetch("/notifications/new_trading_tip", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(
          `Error: Unable to notify server. Status: ${response.status}`
        );
      }
    } catch (error: any) {
      console.error(error.message);
    }
  }

  checkForTradingTips(order: any, protectiveOrder: any) {
    const tips: any = {};

    // if (
    //   this.challengeAccount &&
    //   order.accountId === this.challengeAccount.account.account_id
    // ) {
      const riskParams = this.buildRiskParams(order);
      if (riskParams) {
        if (this.hasViolatedMargin(riskParams)) {
          tips.marginRisk = `Your order of ${order.side} ${Math.abs(
            riskParams.orderQty
          )} ${riskParams.instrument.cmeSymbol} risked too much of your capital to margin a trade.`;
        }

        if (this.hasProtectiveOrder(protectiveOrder)) {
          if (
            this.protectiveOrderViolatedPctCapitalRisk(
              riskParams,
              order,
              protectiveOrder
            )
          ) {
            tips.protectivePctCapitalRisk =
              "Protective order exceeds the risk percentage of your capital on a trade.";
          }

          if (
            this.protectiveOrderViolatedCapitalRiskAmount(
              riskParams,
              order,
              protectiveOrder
            )
          ) {
            tips.protectiveCapitalRisk =
              "Protective order exceeds the risk amount of your capital on a trade.";
          }
        }

        if (
          !this.hasProtectiveOrder(protectiveOrder) &&
          this.isProtectiveStopAgainstOpenPosition(order, riskParams)
        ) {
          const mainOrderCostBasis = this.calculateOrderCostBasis(
            this.getOrderPrice(order, riskParams.instrument),
            riskParams.instrumentMultiplier,
            order.qty,
            order.side
          );
          const totalCostBasis = this.calculateTotalPositionCostBasis(
            riskParams.positionsForInstrument,
            riskParams.instrumentMultiplier
          );
          const costBasisDifference = Math.abs(
            mainOrderCostBasis + totalCostBasis
          );

          if (costBasisDifference > Number(this.tradePlan.capitalRiskPerTrade)) {
            tips.protectiveCapitalRisk =
              "Protective order exceeds the risk amount of your capital on a trade.";
          }

          const amount =
            riskParams.accountBalance *
            (Number(this.tradePlan.pctCapitalRiskPerTrade) / 100.0);
          if (costBasisDifference > amount) {
            tips.protectivePctCapitalRisk =
              "Protective order exceeds the risk percentage of your capital on a trade.";
          }
        }
      }
    // }

    return tips;
  }

  hasProtectiveOrder(order: any): boolean {
    return !_.isNull(order) && !_.isUndefined(order);
  }

  protectiveOrderViolatedPctCapitalRisk(
    params: { accountBalance: number },
    order: any,
    protectiveOrder: any
  ): boolean {
    const diff = this.calculateMainAndProtectiveCostDifference(
      params,
      order,
      protectiveOrder
    );
    const amount =
      params.accountBalance *
      (Number(this.tradePlan.pctCapitalRiskPerTrade) / 100.0);

    return diff > amount;
  }

  calculateMainAndProtectiveCostDifference(
    params: any,
    order: { qty: any; side: any },
    protectiveOrder: { qty: any; side: any }
  ): number {
    const mainOrderCostBasis = this.calculateOrderCostBasis(
      this.getOrderPrice(order, params.instrument),
      params.instrumentMultiplier,
      order.qty,
      order.side
    );
    const protectiveOrderCostBasis = this.calculateOrderCostBasis(
      this.getOrderPrice(protectiveOrder, params.instrument),
      params.instrumentMultiplier,
      protectiveOrder.qty,
      protectiveOrder.side
    );

    return Math.abs(mainOrderCostBasis + protectiveOrderCostBasis);
  }

  buildRiskParams(order: OrderState): any {
    const params: any = {};

    const accountSummaries = CQGEnvironment.Instance.selectedAccount?.getAccountSummaries();
    if(accountSummaries?.length == 0 || !accountSummaries[0].currentBalance)
    {
      return null;
    }
    params.accountBalance = accountSummaries[0].currentBalance;

    params.instrument = order.instrument;
    if(!params.instrument)
    {
      return null;
    }
    params.instrumentMultiplier = parseFloat(params.instrument.multiplier);
    params.positions = CQGEnvironment.Instance.selectedAccount?.getPositions();

    params.positionsForInstrument = _.filter(params.positions, function(position: any) {
      return position.getInstrument().contractId == params.instrument.contractId;
    });

    params.netPosition = _.reduce(params.positionsForInstrument, function(net: any, position: any) {
      if(!_.isUndefined(position.size))
      {
        if(position.isShort)
        {
          return net - position.size;
        }
        else
        {
          return net + position.size;
        }
      }
      else
      {
        return net;
      }
    }, 0);

    params.orderQty = order.side == Order2.Order_Side.SIDE_BUY ? order?.quantity : -(order?.quantity || 0);
    params.orderId = order.orderId;

    return params;
  };

  hasViolatedMargin(params: any): boolean {
    if(this.shouldCheckRiskMargin()) {
        const workingPosition = this.getWorkingOrderPosition( params );

        if( this.initiatesNewPosition( params.netPosition, params.orderQty ) || this.addsToPosition( params.netPosition, workingPosition, params.orderQty ) )
        {
        const orderMargin = Math.abs(params.orderQty * params.instrument.marginInitialRate);
        const allPositions = params.positionsForInstrument;

        let allPositionsMargin = 0;
        _.each(allPositions, function(position: any) {
            if(!_.isUndefined(position.size))
            {
            let size = 0;
            if(position.isShort)
            {
                size = position.size;
            }
            else
            {
                size = -position.size;
            }
            allPositionsMargin += Math.abs(size * position.getInstrument().marginInitialRate);
            }
        });

        if(this.marginExceeded(allPositionsMargin, orderMargin, params.accountBalance))
        {
            return true;
        }
        }
    }
    return false;
  }



  addRefreshNotificationCallback(callback: () => void) {
    this.refreshNotificationsCallbacks.push(callback);
  }

  refreshNotifications() {
    this.refreshNotificationsCallbacks.forEach((cb) => cb());
  }

  private getOrderPrice(order: any, instrument: any): number {
    if (this.isMarketOrder(order)) {
      return instrument.lastPrice;
    } else if (this.isLimitOrder(order)) {
      return order.scaled_limit_price;
    } else {
      return order.scaled_stop_price;
    }
  }

  private isMarketOrder(order: any): boolean {
    return order.order_type === Order2.Order_OrderType.ORDER_TYPE_MKT;
  }

  private isLimitOrder(order: any): boolean {
    return order.order_type === Order2.Order_OrderType.ORDER_TYPE_LMT;
  }

  private calculateOrderCostBasis(
    orderPrice: number,
    multiplier: number,
    size: number,
    side: any
  ): number {
    return orderPrice * multiplier * size * this.orderSideMultiplier(side);
  }

  private orderSideMultiplier(side: any): number {
    if (side === Order2.Order_Side.SIDE_BUY) {
      return 1.0;
    } else if (side === Order2.Order_Side.SIDE_SELL) {
      return -1.0;
    } else {
      return 0;
    }
  }

  private calculateTotalPositionCostBasis(
    positions: any[],
    multiplier: any
  ): number {
    return _.reduce(
      positions,
      (sum: number, position: { price: number; size: number; isShort: boolean; }) => sum + this.calculatePositionCostBasis(position, multiplier),
      0
    );
  }

  private calculatePositionCostBasis(
    position: { price: number; size: number; isShort: boolean },
    multiplier: number
  ): number {
    return (
      position.price * multiplier * position.size * (position.isShort ? -1.0 : 1.0)
    );
  }

  private protectiveOrderViolatedCapitalRiskAmount(
    params: any,
    order: any,
    protectiveOrder: any
  ): boolean {
    const diff = this.calculateMainAndProtectiveCostDifference(
      params,
      order,
      protectiveOrder
    );

    return diff > Number(this.tradePlan.capitalRiskPerTrade);
  }

  private isProtectiveStopAgainstOpenPosition(
    order: { stop_price: any },
    riskParams: { netPosition: number; orderQty: number }
  ): boolean {
    return (
      riskParams.netPosition + riskParams.orderQty === 0 &&
      !_.isNull(order.stop_price)
    );
  }

  private shouldCheckRiskMargin()
  {
    return this.checkRiskMargin && this.tradePlan.pctCapitalToMargin && Number(this.tradePlan.pctCapitalToMargin) > 0.0;
  };

  private getWorkingOrderPosition( params: any, workingOrders?: any )
  {
    if( _.isUndefined( workingOrders ) )
    {
      workingOrders = this.getWorkingOrders( params );
    }

    var workingOrderPosition = 0;

    _.each( workingOrders, function( order: OrderState )
    {
      if(order.side == Order2.Order_Side.SIDE_BUY)
      {
        workingOrderPosition += order.size || 0;
      }
      else
      {
        workingOrderPosition -= order.size || 0;
      }
    });

    return workingOrderPosition;
  }

  private getWorkingOrders( params: any ) {
    var allOrders = CQGEnvironment.Instance.selectedAccount?.getOrders();
    var workingStatuses = [CQGConstants.inClientOrderStatus, Shared.OrderStatus_Status.IN_TRANSIT,
        Shared.OrderStatus_Status.WORKING, Shared.OrderStatus_Status.IN_CANCEL,
        Shared.OrderStatus_Status.IN_MODIFY, Shared.OrderStatus_Status.ACTIVEAT];

    return _.filter( allOrders, function( order: any ) {
      return _.includes(workingStatuses, order.status) &&
      order.getInstrument().contractId == params.instrument.contractId &&
      order.orderId != params.orderId;
    });
  }

  private initiatesNewPosition(currentPosition: number, orderQty: number)
  {
    return currentPosition == 0 ||
           (currentPosition > 0 && currentPosition + orderQty < 0) ||
           (currentPosition < 0 && currentPosition + orderQty > 0);
  };

  private addsToPosition(currentPosition: number, workingOrderPosition: number, orderQty: number)
  {
    return ( currentPosition + workingOrderPosition <= 0 && orderQty < 0 ) ||
      ( currentPosition + workingOrderPosition >= 0 && orderQty > 0 );
  };

  private marginExceeded(positionMargins: number, orderMargin: number, accountBalance: number)
  {
    return positionMargins + orderMargin > accountBalance * (Number(this.tradePlan.pctCapitalToMargin) / 100.0);
  };
}

const tradePlanManager = new TradePlanManager();
export default tradePlanManager;
