const EmptyWorkspace = () => (
  <div className="emptyWorkspace">
    <img
      src="/images/icons/workspace.svg"
      alt="Workspace"
      height="48"
      width="46"
      style={{ color: "#5A6874" }}
    />
    <h3>Your workspace is empty</h3>
    <p>Add a new widget from the menu on the left to get started!</p>
  </div>
);

export default EmptyWorkspace;
