import { styled } from '@mui/system';
import {
  Select, Button, FormControl,
  ButtonOwnProps,
} from '@mui/material';

export const redErrorColor = '#D32F2F';
export const defaultBorderColor = 'rgba(0, 0, 0, 0.23)';
// Custom styling using MUI's styled utility
export const StyledFormControl = styled(FormControl)({
  marginBottom: '16px',
});
export const toggleButtonStyles = { padding: '0px !important', display: 'flex', width: '96%', justifyContent: 'space-between', alignItems: 'center', marginLeft: '4%' };

const getButtonColors = (orderSide?: 'BUY' | 'SELL', disabled?: boolean) => {
  if (disabled) {
    return {
      backgroundColor: 'var(--action-disabledBackground, #081D371F)',
      color: '#081D3761',
      hoverBackgroundColor: 'var(--action-disabledBackground, #081D371F)',
    };
  }

  switch (orderSide) {
    case 'BUY':
      return {
        backgroundColor: 'var(--buy-increase-main, #4CAF50)',
        color: '#fff',
        hoverBackgroundColor: '#388E3C',
      };
    case 'SELL':
      return {
        backgroundColor: 'var(--text-sell-decrease, #D32F2F)',
        color: '#fff',
        hoverBackgroundColor: '#C62828',
      };
    default:
      return {
        backgroundColor: 'var(--action-disabledBackground, #081D371F)',
        color: '#081D3761',
        hoverBackgroundColor: 'var(--action-disabledBackground, #081D371F)',
      };
  }
};

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'orderSide' && prop !== 'disabled',
})<{
  orderSide?: 'BUY' | 'SELL';
  disabled?: boolean;
}>(({ orderSide, disabled }) => {
  const { backgroundColor, color, hoverBackgroundColor } = getButtonColors(orderSide, disabled);

  return {
    fontFamily: "Averta-Regular",
    fontWeight: "700",
    lineHeight: "26px",
    letterSpacing: "1.5px",
    backgroundColor,
    color,
    height: '42px',
    borderRadius: '8px',
    fontSize: '12px',
    textTransform: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    '&:hover': {
      backgroundColor: hoverBackgroundColor,
    },
  };
});

const buttonNamedStyle = (styleName: 'BUY' | 'SELL' | 'AMEND' | 'CANCEL') => {
  let style = {};

  switch (styleName) {
    case 'BUY':
      style = {
        backgroundColor: 'var(--buy-increase-main, #4CAF50)',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#388E3C',
        },
      };
      break;
    case 'SELL':
      style = {
        backgroundColor: 'var(--text-sell-decrease, #D32F2F)',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#C62828',
        },
      };
      break;
    case 'AMEND':
      style = {
        backgroundColor: 'var(--buy-increase-main, #006EB6)',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#006EB6',
        },
      };
      break;
    case 'CANCEL':
      style = {
        color: '#0d47a1',
        border: '1px solid #006EB680',
      };
      break;
    default:
      style = {
        backgroundColor: 'var(--action-disabledBackground, #081D371F)',
        color: '#081D3761',
      }
  };
  return style;
}

export const ButtonNamedStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'orderSide' && prop !== 'disabled',
})<{
  styleName: 'BUY' | 'SELL' | 'AMEND' | 'CANCEL';
  disabled?: boolean;
}>(({ styleName, disabled }) => ({
  fontFamily: "Averta-Regular",
  fontWeight: "700",
  lineHeight: "26px",
  letterSpacing: "1.5px",
  height: '42px',
  borderRadius: '8px',
  fontSize: '12px',
  textTransform: 'none',
  cursor: 'pointer',
  pointerEvents: 'auto',
  ...buttonNamedStyle(styleName),
}));

export const StyledBlueBorderButton = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 11px',
  height: '42px',
  borderRadius: '4px',
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '14px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: '#006EB6', //'#D32F2F',
  cursor: 'pointer',
  '&:hover': { color: '#0d47a1' },
  border: '1px solid #006EB680',
  whiteSpace: 'nowrap',
  marginLeft: 0
}));

export const StyledRedTextButton = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 11px',
  height: '42px',
  borderRadius: '4px',
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '14px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: '#D32F2F',
  cursor: 'pointer',
  '&:hover': { color: '#0d47a1' },
  whiteSpace: 'nowrap',
  marginLeft: 0
}));

export const StyledSelect = styled(Select)({
  fontFamily: "Averta-Regular",
  height: '38px',
  borderRadius: '4px',
  backgroundColor: '#fff',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d1d1d1',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
});

export const gridItemStyles = { paddingTop: '6px !important', height: '67px' };
export const inputStyles = {
  height: '36px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '& input': {
      padding: '6.5px 12px',
      border: 'none',
      textAlign: 'center',
      fontSize: '15px',
    },
    '& fieldset': {
      border: 'none',
    },
  },
};

export const inputStyleBordered = {
  lineHeight: '36px',
  border: '1px solid',
  borderColor: "rgba(0, 0, 0, 0.23)",
  borderRadius: '4px',
  padding: "0 0 0 14px",
  margin: '0 0 16px 0',
  backgroundColor: 'transparent',
  '& input': {
    padding: '6.5px 12px',
    border: 'none',
    textAlign: 'center',
    fontSize: '15px',
  },
  '& fieldset': {
    border: 'none',
  },
};

export const tabStyles = {
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
  },
  '& .MuiTab-root': {
    borderBottom: '1px solid #d1d1d1',
    fontFamily: 'Averta-Regular',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: "1.5px",
  },
  padding: '8px 0 0 16px',
  marginBottom: '12px'
};
