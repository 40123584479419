import { useCallback, useEffect, useState } from "react";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { getDefaultTIF } from "../utils";
import { OrderSide, OrderType } from "../../../types";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { UseFormSetValue } from "react-hook-form";
import { IFormInput } from "../types";
import { CQGConstants } from "../../../cqg-api/constants/CQGConstants";

const useOrderTypeChange = (
  selectedContract: Instrument | null,
  amendOrder: OrderState | undefined,
  orderType: string,
  orderSide: OrderSide | undefined,
  setValue: UseFormSetValue<IFormInput>,
  lastPrice: any,
  defaultContractLastPrice: any,
  externalData: any,
  widgetData: any,
) => {
  const [isEnabledLmtPrice, setEnabledLmtPrice] = useState<boolean>(true);
  const [isEnabledStpPrice, setEnabledStpPrice] = useState<boolean>(true);

  const getDefaultLimitPrice = useCallback(() => {
    let limitPrice: number = 0;

    limitPrice = widgetData?.data.limitPrice ?? externalData?.data?.limitPrice! ?? selectedContract?.lastPrice;
    // ?? defaultContractLastPrice ?? 0;

    return limitPrice;
  }, [externalData?.data?.limitPrice, selectedContract?.lastPrice, widgetData?.data.limitPrice]);

  const getDefaultStopPrice = useCallback(() => {
    let stopPrice: number = 0;
    stopPrice = widgetData?.data.stopPrice ?? externalData?.data?.stopPrice! ?? lastPrice;
    // ?? defaultContractLastPrice ?? 0;

    return stopPrice;
  }, [externalData?.data?.stopPrice, lastPrice, widgetData?.data.stopPrice]);

  useEffect(() => {
    setValue(
      "limitPrice",
      parseFloat(DisplayUtil.toDisplayPrice(getDefaultLimitPrice(), selectedContract!)?.toString() || "") || 0,
    );
    setValue(
      "stopPrice",
      parseFloat(DisplayUtil.toDisplayPrice(getDefaultStopPrice(), selectedContract!)?.toString() || "") || 0,
    );
    setValue("rawLimitPrice", getDefaultLimitPrice() || 0);
    setValue("rawStopPrice", getDefaultStopPrice() || 0);
    setValue("timeInForce", getDefaultTIF(amendOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  useEffect(() => {
    switch (orderType) {
      case OrderType.Stl: {
        setEnabledLmtPrice(true);
        setEnabledStpPrice(true);
        if (orderSide) {
          setValue(`oco.${orderSide}.takeProfit.isSelected`, false);
          setValue(`oco.${orderSide}.stopLoss.isSelected`, false);
        }
        break;
      }
      case OrderType.Stp: {
        setEnabledLmtPrice(false);
        setEnabledStpPrice(true);
        if (orderSide) {
          setValue(`oco.${orderSide}.takeProfit.isSelected`, false);
          setValue(`oco.${orderSide}.stopLoss.isSelected`, false);
        }
        break;
      }
      case OrderType.Mkt: {
        setEnabledLmtPrice(false);
        setEnabledStpPrice(false);
        break;
      }
      case OrderType.Lmt: {
        setEnabledLmtPrice(true);
        setEnabledStpPrice(false);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  useEffect(() => {
    if (widgetData?.data instanceof OrderState) {
      const order = widgetData?.data as OrderState;
      const ordeType = CQGConstants.getOrderType(order.orderType);
      setValue("orderType", ordeType);

      setValue("limitPrice", getDefaultLimitPrice());
      setValue("stopPrice", getDefaultStopPrice());
      setValue("rawLimitPrice", getDefaultLimitPrice());
      setValue("rawStopPrice", getDefaultStopPrice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData?.data]);

  return { isEnabledLmtPrice, isEnabledStpPrice, getDefaultLimitPrice, getDefaultStopPrice };
};

export default useOrderTypeChange;
