import { Button } from "@mui/material";
import "./PriceLadder.css";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useMemo, useState } from "react";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderSide, OrderType, Widget } from "../../../types";
import SelectedContractInfo from "../../TradeTicketDrawer/SelectedContractInfo";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { subscribeToInstrumentsDepths } from "../../../utils/subscriptions";
import { capitalizeKeys, mapInstrumentData } from "../../../utils/utils";
import { buyMarketButtonStyles, sellMarketButtonStyles } from "./PriceLadderStyles";
import PriceLadderBook from "./PriceLadderBook/PriceLadderBook";
import PriceLadderFooter from "./PriceLadderFooter";
import useScrollHandlers from "./hooks/useScrollHandler";
import TimeInForce from "../../TradeTicketDrawer/components/TimeInForce";
import FlattenAllPositions from "./FlattenAllPositions";
import CancelAllOrders from "./CancelAllOrders";
import PositionsCount from "./PostionsCount";
import SelectContract from "./SelectContract";
import OrderSettingsImage from "./OrderSettings";
import { ocoInitial } from "./types";
import { CQGEnvironment } from "../../../cqg-api/services/CQGEnvironment";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { makeOrder } from "../../../cqg-api/controllers/tradingTicketController";
import Notification from "../../shared/notification/Notification";
import { AvailableProductsContext } from "../../../App";
import OrderSettingsDialog from "./OrderSettingsDialog/OrderSettingsDialog";
import { Account } from "../../../cqg-api/models/Account";
import { IFormInput } from "../../TradeTicketDrawer/types";
import Quantity from "../../TradeTicketDrawer/components/Quantity";
import { createOrderStateObject, getPrice } from "../../TradeTicketDrawer/utils";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import { setWidgetDataValue } from "../../../redux/products/chartWidget";
import { useDispatch } from "react-redux";

interface PriceLadderProps {
  workspaceId: number;
  widget: Widget;
}

const PriceLadder = ({ workspaceId, widget }: PriceLadderProps) => {
  const dispatch = useDispatch();
  const [openOrderSettings, setOpenOrderSettings] = useState(false);
  const [oco, setOco] = useState(ocoInitial);
  const onClose = () => {
    setOpenOrderSettings(false);
  };
  const { centerDivRef, priceLadderBookRef, scrollUpHandler, scrollDownHandler, scrollToCenterHandler } =
    useScrollHandlers();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      contract: widget.contractIds?.[0],
      timeInForce: 1,
      side: OrderSide.Buy,
      orderType: OrderType.Lmt,
      quantity: 1,
      limitPrice: 0,
      stopPrice: 0,
      // rawLimitPrice:0,
      rawStopPrice: 0,
      oco: ocoInitial,
    },
  });
  const selectedContractDisplayValue = watch("contract");
  const quantityValue = watch("quantity");
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);
  const { panelRef, isSmallPanel } = useResponsivePanel(350);

  const monthlyContracts = useMemo(() => {
    const contracts: any = monthlyContractsByDisplayNameMap[selectedContractDisplayValue];
    return contracts?.monthlyContracts ?? [];
  }, [selectedContractDisplayValue, monthlyContractsByDisplayNameMap]);

  const selectedContract = useMemo(
    () => monthlyContracts.find((contract: Instrument) => contract.displayName === selectedContractDisplayValue),
    [monthlyContracts, selectedContractDisplayValue],
  );

  const [contractRealTimeData, setContractRealTimeData] = useState<Instrument>({ ...selectedContract });

  const { realTimeMarketData } = useRealTimeMarketData();

  useEffect(() => {
    if (selectedContract) {
      setContractRealTimeData({ ...selectedContract });
      subscribeToInstrumentsDepths([selectedContract.contractId] as number[]);
    }
  }, [selectedContract]);

  useEffect(() => {
    if (selectedContract) {
      setContractRealTimeData(mapInstrumentData(selectedContract, realTimeMarketData));
    }
  }, [realTimeMarketData, selectedContract]);

  const onOrderSettingsImageClick = () => {
    setOpenOrderSettings(true);
  };

  const placeOrder = (order: OrderState, account: Account) => {
    CQGEnvironment.Instance.cqgService.placeOrder(order, account);
  };

  const createOrderHandler = async (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    try {
      // Extract account and data values
      const accountId = CQGEnvironment.cqgAccountAuthInfo?.accountId as number;
      const accounts = CQGEnvironment.Instance.accountsManager.getAccount(accountId);
      const data = getValues();
      const buildOrderState = createOrderStateObject(
        data,
        null,
        selectedContract,
        orderSide,
        selectedContract?.correctPriceScale,
      );
      // need to simplify profitLossStrategy for Trade Ticket and Price Ladder
      const profitLossStrategy = {
        profit: {
          price: getPrice(data.oco?.[orderSide]?.takeProfit, true, price, selectedContract, orderSide),
        },
        loss: {
          price: getPrice(data.oco?.[orderSide]?.stopLoss, false, price, selectedContract, orderSide),
        },
      };
      const profitLossStrategyValue =
      orderType === OrderType.Mkt ? buildOrderState.profitLossStrategy : profitLossStrategy;
      
      // Create order
      const orderState = {
        ...buildOrderState,
        side: orderSide,
        orderType,
        limitPrice: price,
        strikePrice: price,
        rawLimitPrice: price,
        profitLossStrategy: profitLossStrategyValue,
      };
      const newOrder = makeOrder(orderState);

      // Attempt to place the order
      await placeOrder(newOrder as OrderState, accounts);
    } catch (error) {
      console.error("Order placement failed:", error);
      Notification.error(`Order could not be placed`);
    }
  };

  const placeMarkerOrder = (orderSide: OrderSide) => {
    createOrderHandler(orderSide, OrderType.Mkt, null);
  };

  const onSaveOcoSettings = (data: any) => {
    setOco(data);
    const dataWithTitle = {
      data: data,
      widgetName: widget.id,
      contractMonth: selectedContractDisplayValue,
    };
    dispatch(setWidgetDataValue(dataWithTitle));
    onClose();
  };

  useEffect(() => {
    if (openOrderSettings) {
      setValue("oco", oco);
    }
  }, [oco, openOrderSettings, setValue]);

  const validOco = () => {
    if (
      oco?.[OrderSide.Buy].takeProfit.isSelected ||
      oco?.[OrderSide.Buy].stopLoss.isSelected ||
      oco?.[OrderSide.Sell].takeProfit.isSelected ||
      oco?.[OrderSide.Sell].stopLoss.isSelected
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (widget.widgetData?.contractMonth === selectedContractDisplayValue) {
      setOco(capitalizeKeys(widget.widgetData.data) as typeof ocoInitial);
    } else {
      setOco(ocoInitial);
    }
  }, [widget.widgetData, selectedContractDisplayValue]);

  return (
    <>
      <div ref={panelRef} className="price-ladder-container">
        <div className="price-ladder-top">
          <SelectContract control={control} name="contract" contracts={monthlyContracts}></SelectContract>
          <PositionsCount contract={selectedContract}></PositionsCount>
          <OrderSettingsImage onClick={onOrderSettingsImageClick} validOco={validOco} />
        </div>

        <div className="price-ladder-top">
          <div className="price-ladder-width">
            <TimeInForce control={control} />
          </div>

          <FlattenAllPositions contract={selectedContract}></FlattenAllPositions>

          <CancelAllOrders contract={selectedContract}></CancelAllOrders>
        </div>

        <div className="price-ladder-top">
          <div className="price-ladder-width">
            <Button variant="contained" sx={buyMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Buy)}>
              BUY MKT
            </Button>
          </div>
          <Quantity isSmallPanel={isSmallPanel} control={control} setValue={setValue} errors={errors} />
          <div className="price-ladder-width">
            <Button variant="contained" sx={sellMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Sell)}>
              SELL MKT
            </Button>
          </div>
        </div>

        <div style={{ margin: "10px" }}>
          <SelectedContractInfo selectedContract={contractRealTimeData} isPriceLadder />
        </div>

        <PriceLadderBook
          centerDivRef={centerDivRef}
          priceLadderBookRef={priceLadderBookRef}
          quantityValue={quantityValue}
          realTimeInstrument={contractRealTimeData}
          createOrderHandler={createOrderHandler}
          contract={selectedContract}
          oco={oco}
        />

        <PriceLadderFooter
          scrollUpHandler={scrollUpHandler}
          scrollToCenterHandler={scrollToCenterHandler}
          scrollDownHandler={scrollDownHandler}
          contract={selectedContract}
        />
      </div>
      {openOrderSettings && (
        <OrderSettingsDialog
          open={openOrderSettings}
          onClose={onClose}
          control={control}
          selectedContract={contractRealTimeData}
          watch={watch}
          selectedInstrument={selectedContract}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          onSaveOcoSettings={onSaveOcoSettings}
        />
      )}
    </>
  );
};

export default PriceLadder;
