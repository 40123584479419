// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-table {
  width: 100%;
}

.tableDataMarket {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.column2,
.column3 {
  text-align: right;
}

.column3 {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.contract-name {
  color: #081d3799;
}

.contract-chip-container {
  display: flex;
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 4px;
}

.contract-chip {
  cursor: pointer;
  border-color: transparent;
}

.contract-chip.selected {
  background-color: #006EB6;
  color: white;
  border-color: #006EB6;
}

.contract-chip:not(.selected) {
  background-color: #e0e0e0;
}
.chart-price-ladder-button {
  width: 100%;
  border-color: #006EB6;
  color: #006EB6;
}

.trade-button {
  width: 100%;
  background-color: #006EB6;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/availableProducts/simpleMobileWidget.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kCAAkC;EAClC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,wBAAwB;EACxB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".simple-table {\n  width: 100%;\n}\n\n.tableDataMarket {\n  display: grid;\n  width: 100%;\n  grid-template-columns: 2fr 1fr 1fr;\n  gap: 10px;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n}\n\n.column2,\n.column3 {\n  text-align: right;\n}\n\n.column3 {\n  display: flex;\n  align-items: end;\n  justify-content: flex-end;\n}\n\n.contract-name {\n  color: #081d3799;\n}\n\n.contract-chip-container {\n  display: flex;\n  margin-bottom: 16px;\n  overflow-x: auto;\n  white-space: nowrap;\n  padding-bottom: 8px;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  gap: 4px;\n}\n\n.contract-chip {\n  cursor: pointer;\n  border-color: transparent;\n}\n\n.contract-chip.selected {\n  background-color: #006EB6;\n  color: white;\n  border-color: #006EB6;\n}\n\n.contract-chip:not(.selected) {\n  background-color: #e0e0e0;\n}\n.chart-price-ladder-button {\n  width: 100%;\n  border-color: #006EB6;\n  color: #006EB6;\n}\n\n.trade-button {\n  width: 100%;\n  background-color: #006EB6;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
