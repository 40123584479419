import { useDispatch } from "react-redux";
import { CMESymbolService } from "../../../cqg-api/services/CMESymbolService";
import { CQGEnvironment } from "../../../cqg-api/services/CQGEnvironment";
import { CQGService } from "../../../cqg-api/services/CQGService";
import { useFetchCharts, useCreateChart, useGetChart, useUpdateChart, useRemoveChart } from "../../../hooks/api/chartSetting";
import { setWidgetDataValue } from "../../../redux/products/chartWidget";

export var useChartConfig = (cqgUDFDataFeed, symbol, containerId, onPlaceOrder, widget) =>  {
   const { loadCharts } = useFetchCharts();
   const { createChartAsync } = useCreateChart();
   const { getChartAsync } = useGetChart();
   const { updateChartAsync } = useUpdateChart();
   const { removeChartAsync } = useRemoveChart();
   const dispatch = useDispatch();

   return {
        disabled_features: ["link_to_tradingview", "header_screenshot", "study_templates","order_panel", "header_symbol_search", "symbol_search_hot_key"],
        // enabled_features: ['dome_widget'],
        width: '100%',
        height: '100%',
        symbol: symbol || 'AA',
        interval: '5',
        toolbar_bg: '#f4f7f9',
        container_id:  "TVChartContainer" + containerId,
        datafeed: cqgUDFDataFeed,
        library_path: '/charting_library/',
        locale: 'en',
        timezone: 'America/Chicago',
        custom_css_url: '/charting_library/static/css/custom.css',
        time_frames: [
        { text: "5y", resolution: "1825" },
        { text: "1y", resolution: "365" },
        { text: "6m", resolution: "180" },
        { text: "3m", resolution: "90" },
        { text: "1m", resolution: "30" },
        { text: "5d", resolution: "5" },
        { text: "1d", resolution: "1" },
        ],
        overrides: {
        'symbolWatermarkProperties.transparency': 100
        },
        logo: {
            image: 'images/chart_logo.png',
            link: 'https://www.cmegroup.com'
        },
        brokerFactory: function(host) {
          var broker = new Broker(host);
          return broker;
        },
        brokerConfig: {
          configFlags: {
            supportLevel2Data: true,
            supportDOM: true,
            supportBottomWidget: false,
            supportReversePosition: false,
            supportClosePosition: false,
            supportPLUpdate: false,
            showQuantityInsteadOfAmount: false,
            supportPositions: false,
            supportOrders: true,
            supportExecutions: false,
            supportTrades: true,
            supportPositionBrackets: false,
            supportBalances: false,
            supportOrderPreview: true,
            supportMarketOrders: true,
            supportLimitOrders: true,
            supportStopOrders: true,
            supportMarketBrackets: false,
            supportModifyOrder: true,
            supportAddBracketsToExistingOrder: false
          },
          customUI: {
            showOrderDialog: function(order) {
               console.log(order);
               resolveSymbolFromCme(order.symbol).then(function (instrument) {  
                const symbols = CQGService.resolveSymbols( instrument.cqg_symbol );
                symbols.forEach((symbol) => {
                  symbol.resolvePromise.then(
                    (inst) => {
                      if(onPlaceOrder) {
                        onPlaceOrder(order, inst);
                      }
                    },
                    (error) => {
                      console.debug("Error resolving cqg symbol '" + error.symbol + "'. Reason[ " + error.reason + " ]");
                    },
                  );
                });
              }, function (error) {
                console.log("SYMBOL INFO REJECTED:", symbol, error);
              });
            }
          },
          DOMData: {
            snapshot: true,
          }
        },
        save_load_adapter: {
          getAllCharts: loadCharts,
          saveChart: (chartData) => {
            const dataWithTitle = {
              data: {
                chartId: null,
                chartName: chartData.name,
              },
              widgetName: widget.id
            };
            dispatch(setWidgetDataValue(dataWithTitle));

            if(chartData.id){
              return updateChartAsync(chartData);
            } else {
              return createChartAsync(chartData);
            }
          },
          getChartContent: (chartId) => {
            const dataWithTitle = {
              data: {
                chartId: chartId,
                chartName: null,
              },
              widgetName: widget.id
            };
            dispatch(setWidgetDataValue(dataWithTitle));

            return getChartAsync(chartId) 
          },
          removeChart: (chartId) => { return removeChartAsync(chartId)}
        }
    }
}

export const chartLayout = (symbolSize) => {
    let layout = 's'
    if (symbolSize === 7) {
        return layout;
    }
    switch (symbolSize) {
        case 2:
        case 3:
          layout = `${symbolSize}h`
          break;
        case 4:
        case 6:
        case 8:
          layout = symbolSize.toString();
          break;
        case 5:
          layout = `${symbolSize}s`;
          break;
        default:
          break;
    }

    return layout;
}

export const generateMultiChartLayout = (tvWidget, layoutSize, symbols) => {
    setTimeout(() => {
        if(layoutSize > 1) {
          for(let i = 1; i <= layoutSize -1; i++) {
            tvWidget.chart(i).setSymbol(symbols[i]);
          }
        }
      })
}

function Broker(host) {
  const _this = this;

  this._host = host;

  this.updateOrder = function (order) {
    _this._host._orderUpdate(order);
  }

  this.connectionStatus = function () {
    return 1 /* Connected */;
  };

  this.orders = function () {
  };

  this.positions = function () {
    return new Promise(function (resolve, reject) {
      resolve([]);
    });
  }

  this.executions = function () {
    return new Promise(function (resolve, reject) {
      resolve([]);
    });
  };

  this.isTradable = function (symbol) {
    return Promise.resolve(true);
  }

  this.accountManagerInfo = function () {
    const selected = CQGEnvironment.selectedAccount;
    if(!selected) return false;
    const info = {
      accountInfo: [
        {id: selected.id, name: selected.name}
      ]
    };

    return info;
  }

  this.accountInfo = function () {
    return _this.accountManagerInfo()[0];
  }

  this.symbolInfo = function (symbol) {
    var symbolWithoutExchange = stripExchangeFromSymbol(symbol);

    return new Promise(function (resolve, reject) {
      resolveSymbolFromCme(symbolWithoutExchange).then(function (instrument) {
        var result = {
          qty: {
            min: 1, max: 10, step: 1
          },
          pipSize: instrument.minmove,
          pipValue: 12.50,
          minTick: parseFloat(instrument.tick_size),
          type: instrument.type,
          description: instrument.description,
          allowedDurations: []
        };

        console.log("SYMBOL INFO RESOLVED:", symbol, instrument, result, resolve);
        resolve(result);

      }, function (error) {
        console.log("SYMBOL INFO REJECTED:", symbol, error);
        reject(error);
      });
    });
  };

  this.previewOrder = function (order) {
    console.log("Preview Order:", order);
  }

  this.placeOrder = function (order, silently) {
  }

  this.cancelOrder = function (order, fromCqg, filled) {
  }

  this.cancelOrders = function (symbol, side, ordersIds) {
  };

  this.modifyOrder = function (order, filled) {
  }

  this.closeTrade = function (tradeId) {
    console.log("Close Trade:", tradeId);
  }
}

export const resolveSymbolFromCme = (symbol) => {
  var promise = new Promise( function( resolve, reject ) {
    CMESymbolService.getInstrumentFromCmeSymbol( symbol ).then( function( instrument ) {
      resolve(instrument);
    }, function(rejected) {
      reject(rejected);
    });
  });

  return promise;  
}

export const stripExchangeFromSymbol = (symbol) => {
  var splitted = symbol.split(":");
  return splitted.length > 1 ? splitted[1] : symbol;
}