import { TimeUtil } from "../utils/TimeUtil";
import * as Order2 from "../proto/order_2";
import { Account } from "./Account";
import { Instrument } from "./Instrument";
import { OrderStatus_Status, TransactionStatus_Status } from "../proto/common/shared_1";
import { DisplayUtil } from "../utils/DisplayUtil";
import { CQGConstants } from "../constants/CQGConstants";
import { booleanNullable, dateNullable, numberNullable, stringNullable } from "../types/Types";
import { arrFindLast, sortBy } from "../utils/ArrayUtil";
import { ModifyUserAttributes, UserAttribute } from "../proto/user_attribute_2";
import { UserAlerts } from "../services/UserAlerts";

export class OrderState {
  account: Account;
  instrument: Instrument;

  orderType: numberNullable = null;
  side: numberNullable = null;
  when: Date | null = null;
  clientOrderId: stringNullable = null;
  duration: numberNullable = null;
  isManual: booleanNullable = null;
  goodThruDate: dateNullable = null;
  goodThruTime: dateNullable = null;
  limitPrice: numberNullable = null;
  stopPrice: numberNullable = null;
  size: numberNullable = null;
  enteredByUser: stringNullable = null;
  tradelog: any = null; // Replace 'any' with the actual type if possible
  profitLossStrategy: any = null; // Replace 'any' with the actual type if possible
  strategyMainChainOrderId: stringNullable = null;

  chainOrderId: stringNullable = null;
  orderId: stringNullable = null;
  statusTime: Date | null = null;
  status: numberNullable = null;
  avgFillPrice: numberNullable = null;
  filledSize: numberNullable = null;
  unfilledSize: numberNullable = null;
  execOrderId: stringNullable = null;

  fillTime: Date | null = null;
  cancelTime: Date | null = null;
  rejectTime: Date | null = null;
  rejectMessage: stringNullable = null;
  displayRejectMessage: stringNullable = null;

  canBeCancelled: boolean | null = null;
  canBeModified: boolean | null = null;

  displayContract: stringNullable = null;
  putCall: stringNullable = null;
  strikePrice: numberNullable = null;
  month: stringNullable = null;
  displayCurrency: stringNullable = null;

  displayType: stringNullable = null;
  displaySide: stringNullable = null;
  displayStatus: stringNullable = null;
  displayDuration: stringNullable = null;

  displaySize: numberNullable = null;
  displayFilledSize: numberNullable = null;
  displayUnfilledSize: numberNullable = null;
  displayWhen: stringNullable = null;
  displayStatusTime: stringNullable = null;
  displayLimitPrice: numberNullable = null;
  displayStopPrice: numberNullable = null;

  displayOrderNumber: stringNullable = null;
  displayServerNumber: stringNullable = null;
  displayOrderId: stringNullable = null;
  displayUser: stringNullable = null;
  displayAvgFillPrice: numberNullable = null;

  displayFillTime: stringNullable = null;
  displayCancelTime: stringNullable = null;
  displayUserComment: stringNullable = null;
  displayBuySize: stringNullable = null;
  displaySellSize: stringNullable = null;
  displayOrderLimitPrice: numberNullable = null;
  displayOrderStopPrice: numberNullable = null;
  type: numberNullable = null;
  quantity?: number;
  orderData?: any;
  userAlerts: typeof UserAlerts | null = null;

  constructor(account: Account, instrument: Instrument) {
    this.account = account;
    this.instrument = instrument;
  }

  getAccount(): Account {
    return this.account;
  }

  getInstrument(): Instrument {
    return this.instrument;
  }

  setModified(): void {
    this.canBeCancelled = false;
    this.canBeModified = false;
  }

  update(orderStatus: Order2.OrderStatus): void {
    this.chainOrderId = orderStatus.chainOrderId;
    this.orderId = orderStatus.orderId;
    this.statusTime = TimeUtil.toUtcDate(orderStatus.statusUtcTime as number);
    this.status = orderStatus.status;
    this.filledSize = orderStatus.uint32FillQty;
    this.unfilledSize = orderStatus.uint32RemainingQty;
    this.execOrderId = orderStatus.execOrderId;
    this.avgFillPrice = this.instrument.correctPriceScale
      ? orderStatus.scaledAvgFillPrice * this.instrument.correctPriceScale
      : this.avgFillPrice;
    this.enteredByUser = orderStatus.enteredByUser;

    const transactionStatuses: Order2.TransactionStatus[] = orderStatus.transactionStatuses;

    if (transactionStatuses && transactionStatuses.length) {
      const sortedTransactionStatuses = sortBy(
        transactionStatuses,
        (status: Order2.TransactionStatus) => status.transUtcTime,
      );

      const fillTransaction = arrFindLast(
        sortedTransactionStatuses,
        (transaction: Order2.TransactionStatus) => transaction.status === TransactionStatus_Status.FILL,
      );
      if (fillTransaction) {
        this.fillTime = TimeUtil.toUtcDate(fillTransaction.trans_utc_time);
      }
      const cancelTransaction = arrFindLast(
        sortedTransactionStatuses,
        (transaction: Order2.TransactionStatus) => transaction.status === TransactionStatus_Status.IN_CANCEL,
      );
      if (cancelTransaction) {
        this.cancelTime = TimeUtil.toUtcDate(cancelTransaction.trans_utc_time);
      }

      const rejectTransaction = arrFindLast(
        sortedTransactionStatuses,
        (transaction: Order2.TransactionStatus) =>
          transaction.status === TransactionStatus_Status.REJECTED ||
          transaction.status === TransactionStatus_Status.REJECT_CANCEL ||
          transaction.status === TransactionStatus_Status.REJECT_MODIFY,
      );

      if (rejectTransaction) {
        this.rejectTime = TimeUtil.toUtcDate(rejectTransaction.transUtcTime);
        this.rejectMessage = rejectTransaction.textMessage;
      }
    }

    this.canBeCancelled = [
      OrderStatus_Status.WORKING,
      OrderStatus_Status.ACTIVEAT,
      OrderStatus_Status.SUSPENDED,
      OrderStatus_Status.DISCONNECTED,
    ].includes(orderStatus.status);

    this.canBeModified = orderStatus.status === OrderStatus_Status.WORKING;

    const order = orderStatus.order;
    if (order) {
      const scale = this.instrument.correctPriceScale!;

      this.orderType = order.orderType;
      this.side = order.side;
      this.when = TimeUtil.toUtcDate(order.whenUtcTime as number);
      this.clientOrderId = order.clOrderId;
      this.duration = order.duration;
      this.isManual = order.isManual;
      this.goodThruDate = order.goodThruDate ? TimeUtil.toUtcDate(order.goodThruDate) : this.goodThruDate;
      this.goodThruTime = order.goodThruUtcTime ? TimeUtil.toUtcDate(order.goodThruUtcTime) : this.goodThruTime;

      this.limitPrice = order.scaledLimitPrice ? order.scaledLimitPrice * scale : this.limitPrice;
      this.stopPrice = order.scaledStopPrice ? order.scaledStopPrice * scale : this.stopPrice;
      this.size = order.uint32Qty;

      const userAttributes = order.userAttributes;
      if (userAttributes && userAttributes.length) {
        const tradelogJsonAttribute = userAttributes.find((attribute) => attribute.name === "tradelog_json");
        const profitLossJsonAttribute = userAttributes.find((attribute) => attribute.name === "profitLoss_json");
        const strategyMainChainOrderIdAttribute = userAttributes.find(
          (attribute) => attribute.name === "strategyMainChainOrderId",
        );

        if (tradelogJsonAttribute) {
          this.tradelog = tradelogJsonAttribute.value ? JSON.parse(tradelogJsonAttribute.value) : this.tradelog;
        }

        if (profitLossJsonAttribute) {
          this.profitLossStrategy = profitLossJsonAttribute.value
            ? JSON.parse(profitLossJsonAttribute.value)
            : this.profitLossStrategy;
        }

        if (strategyMainChainOrderIdAttribute) {
          this.strategyMainChainOrderId = strategyMainChainOrderIdAttribute.value;
        }
      }
    }

    this.updateDisplayValues();
  }

  assign(orderData: OrderState): void {
    this.clientOrderId = generateClientOrderId();
    this.isManual = true;
    this.when = new Date();
    this.statusTime = new Date();
    this.status = CQGConstants.inClientOrderStatus;

    this.orderType = orderData.type;
    this.duration = orderData.duration;
    this.side = orderData.side;
    this.size = orderData.size;
    const orderLimitPrice = DisplayUtil.fromDisplayPrice(orderData.displayOrderLimitPrice!, this.instrument);
    const orderStopPrice = DisplayUtil.fromDisplayPrice(orderData.displayOrderStopPrice!, this.instrument);
    switch (orderData.type) {
      case Order2.Order_OrderType.ORDER_TYPE_LMT:
        this.limitPrice = orderLimitPrice;
        break;
      case Order2.Order_OrderType.ORDER_TYPE_STP:
        this.stopPrice = orderStopPrice;
        break;
      case Order2.Order_OrderType.ORDER_TYPE_STL:
        this.limitPrice = orderLimitPrice;
        this.stopPrice = orderStopPrice;
        break;
      default:
        break;
    }
    this.goodThruDate = orderData.goodThruDate || null;
    this.tradelog = orderData.tradelog;
    this.profitLossStrategy = orderData.profitLossStrategy;
    this.strategyMainChainOrderId = orderData.strategyMainChainOrderId;

    this.updateDisplayValues();
  }

  updateDisplayValues(): void {
    const instrument = this.instrument;
    const self = this;
    if (!instrument.displayName) {
      instrument.resolveFromCmeSymbol().then((value) => {
        self.displayContract = value ? value : instrument.title;
        self.putCall = instrument.putCallAbbrev();
        self.strikePrice = instrument.strikePrice;
        self.month = instrument.month;
      });
    } else {
      this.displayContract = instrument.displayName;
      this.putCall = instrument.putCallAbbrev();
      this.strikePrice = instrument.strikePrice;
      this.month = instrument.month;
    }
    this.displayCurrency = instrument.currency;

    this.displayType = CQGConstants.getOrderType(this.orderType);
    this.displaySide = CQGConstants.getOrderSide(this.side);

    if (this.status === OrderStatus_Status.WORKING && this.filledSize! > 0 && this.unfilledSize! > 0) {
      this.displayStatus = "PARTIALLY FILLED";
    } else if (this.status === OrderStatus_Status.ACTIVEAT) {
      this.displayStatus = "HOLD TO OPEN";
    } else {
      this.displayStatus = CQGConstants.getOrderStatus(this.status);
    }

    this.displayDuration = CQGConstants.getOrderDuration(this.duration);

    this.displaySize = this.size;
    this.displayFilledSize = this.filledSize;
    this.displayUnfilledSize = this.unfilledSize;
    this.displayWhen = DisplayUtil.toDisplayTime(this.when!);
    this.displayStatusTime = DisplayUtil.toDisplayTime(this.statusTime!);
    this.displayLimitPrice = null;
    this.displayStopPrice = null;

    switch (this.orderType) {
      case Order2.Order_OrderType.ORDER_TYPE_LMT:
        this.displayLimitPrice = DisplayUtil.toDisplayPrice(this.limitPrice!, instrument);
        break;
      case Order2.Order_OrderType.ORDER_TYPE_STL:
        this.displayStopPrice = DisplayUtil.toDisplayPrice(this.stopPrice!, instrument);
        this.displayLimitPrice = DisplayUtil.toDisplayPrice(this.limitPrice!, instrument);
        break;
      case Order2.Order_OrderType.ORDER_TYPE_STP:
        this.displayStopPrice = DisplayUtil.toDisplayPrice(this.stopPrice!, instrument);
        break;
      default:
        break;
    }

    this.displayOrderNumber = this.orderId;
    this.displayServerNumber = this.orderId;
    this.displayOrderId = this.execOrderId;
    this.displayUser = this.enteredByUser;
    this.displayAvgFillPrice = this.avgFillPrice ? DisplayUtil.toDisplayPrice(this.avgFillPrice, instrument) : null;

    this.displayFillTime = DisplayUtil.toDisplayTime(this.fillTime!);
    this.displayCancelTime = DisplayUtil.toDisplayTime(this.cancelTime!);
    if (this.tradelog) {
      this.displayUserComment = this.tradelog.comments;
    }

    const displaySizeWithFilled =
      this.filledSize !== this.size ? this.filledSize + "(" + this.size + ")" : this.size?.toString();
    this.displayBuySize = this.side === Order2.Order_Side.SIDE_BUY ? displaySizeWithFilled : null;
    this.displaySellSize = this.side === Order2.Order_Side.SIDE_SELL ? displaySizeWithFilled : null;

    if (this.displayStatus && this.displayStatus === "IN_CLIENT") {
      //:: this.userAlerts = userAlerts;
      setTimeout(() => {
        const orders = this.getAccount().getOrders();
        const inClientOrders = orders.filter((order) => order.displayStatus === "IN_CLIENT");
        if (inClientOrders.length > 0) {
          for (let i = 0; i < inClientOrders.length; i++) {
            if (inClientOrders[i].clientOrderId === this.clientOrderId) {
              const msg =
                "Your order could not be processed.<br/>" +
                "<strong>Reason:</strong><br/>" +
                '<span class="pre-wrap" style="white-space: pre-wrap;">The order was not processed because the market is closed or there is a network issue.</span>';
              //:: this.userAlerts.addAlert("Order IN_CLIENT", msg, cqgConstants.DialogType.ORDER_ALERT, {}, null);
            }
          }
        }
      }, 5000);
    }

    if (this.rejectMessage) {
      this.displayRejectMessage = this.rejectMessage;

      if (/Position limit for (.)+ is (\d)+, worst case position is (\d)+/.test(this.rejectMessage)) {
        this.displayRejectMessage = this.rejectMessage.substr(0, this.rejectMessage.indexOf(","));
      }

      if (this.rejectMessage.includes("Cannot process the order (error code 11)")) {
        this.displayRejectMessage = "Cannot route the order at this time";
      }

      this.displayRejectMessage = this.displayRejectMessage.replace(/F.US.([^\s])+/g, this.displayContract!);
    }
  }

  toWebApiOrder(): Order2.Order {
    const order = Order2.Order.create();
    const instrument = this.instrument;
    order.accountId = this.account.id;
    order.whenUtcTime = TimeUtil.toBaseTimeMsOffset(this.when!);
    order.contractId = instrument.contractId!;
    order.clOrderId = this.clientOrderId!;
    order.orderType = this.orderType!;
    order.duration = this.duration!;
    order.goodThruDate = this.goodThruDate ? TimeUtil.toBaseTimeMsOffset(this.goodThruDate) : order.goodThruDate;
    order.side = this.side!;

    const scale = instrument.correctPriceScale!;
    order.scaledLimitPrice = this.limitPrice != null ? Number((this.limitPrice / scale).toFixed()) : undefined;
    order.scaledStopPrice = this.stopPrice != null ? this.stopPrice / scale : undefined;
    order.uint32Qty = this.size!;
    order.isManual = this.isManual!;
    order.userAttributes = [];

    if (this.tradelog) {
      const userAttribute = UserAttribute.create();
      userAttribute.name = "tradelog_json";
      userAttribute.value = JSON.stringify(this.tradelog);
      userAttribute.deleted = false;

      order.userAttributes.push(userAttribute);
    }

    if (this.profitLossStrategy) {
      const userAttribute = UserAttribute.create();
      userAttribute.name = "profitLoss_json";
      userAttribute.value = JSON.stringify(this.profitLossStrategy);
      userAttribute.deleted = false;

      order.userAttributes.push(userAttribute);
    }

    if (this.strategyMainChainOrderId) {
      const userAttributes = UserAttribute.create();
      userAttributes.name = "strategyMainChainOrderId";
      userAttributes.value = this.strategyMainChainOrderId;
      userAttributes.deleted = false;

      order.userAttributes.push(userAttributes);
    }

    return order;
  }

  toWebApiCancelOrder(): Order2.CancelOrder {
    const cancelOrder = Order2.CancelOrder.create();
    cancelOrder.orderId = this.orderId!;
    cancelOrder.accountId = this.account.id;
    cancelOrder.origClOrderId = this.clientOrderId!;
    cancelOrder.clOrderId = generateClientOrderId();
    cancelOrder.whenUtcTime = TimeUtil.toBaseTimeMsOffset(this.when!);
    return cancelOrder;
  }

  toWebApiModifyOrder(): Order2.ModifyOrder {
    const instrument = this.instrument;
    const scale = instrument.correctPriceScale!;
    const modifyOrder = Order2.ModifyOrder.create();
    modifyOrder.orderId = this.orderId!;
    modifyOrder.accountId = this.account.id;
    modifyOrder.origClOrderId = this.clientOrderId!;
    modifyOrder.clOrderId = generateClientOrderId();
    modifyOrder.whenUtcTime = TimeUtil.toBaseTimeMsOffset(this.when!);
    modifyOrder.uint32Qty = this.size!;
    modifyOrder.scaledLimitPrice = this.limitPrice != null ? Number((this.limitPrice / scale).toFixed()) : undefined;
    modifyOrder.scaledStopPrice = this.stopPrice != null ? Number((this.stopPrice / scale).toFixed()) : undefined;
    modifyOrder.duration = this.duration!;
    modifyOrder.goodThruDate = this.goodThruDate ? TimeUtil.toBaseTimeMsOffset(this.goodThruDate) : undefined;
    return modifyOrder;
  }

  toWebApiModifyUserAttributes(): ModifyUserAttributes {
    const userAttribute = UserAttribute.create();
    userAttribute.name = "profitLoss_json";
    userAttribute.value = JSON.stringify(this.profitLossStrategy);
    userAttribute.deleted = false;

    const modifyUserAttributes = ModifyUserAttributes.create();
    modifyUserAttributes.chainOrderId = this.chainOrderId!;
    modifyUserAttributes.accountId = this.account.id;
    modifyUserAttributes.userAttributes.push(userAttribute);
    return modifyUserAttributes;
  }
}

function generateClientOrderId(): string {
  let id = "";
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      id += "-";
    }
    id += parseInt((Math.random() * 16) as any).toString(16);
  }
  return id;
}
