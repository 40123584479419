import { createTheme } from "@mui/material/styles";

// const FONT_FAMILY = "Saans";

export const themeValues = {
  color: {
    blue: {
      300: "#E7EBF6",
      500: "#2ee56b",
      700: "#0D1821",
    },
    blueGrey: {
      500: "#808699",
      600: "#606880",
      700: "#3A3E4D",
    },
    green: {
      100: "#dcfbe6",
      200: "#b9f6ce",
      300: "#97f2b5",
      400: "#74ee9c",
      500: "#51e984",
      600: "#2ee56b", // laser green
      700: "#26bf59",
      800: "#1f9947",
      900: "#177336",
      1000: "#0f4c24",
      1100: "#082612",
    },
    black: {
      100: "#eaeaea",
      200: "#d4d4d4",
      300: "#bfbfbf",
      400: "#aaaaaa",
      500: "#959595",
      600: "#808080",
      700: "#6a6a6a",
      800: "#555555",
      900: "#404040",
      1000: "#2b2b2b",
      1100: "#151515",
      1200: "#000000",
    },
    yellow: {
      100: "#fff9d4",
      200: "#fff3aa",
      300: "#ffed80",
      400: "#ffe855",
      500: "#ffe22b",
      600: "#ffdc00",
      700: "#d4b700",
      800: "#aa9300",
      900: "#806e00",
      1000: "#554900",
      1100: "#2b2500",
    },
    neutralsWhite: "#FFFFFF",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
};

const theme = createTheme({
  typography: {
    fontFamily: "Averta-Regular",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        // Name of the slot
        label: {
          // Some CSS
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "18px",
        },
        labelContainer: {
          color: themeValues.color.blueGrey[600],
          fontSize: "12px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "red",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // root: {
        //   color:'red', 
        //   background:'blue',
        // },
      },
      //     label.mui-focused {
      //       color: var(--black-40, rgba(255, 255, 255, 0.4));
      //     }
      //     > label {
      //       color: var(--black-40, rgba(255, 255, 255, 0.4));
      //     }
      //     .MuiInputBase-root {
      //       color: var(--black-40, rgba(255, 255, 255, 0.4));
      //     }
      //     .MuiOutlinedInput-notchedOutline {
      //       border-color: var(--black-40, rgba(255, 255, 255, 0.4));
      //     }
      //     .MuiOutlinedInput-root {
      //       &.Mui-focused fieldset {
      //         border-color: var(--black-40, rgba(255, 255, 255, 0.4));
      //       }
      //     }
    },
  },
});

export default theme;
