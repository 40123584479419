import React, { useCallback, useMemo, useState } from "react";
import Logo from "../../Logo";
import "./header.css";
import { removeCurrentUserFromLS } from "../../../../storage";
import { useDispatch } from "react-redux";
import authActions from "../../../../redux/auth/authActions";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";
import ProfileSection from "./ProfileSection";
import FundsInfo from "./FundsInfo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import mobile from "is-mobile";
import CloseIcon from "@mui/icons-material/Close";
import { MobileSearchIcon } from "../../../../images/Icons";

const Header = ({
  handleMobileMenu,
  isMobileProfileViewVisible,
}: {
  handleMobileMenu: () => void;
  isMobileProfileViewVisible: boolean;
}) => {
  const dispatch = useDispatch();
  const currentUser = useGetCurrentUser();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
  const firstName = currentUser?.firstName || "";
  const lastName = currentUser?.lastName || "";
  const userName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
  const profileInitial = useMemo(() => `${firstName.charAt(0).toUpperCase()}`, [firstName]);

  const [anchorShow, setAnchorShow] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorShow);

  const handleAccountClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorShow(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorShow(null);
  }, []);

  const handleLogoutClick = useCallback(() => {
    removeCurrentUserFromLS();
    dispatch(authActions.doSignout());
    handleClose();
  }, [dispatch, handleClose]);

  const handleAccountSettingsClick = useCallback(() => {
    console.log(`clicked`);
  }, []);

  const handleMobileMenuClick = useCallback(() => {
    handleMobileMenu();
  }, [handleMobileMenu]);

  return (
    <header>
      <div className="header-left">
        <Logo />
        {!isMobileView && <FundsInfo />}
      </div>
      <div className="header-controls">
        {!isMobileView && (
          <ProfileSection
            userName={userName}
            profileInitial={profileInitial}
            open={open}
            anchorShow={anchorShow}
            handleAccountClick={handleAccountClick}
            handleClose={handleClose}
            handleLogoutClick={handleLogoutClick}
            handleAccountSettingsClick={handleAccountSettingsClick}
          />
        )}
        {isMobileView && (
          <>
            <IconButton>
              <MobileSearchIcon className="header-icon-color" />
            </IconButton>
            <IconButton onClick={handleMobileMenuClick}>
              {isMobileProfileViewVisible ? <CloseIcon /> : <MenuIcon className="header-icon-color" />}
            </IconButton>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
