import { AccountSummary } from "../models/AccountSummary";
import { AccountsManager } from "./AccountsManager";
import * as WebAPI from "../proto/webapi_2";
import * as TradeRouting from "../proto/trade_routing_2";
import * as TradingAccount from "../proto/trading_account_2";
import EventEmitter from "events";
import { Account } from "../models/Account";
import { TradeSubscriptionParams } from "../models/TradeSubscriptionParams";
import { AccountSummariesSubscription } from "../models/AccountSummariesSubscription";
import * as _ from "../../vendors/underscore-esm";
import { ContractMetadata_MarginStyle } from "../proto/metadata_2";
import { Instrument } from "../models/Instrument";
import { Position } from "../models/Position";
import { PurchaseAndSalesGroup } from "../models/PurchaseAndSalesGroup";
import { MatchedTrade } from "../models/MatchedTrade";
import { TradeSubscriptionsManager } from "./TradeSubscriptionsManager";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";
import {TradeSubscription} from "../models/TradeSubscription";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { CQGEnvironment } from "./CQGEnvironment";

export class AccountSummariesManager {
  private subscription: AccountSummariesSubscription | null = null;
  private accountSummaries: { [key: string]: AccountSummary } = {};
  private accountSummaryLastUpdate: { [key: number]: number } = {}; //dictionary<account,lastUpdateTime>
  private totalCurrency = "TOTAL";
  private serviceUpdates: TradeRouting.CollateralStatus[] = [];

  private onCollateralStatusesEvent: EventEmitter = new EventEmitter();

  private accountsManager: AccountsManager;
  private tradeSubscriptionsManager: TradeSubscriptionsManager;
  private serviceMessageEventManager: CQGServiceMessageEventManager;

  constructor(
    accountsManager: AccountsManager,
    tradeSubscriptionsManager: TradeSubscriptionsManager,
    serviceMessageEventManager: CQGServiceMessageEventManager,
  ) {
    this.accountsManager = accountsManager;
    this.tradeSubscriptionsManager = tradeSubscriptionsManager;
    this.serviceMessageEventManager = serviceMessageEventManager;

    this.processCollateralStatusUpdate = this.processCollateralStatusUpdate.bind(this);
    this.serviceMessageEventManager?.onCollateralStatuses(this.processCollateralStatusUpdates);
    this.serviceMessageEventManager?.onInformationReports(this.processInformationReport);
    this.serviceMessageEventManager?.onServiceReady(() => {
      this.subscribe(CQGServiceMessageManager.nextRequestId() as number);
    });
  }

  public onCollateralStatusesDataReceived(listnerFn: (collateralStatus: TradeRouting.CollateralStatus[]) => void) {
    this.onCollateralStatusesEvent.on("collateralStatuses", listnerFn);
    this.onCollateralStatusesDataReceivedEventRegister();
  }

  public offCollateralStatusesDataReceived(listnerFn: (collateralStatus: TradeRouting.CollateralStatus[]) => void) {
    this.onCollateralStatusesEvent.off("collateralStatuses", listnerFn);
  }

  private onCollateralStatusesDataReceivedEventRegister = () => {
    this.onCollateralStatusesEvent.emit("collateralStatuses", this.serviceUpdates);
  };

  subscribeToCollaterals = (requestId: number, accountId: number) => {
    var account = this.accountsManager?.getAccount(accountId);
    if (!account || account.collateralDataRequested) {
      return;
    }

    let params = new TradeSubscriptionParams(requestId, accountId as number);
    params.subscriptionScope = [TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_COLLATERAL];
    params.publicationType = TradeRouting.TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS;

    this.tradeSubscriptionsManager?.subscribe(params);

    account.collateralDataRequested = true;
  };

  public subscribe = (requestId: number) => {
    this.subscription = new AccountSummariesSubscription(requestId);
    return this.subscription;
  };

  isSubscribed = () => {
    this.subscription = null;
    return this.subscription != null;
  };

  clear = () => {
    this.subscription = null;
    this.accountSummaries = {};
  };

  public processInformationReport = (informationReport: WebAPI.InformationReport[]) => {
    informationReport.forEach((item) => {
      this.processLastStatementBalancesReport(item);
    });
  };

  public processCollateralStatusUpdates = (updates: TradeRouting.CollateralStatus[]) => {
    // console.log("AccountSummariesManager: processCollateralStatusUpdates", updates);
    this.serviceUpdates = updates;

    updates.forEach((update) => {
      this.processCollateralStatusUpdate(update);
    });

    this.onCollateralStatusesEvent.emit("collateralStatuses", updates);
  };

  private processLastStatementBalancesReport = (informationReport: WebAPI.InformationReport) => {
    if (!this.subscription || this.subscription.id !== informationReport.id) {
      return;
    }
    
    this.subscription.statusCode = informationReport.statusCode;
    this.subscription.textMessage = informationReport.textMessage || null;

    if (
      this.subscription.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS ||
      this.subscription.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED ||
      this.subscription.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_UPDATE
    ) {
      var lastStatementBalancesReport = informationReport.lastStatementBalancesReport;
      lastStatementBalancesReport?.balances.forEach((item) => {
        this.processLastStatementBalance(item);
      });
    }
  };

  private processLastStatementBalance = (balance: TradingAccount.Balance) => {
    this.updateAccountSummary(balance, AccountSummaryUpdateKind.LAST_STATEMENT_BALANCE);
  };

  processCollateralStatusUpdate = (collateralStatus: TradeRouting.CollateralStatus) => {
    this.updateAccountSummary(collateralStatus, AccountSummaryUpdateKind.COLLATERAL_STATUS);
  };

  updateAccountSummary = (update: TradeRouting.CollateralStatus | TradingAccount.Balance, updateKind: number) => {
    var accountSummaryKey = this.getAccountSummaryKey(update.accountId, update.currency);
    var accountSummaryToUpdate = this.accountSummaries[accountSummaryKey];
    if (!accountSummaryToUpdate) {
      var account = this.accountsManager.getAccount(update.accountId);
      if (!account) {
        return;
      }
      accountSummaryToUpdate = this.createAccountSummary(accountSummaryKey, account, update.currency);
    }
    switch (updateKind) {
      case AccountSummaryUpdateKind.LAST_STATEMENT_BALANCE:
        accountSummaryToUpdate.updateFromLastStatementBalance(update as TradingAccount.Balance);
        break;
      case AccountSummaryUpdateKind.COLLATERAL_STATUS:
        accountSummaryToUpdate.updateFromCollateralStatus(update as TradeRouting.CollateralStatus);
        break;
      default:
        throw "Unknown account summary update kind: " + updateKind;
    }
  };

  updateAccountSummaryTotal = (account: Account) => {
    /// <summary>Calculates total account summary values.</summary>
    if (!account.accountSummaryTotal) {
      account.accountSummaryTotal = new AccountSummary(account, this.totalCurrency);
    }
    var total = account.accountSummaryTotal;
    total.yesterdayBalance = 0;
    total.yesterdayOte = 0;
    total.collateral = 0;
    total.cashExcess = 0;
    total.purchasingPower = 0;
    total.oteMvo = 0;
    total.profitLoss = 0;
    total.currentBalance = 0;
    total.oteMvoAndPl = 0;
    total.upl = 0;
    total.mvo = 0;
    total.nlv = 0;
    total.totalPl = 0;
    total.adjustedPurchasingPower = 0;

    _.forEach(account.getAccountSummaries(), (accountSummary: AccountSummary) => {
      var rate = accountSummary.currencyRate;
      total.yesterdayBalance += accountSummary.yesterdayBalance * rate;
      total.yesterdayOte += accountSummary.yesterdayOte * rate;
      total.collateral += accountSummary.collateral * rate;
      total.cashExcess += accountSummary.cashExcess * rate;
      total.purchasingPower += accountSummary.purchasingPower * rate;
      total.oteMvo += accountSummary.oteMvo * rate;
      total.profitLoss += accountSummary.profitLoss * rate;
      total.currentBalance += accountSummary.currentBalance * rate;
      total.oteMvoAndPl += accountSummary.oteMvoAndPl * rate;
      total.upl += accountSummary.upl * rate;
      total.mvo += accountSummary.mvo * rate;
      total.nlv += accountSummary.nlv * rate;
      total.adjustedPurchasingPower = accountSummary.adjustedPurchasingPower * rate;
    });
  };

  getAccountSummaryKey = (accountId: string | number, currency: string) => {
    return accountId + "-" + currency;
  };

  createAccountSummary = (accountSummaryKey: string, account: Account, currency: string) => {
    let accountSummary = new AccountSummary(account, currency);
    this.accountSummaries[accountSummaryKey] = accountSummary;
    account.getAccountSummaries().push(accountSummary);
    return accountSummary;
  };

  updateAccountSummaryThrottled = (account: Account) => {
    var utcNow = Date.now();
    var summaryLastUpdate = this.accountSummaryLastUpdate[account.id];
    var delta = utcNow - summaryLastUpdate;
    if (!summaryLastUpdate || delta > 1000 || delta < 0) {
      this.calcAccountSummary(account);
      this.accountSummaryLastUpdate[account.id] = Date.now();
    }
  };

  ensureAccountSummaryExist = (account: Account, currency: string) => {
    var accountSummaryKey = this.getAccountSummaryKey(account.id, currency);
    if (!this.accountSummaries[accountSummaryKey]) {
      this.createAccountSummary(accountSummaryKey, account, currency);
    }
  };

  calcAccountSummary = (account: Account) => {
    if (!account) {
      return;
    }
    _.forEach(this.accountSummaries, (accountSummary: AccountSummary) => {
      if (accountSummary.getAccount() != account) {
        return true;
      }

      let yesterdayBalance = accountSummary.yesterdayBalance;
      let yesterdayOte = accountSummary.yesterdayOte;
      let collateral = accountSummary.collateral;
      let currencyRate = 1; //:: currencyRatesManager.getCurrencyRate(account, accountSummary.currency);

      let oteTotal = 0;
      let mvoTotal = 0;
      let uplTotal = 0;
      let optionPremiumTotal = 0;

      _.forEach(account.getPositions(), (position: Position) => {
        if (!position || position.id < 0) {
          // do not process the app generated zero position. See PositionsManager for more details.
          return true;
        }

        const instrument = position.getInstrument()!;
        if (instrument.currency != accountSummary.currency) {
          return true;
        }

        var lastTradePrice = this.getLastTradePrice(instrument, position.isShort);
        if (!lastTradePrice) {
          position.updateFromAccountSummary(0, 0, 0); // TODO: It was set to null, null, null
          return true;
        }

        var netPosition = position.isShort ? -position.size : position.size;
        var priceMultiplier = instrument.tickValue / instrument.rawTickSize;
        var amountOfMoneySpend = netPosition * position.price * priceMultiplier;
        var mvo = netPosition * lastTradePrice * priceMultiplier;
        var ote = mvo - amountOfMoneySpend;
        var upl = ote;

        oteTotal += ote;

        if (
          instrument.marginStyle &&
          instrument.marginStyle == ContractMetadata_MarginStyle.MARGIN_STYLE_PREMIUM &&
          !position.tradeUtcTime
        ) {
          var sign = position.isShort ? -1 : 1;
          optionPremiumTotal += amountOfMoneySpend * sign;
        }

        position.updateFromAccountSummary(ote, 0, 0);
      });

      let profitLossTotal = 0;
      _.forEach(account.getPurchaseAndSalesGroups(), (group: PurchaseAndSalesGroup) => {
        let instrument = group.getInstrument()!;
        if (instrument.currency != accountSummary.currency) {
          return true;
        }

        var profitLoss = group.realizedProfitLoss;
        profitLossTotal += profitLoss;

        if (instrument.marginStyle == ContractMetadata_MarginStyle.MARGIN_STYLE_PREMIUM) {
          var amountOfMoneySpend = 0;
          var priceMultiplier = instrument.tickValue / instrument.tickSize;
          _.forEach(group.getMatchedTrades(), (matchedTrade: MatchedTrade, index: number) => {
            if (!matchedTrade.tradeUtcTime) {
              var signedTradeSize = matchedTrade.isShort ? -matchedTrade.size : matchedTrade.size;
              var aoms = signedTradeSize * matchedTrade.price * priceMultiplier;
              amountOfMoneySpend += aoms;
            }
          });
          optionPremiumTotal += amountOfMoneySpend;
        }
      });

      accountSummary.updateValue( 'oteMvo', oteTotal + mvoTotal );
      accountSummary.updateValue( 'profitLoss', profitLossTotal );
      accountSummary.updateValue( 'oteMvoAndPl', (oteTotal + mvoTotal + profitLossTotal) );
      accountSummary.updateValue( 'currentBalance', yesterdayBalance + accountSummary.oteMvoAndPl + optionPremiumTotal );
      accountSummary.nlv = accountSummary.currentBalance + oteTotal + mvoTotal + collateral;
      accountSummary.mvo = mvoTotal;
      accountSummary.upl = uplTotal;
      accountSummary.currencyRate = currencyRate;
      accountSummary.updateValue( 'adjustedPurchasingPower', accountSummary.purchasingPower + accountSummary.oteMvo );

      // check it later
      accountSummary.adjustedPurchasingPower = accountSummary.purchasingPower + accountSummary.oteMvo;
      accountSummary.lastUpdateTimestamp = new Date();
    });
    this.updateAccountSummaryTotal(account);
  };

  getLastTradePrice = (instrument: Instrument, isShortPosition: boolean) => {
    //TODO: consider configurable way to use Trade/settlement or Bid/Ask
    if (instrument.lastPrice) {
      return instrument.lastPrice;
    }
    if (isShortPosition) {
      if (instrument.bestAsk) {
        return instrument.bestAsk;
      }
    } else {
      if (instrument.bestBid) {
        return instrument.bestBid;
      }
    }
    if (instrument.yesterdaySettlement) {
      return instrument.yesterdaySettlement;
    }
    return null;
  };
}

var AccountSummaryUpdateKind = {
  LAST_STATEMENT_BALANCE: 0,
  COLLATERAL_STATUS: 1,
};
