import { useEffect } from "react";
import { TradeTicketTab } from "../../../types";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { IFormInput } from "../types";

const useHandleFutureOrOptionTabChange = (
  selectedInstrument: any,
  selectedContract: Instrument | null,
  limitPrice: number,
  activeTab: string,
  setValue: UseFormSetValue<IFormInput>,
  getDefaultLimitPrice: () => number,
  getDefaultStopPrice: () => number,
  putCallValue: "P" | "C" | undefined,
  selectedStrike: number | null,
  contractValue: string,
  reset: UseFormReset<IFormInput>,
) => {
  useEffect(() => {
    reset({
      side: undefined,
      contract: contractValue,
    });
  }, [activeTab, contractValue, reset]);
  useEffect(() => {
    if (selectedInstrument && limitPrice === 0 && activeTab === TradeTicketTab.Futures) {
      setValue(
        "limitPrice",
        parseFloat(DisplayUtil.toDisplayPrice(getDefaultLimitPrice(), selectedInstrument)?.toString() || "") || 0,
      );
      setValue(
        "stopPrice",
        parseFloat(DisplayUtil.toDisplayPrice(getDefaultStopPrice(), selectedInstrument)?.toString() || "") || 0,
      );
      setValue("rawLimitPrice", getDefaultLimitPrice());
      setValue("rawStopPrice", getDefaultStopPrice());
    }
  }, [activeTab, getDefaultLimitPrice, getDefaultStopPrice, limitPrice, selectedInstrument, setValue]);

  useEffect(() => {
    if (putCallValue) {
      setValue("limitPrice", 0);
      setValue("stopPrice", 0);
      setValue("rawLimitPrice", 0);
      setValue("rawStopPrice", 0);
    }
  }, [putCallValue, setValue, selectedStrike, contractValue, activeTab]);

  useEffect(() => {
    if (selectedStrike && selectedContract && limitPrice === 0 && activeTab === TradeTicketTab.Options) {
      setValue(
        "limitPrice",
        parseFloat(DisplayUtil.toDisplayPrice(Number(getDefaultLimitPrice()), selectedContract)?.toString() || "") || 0,
      );
      setValue(
        "stopPrice",
        parseFloat(DisplayUtil.toDisplayPrice(Number(getDefaultStopPrice()), selectedContract)?.toString() || "") || 0,
      );
      setValue("rawLimitPrice", getDefaultLimitPrice());
      setValue("rawStopPrice", getDefaultStopPrice() || 0);
    }
  }, [
    activeTab,
    limitPrice,
    selectedStrike,
    selectedContract,
    setValue,
    contractValue,
    getDefaultLimitPrice,
    getDefaultStopPrice,
  ]);
  return;
};

export default useHandleFutureOrOptionTabChange;
