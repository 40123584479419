import { useCallback, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { IFormInput } from "../types";

const useHandleStrikeAndOptionChange = (
  putCallValue: "P" | "C" | undefined,
  setSelectedContract: any,
  setValue: UseFormSetValue<IFormInput>,
  selectedInstrument: any,
) => {
  const [selectedStrike, setSelectedStrike] = useState<number | null>(null);
  const handleStrikeChange = useCallback(
    (strikeDetails: { strike: number | null; callOption: any; putOption: any }) => {
      const { callOption, putOption, strike } = strikeDetails;

      if (putCallValue === "P" && putOption) {
        setSelectedStrike(strike);
        setSelectedContract(putOption);
      } else if (putCallValue === "C" && callOption) {
        setSelectedStrike(strike);
        setSelectedContract(callOption);
      } else {
        setSelectedStrike(strike);
      }
    },
    [putCallValue, setSelectedContract],
  );
  const handleOptionChange = useCallback(
    (option: "P" | "C") => {
      setValue("putCall", option);
      const relevantOption = option === "P" ? selectedInstrument?.putOptions : selectedInstrument?.callOptions;
      handleStrikeChange({
        strike: selectedStrike,
        putOption: option === "P" ? relevantOption : null,
        callOption: option === "C" ? relevantOption : null,
      });
    },
    [handleStrikeChange, selectedInstrument?.callOptions, selectedInstrument?.putOptions, selectedStrike, setValue],
  );
  return { selectedStrike, setSelectedStrike, handleStrikeChange, handleOptionChange };
};

export default useHandleStrikeAndOptionChange;
