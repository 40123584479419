export const privateRoutes = [
  
  // {
  //   path: "/widgets/chart",
  //   loader: () => import ("../components/mobileView/Chart/Chart"),
  // },
  {
    path: "/",
    loader: () => import("../components/home/Home"),
    permissionRequired: null,
    exact: true,
  },
];

export const publicRoutes = [
  {
    path: "/auth/signin",
    loader: () => import("../components/auth/SigninPage"),
  },
  {
    path: "/widgets/position",
    loader: () => import ("../components/PositionsWidget"),
  },
  {
    path: "/web",
    loader: () => import ("../components/Web"),
  },
  {
    path: "/widgets/chart/:symbol",
    loader: () => import ("../components/mobileView/Chart/Chart"),
  },
];

