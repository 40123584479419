import { BootstrapTooltip } from "../../../shared/BootstrapTooltip";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { menuIconStyles } from "../PriceLadderStyles";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { PriceLadderIcon } from "../../../../images/Icons";
import { useMemo } from "react";

interface OrderContentProps {
  orderClass: string;
  cancelStyles: {
    height: string;
    width: string;
    cursor: string;
    color: string;
  };
  placedOrders: OrderState[];
  onCancelClick: (orders: OrderState[]) => void;
  isDragging: boolean;
}

const OrderChip = ({ orderClass, cancelStyles, placedOrders, onCancelClick, isDragging }: OrderContentProps) => {
  const totalOrdersQty = useMemo(
    () => placedOrders.reduce((sum, order: OrderState) => sum + (order.size || 0), 0),
    [placedOrders],
  );

  return (
    <span className={orderClass}>
      {isDragging ? (
        <PriceLadderIcon style={menuIconStyles} className="price-ladder-pointer" />
      ) : (
        <BootstrapTooltip title="Drag to adjust" placement="top">
          <span>
            <PriceLadderIcon style={menuIconStyles} className="price-ladder-pointer" />
          </span>
        </BootstrapTooltip>
      )}

      <span>{totalOrdersQty}</span>
      <BootstrapTooltip title="Cancel order" placement="top">
        <CancelRoundedIcon style={cancelStyles} onClick={() => onCancelClick(placedOrders)} />
      </BootstrapTooltip>
    </span>
  );
};

export default OrderChip;
