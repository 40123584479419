import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import clsx from "clsx";
import { Mode_Type } from "../../../../types";

const modeStyles = { fontFamily: "Averta-Regular", fontWeight: "600", letterSpacing: "1.5px" };

interface ModeProps {
  mode: Mode_Type;
  onModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onModeReset: () => void;
}

const Mode = ({ mode, onModeChange, onModeReset }: ModeProps) => {
  return (
    <FormControl sx={{ padding: "5px 10px", display: "flex" }}>
      <FormLabel id="demo-radio-buttons-group-label" sx={{ marginTop: "10px" }}>
        Mode
      </FormLabel>
      <RadioGroup name="mode" value={mode} onChange={onModeChange}>
        <div className="mobile-mode">
          <FormControlLabel
            sx={modeStyles}
            value={Mode_Type.PRACTICE_MODE}
            control={<Radio />}
            label={Mode_Type.PRACTICE_MODE}
          />
          <Button
            className={clsx({
              "mobile-add-icon": true,
            })}
            sx={modeStyles}
            onClick={() => onModeReset()}
          >
            RESET
          </Button>
        </div>
        <div className="mobile-mode">
          <FormControlLabel
            sx={modeStyles}
            value={Mode_Type.CHALLENGE_MODE}
            control={<Radio />}
            label={Mode_Type.CHALLENGE_MODE}
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default Mode;
