import { Close } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import clsx from "clsx";

const Header = ({ onHandleClose, isMobileView }: { onHandleClose: () => void; isMobileView: boolean }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ justifyContent: "space-between" }}
      className={clsx({
        "trade-plan-header-mobile": isMobileView,
      })}
    >
      <Typography variant="h6" fontSize={16} fontWeight={600} lineHeight={"28px"} fontFamily={"Averta-Regular"}>
        My Trade Plan (Practice Account)
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        {!isMobileView && (
          <Button
            type="submit"
            variant="contained"
            sx={{ height: "24px", width: "55px", fontSize: "12px", background: "#006EB6" }}
          >
            Save
          </Button>
        )}
        <IconButton onClick={onHandleClose} sx={{ marginTop: "-8px" }}>
          <Close />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Header;
