import { Widget, WidgetType } from "../../../types";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { useFetchWorkspaceWatchlist, useUpdateWatchlist } from "../../../hooks/api/watchlist";
import { Settings } from "@mui/icons-material";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { MapTreeData } from "../../../utils/utils";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { subscribeToContracts, unsubscribeInstuments } from "../../../utils/subscriptions";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { useDispatch, useSelector } from "react-redux";
import watchlistSelectors from "../../../redux/watchlist/watchlistSelector";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import WatchlistDataGrid from "./WatchlistDataGrid";
import LoadingComponent from "../../shared/LoadingComponent";
import "./Watchlist.css";
import FilterProducts from "../availableProducts/FilterProducts";
import { Button, IconButton } from "@mui/material";
import ChartIcon from "../availableProducts/ChartIcon";
import watchlistActions from "../../../redux/watchlist/watchlistActions";
import SelectContract from "../availableProducts/SelectContract";
import { Contract } from "../availableProducts/types";
import { v4 as uuidv4 } from "uuid";
import RenderWatchlistCell from "./RenderWatchlistCell";
import { DeleteIcon } from "../../../images/Icons";
import RenderColorCell from "../availableProducts/RenderColorCell";
import PriceLadderIcon from "../availableProducts/PriceLadderIcon";
// import TradeTicketDrawer from "../../TradeTicketDrawer";
import { setChartWidgetValue, setSelectedInstrument, setTradeTicketValue } from "../../../redux/products/chartWidget";
import { TimeUtil } from "../../../cqg-api/utils/TimeUtil";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { AvailableProductsContext } from "../../../App";
import TradeButton from "../../shared/TradeButton";

const editWatchlistButtonStyles = {
  height: "27.25px",
  marginTop: "8px",
  color: "#006EB6",
  borderColor: "#006EB6",
  fontWeight: "700",
  borderRadius: "4px",
  fontFamily: "Averta-regular",
  fontSize: "12px",
};

interface RowState {
  [key: string]: Contract;
}

const Watchlist = (props: {
  workspaceId: number;
  workspaceClassName?: string;
  widget: Widget;
  isPopupWindow?: boolean;
}) => {
  const dispatch = useDispatch();
  const { widget } = props;

  const { loadProducts, loaded } = useFetchProducts();
  const { updateWatchlistAsync } = useUpdateWatchlist();
  const { watchlist, loadWorkspaceWatchlist } = useFetchWorkspaceWatchlist();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<any>();
  // const [openDrawer, setOpenDrawer] = useState(false);
  // const [selectedContract, setSelectedContract] = useState<any>();
  const { realTimeMarketData } = useRealTimeMarketData();

  const { monthlyContractsByDisplayNameMap, availableloading } = useContext(AvailableProductsContext);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const panelRef = useRef(null);
  const open = Boolean(anchorEl);

  const [panelSize, setPanelSize] = useState({ width: 0 });
  const isSmallPanelFilter = panelSize.width < 600;

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    if (!widget.widgetId) {
      console.error("Watchalist id not found.");
      return;
    }
    loadWorkspaceWatchlist(widget.widgetId);
  }, [loadWorkspaceWatchlist, widget.widgetId]);

  const watchlistProducts = useMemo(() => {
    if (!watchlist?.associatedProducts) return [];
    const associatedProductsList = watchlist.associatedProducts.split(",").map((id) => id.trim());
    const instrumentWithIds =
      Object.keys(monthlyContractsByDisplayNameMap)?.length > 0
        ? associatedProductsList.filter(associatedProduct => monthlyContractsByDisplayNameMap[associatedProduct])
          .map((associatedProduct, index) => ({
            ...monthlyContractsByDisplayNameMap[associatedProduct],
            id: uuidv4(),
          }))
        : [];
    return instrumentWithIds;
  }, [watchlist?.associatedProducts, monthlyContractsByDisplayNameMap]);

  useEffect(() => {
    const contractIds = watchlistProducts?.map((contract) => contract.contractId).filter(Boolean);
    subscribeToContracts(contractIds as number[]);
    return () => {
      unsubscribeInstuments(watchlistProducts);
    };
  }, [watchlistProducts]);

  const [watchlistData, setWatchlistData] = useState<Instrument[]>([]);

  useEffect(() => {
    setWatchlistData(watchlistProducts);
  }, [watchlistProducts]);

  useEffect(() => {
    if (watchlistData.length) {
      setWatchlistData(MapTreeData(watchlistData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const [rowState, setRowState] = useState<RowState>({});

  const handleRowSelectedContractChange = useCallback(
    (id: string, contract: Contract) => {
      // Update row state
      setRowState((prevState) => ({
        ...prevState,
        [id]: contract,
      }));

      // Map updated contracts based on the selected contract's display name
      const updatedContracts = watchlistData.map((row: any) =>
        row.id === id
          ? {
              ...monthlyContractsByDisplayNameMap[contract.displayName],
              id: uuidv4(),
            }
          : row,
      );

      // Update the watchlist data state
      setWatchlistData(updatedContracts);

      // Update watchlist asynchronously if watchlist exists
      if (watchlist?.id) {
        const displayNames = updatedContracts.map((c) => c.displayName).join(",");
        updateWatchlistAsync(watchlist.id, displayNames);
      }

      // Subscribe to instrument based on contractId
      subscribeToContracts([contract.contractId]);
    },
    [monthlyContractsByDisplayNameMap, updateWatchlistAsync, watchlist, watchlistData],
  );

  const openChartForInstrument = useCallback(
    ({ row }: GridRenderCellParams) => {
      const selectedInstrument = row.monthlyContracts.find(
        (contract: { displayName: string }) => contract.displayName === row.displayName,
      );
      if (!selectedInstrument) {
        console.log("Instrument not found");
        return;
      }
      const chartWidgetValue: Widget = {
        key: 3,
        title: WidgetType.Chart,
        widgetType: `${selectedInstrument.displayName} Chart`,
        contractIds: [selectedInstrument.displayName],
      };
      dispatch(setChartWidgetValue(chartWidgetValue));
    },
    [dispatch],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "cmeSymbolName",
        headerName: "Name",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
      },
      {
        field: "monthlyContracts",
        headerName: "Contract",
        flex: 1,
        minWidth: 150,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const rowId = params.row.id;
          const selectedContract = rowState[rowId as string] || params.row;
          const isExpired = TimeUtil.isContractExpired(params.row.last_trading_date);

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <SelectContract
                selectedState={selectedContract}
                id={rowId}
                contracts={params.value}
                onSelect={(id: string, contract: Contract) => handleRowSelectedContractChange(id, contract)}
              />
              {isExpired && <span style={{ marginLeft: "8px", color: "#C62828", fontSize: "12px" }}>EXPIRED</span>}
            </div>
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(params?.row.lastPrice, params.row)} />;
        },
      },
      {
        field: "labelPriceNetChange",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelOpen",
        headerName: "Open",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelHigh",
        headerName: "High",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelLow",
        headerName: "Low",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelTotalVolume",
        headerName: "Volume",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
      },
      {
        field: "selected",
        headerName: "Actions",
        headerClassName: "market-table--header",
        flex: 1,
        minWidth: 140,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const isExpired = TimeUtil.isContractExpired(params.row.last_trading_date);

          if (isExpired) {
            return (
              <IconButton
                onClick={() => {
                  setOpenConfirmDialog(true);
                  setDeleteRowId(params?.row?.displayName);
                }}
                style={{
                  gap: 4,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                disableRipple
              >
                <DeleteIcon sx={{ fontSize: 15 }} />
                <span
                  style={{
                    fontFamily: "Averta-Regular",
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "#006EB6",
                  }}
                >
                  Remove
                </span>
              </IconButton>
            );
          }
          return (
            <span className="starIcon">
              <TradeButton
                enabled={true}
                handleClick={() => {
                  // setSelectedContract(params.row.monthlyContracts);
                  const chartWidgetValue: Widget = {
                    key: 3,
                    title: WidgetType.TradeTicket,
                    widgetType: "Sarab Chart",
                  };
                  dispatch(
                    workspacesActions.dispatchSetWorkspace({ workspaceId: props.workspaceId, instrument: params.row }),
                  );
                  dispatch(setTradeTicketValue(chartWidgetValue));
                  dispatch(
                    setSelectedInstrument({
                      instrument: params.row.monthlyContracts,
                      selected: params.row ?? params.row.monthlyContracts[0],
                    }),
                  );
                  // setOpenDrawer(true);
                }}
              >
              </TradeButton>
              <IconButton onClick={() => openChartForInstrument(params)}>
                <ChartIcon />
              </IconButton>
              <IconButton>
                <PriceLadderIcon />
              </IconButton>
            </span>
          );
        },
      },
    ],
    [dispatch, handleRowSelectedContractChange, openChartForInstrument, props.workspaceId, rowState],
  );

  const handleCloseDialog = () => {
    setDeleteRowId("");
    setOpenConfirmDialog(false);
  };

  const onDeleteProduct = async (e?: any, rowId?: number) => {
    const rowIdToDelete = rowId ?? deleteRowId;

    // Filter out the product to delete and map remaining display names to a string
    const updatedAssociatedProducts = watchlistData
      .filter(({ displayName }) => displayName !== rowIdToDelete.toString())
      .map(({ displayName }) => displayName)
      .join(",");

    if (watchlist) {
      setLoading(true);
      try {
        await updateWatchlistAsync(watchlist.id, updatedAssociatedProducts);
        await loadWorkspaceWatchlist(watchlist.id);
      } finally {
        setLoading(false);
        handleCloseDialog();
      }
    }
  };

  const updateLoading = useSelector(watchlistSelectors.getUpdateLoading);
  const watchlistWidget = useSelector(watchlistSelectors.getWidget);

  useEffect(() => {
    const updateList = async () => {
      if (updateLoading && widget.widgetId) {
        setLoading(true);
        await loadWorkspaceWatchlist(widget.widgetId);
        dispatch(watchlistActions.dispatchUpdateWatchlistLoading(false));
        setLoading(false);
      }
    };
    updateList();
  }, [dispatch, loadWorkspaceWatchlist, updateLoading, watchlistWidget?.widgetId, widget?.widgetId]);

  const onRowDrop = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (!watchlist) return;

      if (fromIndex < 0 || fromIndex >= watchlistData.length || toIndex < 0 || toIndex >= watchlistData.length) {
        console.error("Invalid indices provided.");
        return;
      }

      setWatchlistData((prev: any) => {
        const updatedRows = [...prev];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        const watchlistNewOrder = updatedRows.map((row) => row.displayName).join(",");
        updateWatchlistAsync(watchlist.id, watchlistNewOrder);
        return updatedRows;
      });
    },
    [updateWatchlistAsync, watchlist, watchlistData.length],
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setPanelSize({ width: entry.contentRect.width });
      }
    });
    const currentPanel = panelRef.current;
    if (currentPanel) {
      observer.observe(currentPanel);
    }

    return () => {
      if (currentPanel) {
        observer.unobserve(currentPanel);
      }
    };
  }, []);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditWatchlistClick = () => {
    const selectedContractIds = Array.from(new Set(watchlistData.map((item) => item.displayName)));
    dispatch(
      watchlistActions.dispatchUpdateWatchlist({
        widget,
        selectedTreeItems: selectedContractIds,
      }),
    );
    dispatch(watchlistActions.dispatchEditWatchlist(true));
  };

  return (
    <>
      <div ref={panelRef} className="widget-parent-container">
        {(loading || availableloading) && <LoadingComponent withOverlay />}
        <div className="draggableCancelSelector widget-grid-container watchlist-container">
          <div className="watchlist-header">
            <div>
              <Button
                disabled={!loaded}
                onClick={() => handleEditWatchlistClick()}
                sx={editWatchlistButtonStyles}
                variant="text"
                startIcon={<Settings style={{ height: "14px", width: "14px" }} />}
              >
                Edit
              </Button>
            </div>
            <div className="watchlist-header--actions">
              <FilterProducts
                selectedAssetType={selectedFilter.toString()}
                handleFilterIconClick={handleFilterIconClick}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                setSelectedFilter={setSelectedFilter}
                isSmallPanelFilter={isSmallPanelFilter}
              />
            </div>
          </div>
          <div className="watchlist-grid-panel">
            <DndProvider context={window} backend={HTML5Backend}>
              <WatchlistDataGrid
                watchlistRows={watchlistData}
                columns={columns}
                onRowDrop={onRowDrop}
                watchlistId={watchlist?.id}
              />
            </DndProvider>
          </div>
        </div>
      </div>
      {/* {true && <TradeTicketDrawer open={openDrawer} setOpen={setOpenDrawer} contracts={selectedContract} />}*/}
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseDialog}
        onDelete={onDeleteProduct}
        loading={loading}
        confirmText={"Are you sure you want to remove"}
      />
    </>
  );
};

export default Watchlist;
