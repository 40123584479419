// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digital-time-container {
  display: flex;
  justify-content: center;
}

.digital-time {
  font-family: "Averta-Regular";
  position: absolute;
  bottom: 15px;
  font-size: 14px;
  color: #5a6874;
}

@media screen and (orientation: landscape) {
  .digital-time {
    position: relative;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/mobile/profile/DigitalTime/DigitalTime.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".digital-time-container {\n  display: flex;\n  justify-content: center;\n}\n\n.digital-time {\n  font-family: \"Averta-Regular\";\n  position: absolute;\n  bottom: 15px;\n  font-size: 14px;\n  color: #5a6874;\n}\n\n@media screen and (orientation: landscape) {\n  .digital-time {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
