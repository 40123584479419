import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { orderFiltersMobile } from "../widgets/orders/types";

export const selectStyle = {
  minWidth: "fit-content",
  width: '50%',
  fontSize: '14px',
  padding: '5px',
  fontFamily: 'Averta-Regular',
  '& .MuiSelect-select': {
    padding: '5px',
    fontSize: '12px',
    background: 'white',
  },
};
export type FilterOrdersProps = {
  value: number;
  selectFilter: (event: number) => void;
};
const FilterOrders = ({ value, selectFilter }: FilterOrdersProps) => {
  const handleFilterSelection = (event: SelectChangeEvent<number>) => {
    selectFilter(event.target.value as number);
  };
  return (
    <Select
      sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleFilterSelection}
    >
      {orderFiltersMobile.map((value) => (
        <MenuItem key={value.label} value={value.orderStatusId} sx={{ fontSize: "14px", fontFamily: "Averta-Regular" }}>
          {value.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterOrders;
