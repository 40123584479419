import React from "react";
import { Tooltip } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tooltip: {
    width: '218px',                     
    padding: '4px 8px',                 
    gap: 0,                             
    borderRadius: '4px',
    opacity: 0,                         
    backgroundColor: '#607D8B',         
    fontFamily: 'Averta-Regular',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  infoIcon: {
    width: '12px',
    height: '12px',
    color: '#081D374D',
    marginBottom: '4px',
    marginLeft: '4px',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#607D8B',
    },
  },
});

const TooltipWithIcon = ({ tooltipText }: { tooltipText: string }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltipText}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <InfoRounded
        fontSize="small"
        className={classes.infoIcon}
      />
    </Tooltip>
  );
};

export default TooltipWithIcon;
