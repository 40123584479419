import { LayoutProps } from '../../home/components/Layout/types';

const boxStyle = {
  width: '100%',
  height: '100vh',
};

const Layout = ({ children }: LayoutProps) => {
  return(
    <div style={boxStyle}>
      {children}
    </div>
);}

export default Layout;
