import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Control, FieldErrors, UseFormGetValues, UseFormWatch } from "react-hook-form";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import { DialogContent, Grid } from "@mui/material";
import SideController from "../../../TradeTicketDrawer/components/SideController";
import { gridItemStyles } from "../../../TradeTicketDrawer/styles";
import TakeProfitandLoss from "./TakeProfitAndLoss/TakeProfitandLoss";
import { useEffect, useState } from "react";
import { IFormInput } from "../../../TradeTicketDrawer/types";

interface OrderSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  control: Control<IFormInput, any>;
  selectedContract: Instrument;
  watch: UseFormWatch<IFormInput>;
  selectedInstrument: Instrument;
  setValue: any;
  errors: FieldErrors<IFormInput>;
  getValues: UseFormGetValues<IFormInput>;
  onSaveOcoSettings: any;
}

const OrderSettingsDialog = ({
  open,
  onClose,
  control,
  selectedContract,
  watch,
  selectedInstrument,
  setValue,
  errors,
  getValues,
  onSaveOcoSettings,
}: OrderSettingsDialogProps) => {
  const [instrument, setInstrument] = useState<Instrument>(selectedInstrument);

  useEffect(() => {
    setInstrument(selectedInstrument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveOcoSettingsClick = () => {
    onSaveOcoSettings(getValues()?.oco);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "444px",
          padding: "14px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Grid item xs={6} sx={gridItemStyles} style={{ marginBottom: "16px" }}>
          <SideController control={control} side={errors.side} setValue={setValue} />
        </Grid>
        <Grid container spacing={2}>
          <TakeProfitandLoss control={control} setValue={setValue} watch={watch} selectedContract={instrument} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            color: "rgba(8, 29, 55, 0.87)",
            fontFamily: "Averta-Regular",
            letterSpacing: "1.5px",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#006EB6",
            fontFamily: "Averta-Regular",
            letterSpacing: "1.5px",
          }}
          onClick={() => onSaveOcoSettingsClick()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderSettingsDialog;
