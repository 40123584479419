import { Control, Controller } from "react-hook-form";
import { StyledFormControl, StyledSelect } from "../styles";
import { MenuItem } from "@mui/material";
import { IFormInput } from "../types";

interface ContractSelectProps {
  control: Control<IFormInput, any>;
  contracts: any;
  canChangeInstrument: boolean;
  widgetData?: { data?: { displayContract?: string } };
}

const ContractSelect = ({
  control,
  contracts,
  canChangeInstrument,
  widgetData,
}: ContractSelectProps) => {
  const isContractAvailable = widgetData?.data?.displayContract;

  const isDisabled = !canChangeInstrument || !!isContractAvailable;

  return (
    <StyledFormControl fullWidth>
      <Controller
        name="contract"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            disabled={isDisabled}
            value={isContractAvailable ? widgetData?.data?.displayContract : field.value}
          >
            {isContractAvailable ? (
              <MenuItem value={widgetData?.data?.displayContract} disabled>
                {widgetData?.data?.displayContract}
              </MenuItem>
            ) : (
              contracts?.map((contract: any) => (
                <MenuItem key={contract.displayName} value={contract.displayName}>
                  {contract.displayName}
                </MenuItem>
              ))
            )}
          </StyledSelect>
        )}
      />
    </StyledFormControl>
  );
};

export default ContractSelect;
