import "./widgets.css";
import MenuItem from "../../../../common/userinfo/Menutem";
import { widgets } from "../../../../workspace/mockData";
import { Widget, WidgetType } from "../../../../../types";

const allowedWidgetTypes = [
  WidgetType.Watchlist,
  WidgetType.Chart,
  WidgetType.PriceLadder,
];

const WidgetList = ({ onAddWidgetClick, drawerOpen, handleDrawer }: WidgetsNavProps) => {
  const handleonAddWidgetClick = (widget: Widget) => {
    const isDisabled = drawerOpen && !allowedWidgetTypes.includes(widget.widgetType as WidgetType);

    if (isDisabled) {
      handleDrawer();
    }

    if (drawerOpen && allowedWidgetTypes.includes(widget.widgetType as WidgetType)) {
      onAddWidgetClick(widget);
    } else if (!drawerOpen || !isDisabled) {
      onAddWidgetClick(widget);
    }
  };

  return (
    <ul>
      {widgets.map((item, index) => {
        const isDisabled =
          drawerOpen && !allowedWidgetTypes.includes(item.widgetType as WidgetType);

        return (
          <li key={index} style={{ cursor: isDisabled ? "" : "pointer"}} onClick={() => handleonAddWidgetClick(item)}>
            <MenuItem text={item.title} icon={item.icon} />
          </li>
        );
      })}
    </ul>
  );
};

type WidgetsNavProps = { onAddWidgetClick: (widget: Widget) => void, drawerOpen: boolean, handleDrawer: () => void; };
const WidgetsNav = ({ onAddWidgetClick, drawerOpen, handleDrawer }: WidgetsNavProps) => (
  <div className="productsNav">
    <h3 className="heading">ADD WIDGETS</h3>
    <WidgetList drawerOpen={drawerOpen} onAddWidgetClick={onAddWidgetClick} handleDrawer={handleDrawer} />
  </div>
);

export default WidgetsNav;
