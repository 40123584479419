import { Grid, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import MinusIcon from "../../../../TradeTicketDrawer/MinusIcon";
import PlusIcon from "../../../../TradeTicketDrawer/PlusIcon";
import { onlyNumbersRules } from "../../utils";
import { inputStyles } from "../../../../TradeTicketDrawer/styles";
import { OrderSide } from "../../../../../types";
import { IFormInput } from "../../../../TradeTicketDrawer/types";

interface ProfitLossProps {
  control: Control<IFormInput, any>;
  setValue: (field: string, value: number) => void;
  name: any;
  orderSide: OrderSide;
  onProfitLossChange: (pnl: number) => void;
}

const ProfitLoss = ({ control, setValue, name, orderSide, onProfitLossChange }: ProfitLossProps) => {
  const handleChange = (field: any, increment: number) => {
    const updatedValue = Number((field.value + increment).toFixed(2));
    setValue(field.name, updatedValue);
    onProfitLossChange(updatedValue);
  };

  return (
    <Grid item xs={6} sx={{ padding: 0 }}>
      <label className="label">{name === `oco.${orderSide}.takeProfit.profit` ? "Profit $" : "Loss $"}</label>
      <div className="control">
        <Controller
          name={name}
          control={control}
          rules={onlyNumbersRules}
          render={({ field }) => (
            <>
              <button type="button" className="minusButton" onClick={() => handleChange(field, -0.01)}>
                <MinusIcon />
              </button>

              <TextField
                {...field}
                type="number"
                fullWidth
                value={field.value}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  field.onChange(newValue);
                  onProfitLossChange(newValue);
                }}
                variant="outlined"
                sx={inputStyles}
              />

              <button type="button" className="minusButton" onClick={() => handleChange(field, 0.01)}>
                <PlusIcon />
              </button>
            </>
          )}
        />
      </div>
    </Grid>
  );
};

export default ProfitLoss;
