import { DockviewApi, IDockviewPanelProps } from "dockview";
import { DockerviewPanelProps, Widget, WidgetType, Workspace } from "../../types";
import AvailableProducts from "../widgets/availableProducts/AvailableProducts";
import Orders from "../widgets/orders/Orders";
import Positions from "../widgets/positions/Positions";
import OrdersMobile from "../../components/OrdersWidget";
import PositionsMobile from "../../components/PositionsWidget";
import Watchlist from "../widgets/watchlist/Watchlist";
import TradeLog from "../widgets/TradeLog/index";
import Trade from "../TradeTicketDrawer/Trade";
import TradingChart from "../widgets/tradingChart/TradingChart";
import React from "react";
import PriceLadder from "../widgets/priceLadder/PriceLadder";

let panelCount = 0;

const TRADE_TICKET_HEIGHT = 735;
const TRADE_TICKET_WIDTH = 384;
const PANEL_TICKET_HEIGHT = 400;
const PANEL_TICKET_WIDTH = 600;
const PRICE_LADDER_HEIGHT = 700;
const PRICE_LADDER_WIDTH = 301;


const generateUniqueId = () => {
  return `id${Date.now().toString()}${(panelCount++).toString()}`;
};

export const addPanels = (workspace: Workspace, dockviewApi: DockviewApi, widgets: Widget[], referenceGroup: any) => {
  widgets?.forEach((widget, index) => {
    const uniqueId = generateUniqueId();
    const panel = dockviewApi.addPanel<DockerviewPanelProps>({
      id: uniqueId,
      component: "default",
      renderer: "always",
      title: widget.widgetType,
      params: {
        widget,
        workspaceId: workspace.id
      },
      floating: {
        width: PANEL_TICKET_WIDTH,
        height: PANEL_TICKET_HEIGHT,
      },
    });
    panel.group.locked = "no-drop-target";
  });
};

export const addPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace, dockviewRef: React.MutableRefObject<null | any>,) => {
  const uniqueId = generateUniqueId();

  const { icon, ...widgetWithoutIcon } = widget;
  const totalPanels = dockviewApi.panels.length;
  const left = dockviewRef?.current?.clientWidth / 2 - PRICE_LADDER_WIDTH / 2;
  const top = dockviewRef?.current?.clientHeight / 2 - PRICE_LADDER_HEIGHT / 2;
  const floatingStyle = totalPanels === 0 ? {} : {floating: {
    position: widget.title === WidgetType.PriceLadder ? { top, left } : {
      left: 50 + totalPanels * 60,
      top: 50 + totalPanels * 30,
    },
    width: widget.title === WidgetType.PriceLadder ? PRICE_LADDER_WIDTH : PANEL_TICKET_WIDTH,
    height: widget.title === WidgetType.PriceLadder ? PRICE_LADDER_HEIGHT : PANEL_TICKET_HEIGHT,
  }}

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    ...floatingStyle,
    title: widget.widgetType === WidgetType.PriceLadder ? widget.widgetName : widget.widgetType,
    params: {
      widget: widgetWithoutIcon,
      workspaceId: workspace.id, 
    },
  });
  panel.group.locked = "no-drop-target";
};
export const addTradingTicketPanel = (
  dockviewApi: DockviewApi,
  widget: Widget,
  workspace: Workspace,
  dockviewRef: React.MutableRefObject<null | any>,
) => {
  const uniqueId = generateUniqueId();
  const left = dockviewRef?.current?.clientWidth / 2 - TRADE_TICKET_WIDTH / 2;
  const top = dockviewRef?.current?.clientHeight / 2 - TRADE_TICKET_HEIGHT / 2;
  const { icon, ...widgetWithoutIcon } = widget;

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: "Trade",
    floating: {
      width: TRADE_TICKET_WIDTH,
      height: TRADE_TICKET_HEIGHT,
      position: {
        left,
        top,
      },
    },
    params: {
      widget: widgetWithoutIcon,
      workspaceId: workspace.id, 
    },
  });
  dockviewApi.onWillDragPanel((event) => {
    if (event.panel.id === uniqueId) {
      event.nativeEvent.preventDefault();
    }
  });
  panel.group.locked = "no-drop-target";
};

export const addChartPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  const { icon, ...widgetWithoutIcon } = widget;

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.widgetType,
    floating: {
      width: PANEL_TICKET_WIDTH,
      height: PANEL_TICKET_HEIGHT,
    },
    params: {
      widget: widgetWithoutIcon,
      workspaceId: workspace.id, 
    },
  });

  panel.group.locked = "no-drop-target";
};

export const componentFactory = (widget: Widget, workspaceId: number) => {
  if (!workspaceId) return <>No workspace found</>;

  const workspaceClassName = "grid-layout-workspace";

  const isTradingChartWidget = widget.title === WidgetType.Chart || widget.title === WidgetType.PriceLadder;

  switch (widget.title) {
    case WidgetType.Watchlist:
      return <Watchlist widget={widget} workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.AvailableProducts:
      return <AvailableProducts workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.Orders:
      return <Orders workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.Positions:
      return <Positions workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.TradeTicket:
      return <Trade workspaceId={workspaceId} externalData={widget.widgetData} expired={widget.expired} />;
    case WidgetType.PriceLadder:
      return <PriceLadder workspaceId={workspaceId} widget={widget} />;
    default:
      if (isTradingChartWidget) {
        return <TradingChart symbols={widget.contractIds} workspaceId={workspaceId} widget={widget} />;
      }
      return <div>No widget found</div>;
  }
};
export const componentFactoryMobile = (widget: Widget, workspaceId: number) => {
  if (!workspaceId) return <>No workspace found</>;

  const workspaceClassName = "grid-layout-workspace";

  const isTradingChartWidget = widget.title === WidgetType.Chart || widget.title === WidgetType.PriceLadder;

  switch (widget.title) {
    case WidgetType.Watchlist:
      return <Watchlist widget={widget} workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.AvailableProducts:
      return <AvailableProducts workspaceId={workspaceId} workspaceClassName={workspaceClassName} isMobile={true} />;
    case WidgetType.Orders:
      return <OrdersMobile />;
    case WidgetType.Positions:
      return <PositionsMobile />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.TradeTicket:
      return <Trade workspaceId={workspaceId} externalData={widget.widgetData}  />;
    case WidgetType.PriceLadder:
      return <PriceLadder workspaceId={workspaceId} widget={widget} />;
    default:
      if (isTradingChartWidget) {
        return <TradingChart symbols={widget.contractIds} workspaceId={workspaceId}/>;
      }
      return <div>No widget found</div>;
  }
};

function RenderWhenVisible(component: React.FunctionComponent<IDockviewPanelProps>) {
  const HigherOrderComponent = (props: IDockviewPanelProps) => {
    const [visible, setVisible] = React.useState<boolean>(props.api.isVisible);

    React.useEffect(() => {
      const disposable = props.api.onDidVisibilityChange((event) => setVisible(event.isVisible));

      return () => {
        disposable.dispose();
      };
    }, [props.api]);

    if (!visible) {
      return null;
    }

    return React.createElement(component, props);
  };
  return HigherOrderComponent;
}

export const components = {
  default: RenderWhenVisible((props: IDockviewPanelProps<DockerviewPanelProps>) => {
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
          width: "100%",
        }}
      >
        {componentFactory(props.params.widget, props.params.workspaceId ?? 0)}
      </div>
    );
  }),
};
