import { CQGEnvironment } from "../../../cqg-api/services/CQGEnvironment";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { TradeTicketTab } from "../../../types";
import * as Order2 from "../../../../src/cqg-api/proto/order_2";
import { UseFormWatch } from "react-hook-form";
import { IFormInput } from "../types";
import { Instrument } from "../../../cqg-api/models/Instrument";

interface useTradePropsArgs {
  watch: UseFormWatch<IFormInput>;
  activeTab: string;
  selectedStrike: number | null;
  selectedContract: Instrument | null;
  selectedInstrument: any;
}

export const useTradeProps = ({
  watch,
  activeTab,
  selectedStrike,
  selectedContract,
  selectedInstrument,
}: useTradePropsArgs) => {
  const quantity = watch("quantity");
  const stopPrice = watch("stopPrice");
  const timeInForce = watch("timeInForce") === Order2.Order_Duration.DURATION_DAY ? "DAY" : "GTC";
  const putCall = watch("putCall");
  const orderType = watch("orderType");
  const contractValue = watch("contract");
  const limitPrice = watch("limitPrice");
  const orderSide = watch("side");
  const strikePrice = selectedStrike ? selectedContract?.strikePrice : null;

  const takeProfit =
    activeTab === TradeTicketTab.Futures && orderSide && watch(`oco.${orderSide}.takeProfit.isSelected`)
      ? Number(watch(`oco.${orderSide}.takeProfit.price`))
      : undefined;

  const stopLossPrice =
    activeTab === TradeTicketTab.Futures && orderSide && watch(`oco.${orderSide}.stopLoss.isSelected`)
      ? Number(watch(`oco.${orderSide}.stopLoss.price`))
      : undefined;

  const lastPrice =
    activeTab === TradeTicketTab.Options
      ? limitPrice
      : selectedInstrument && selectedContract
        ? DisplayUtil.toDisplayPrice(selectedInstrument?.lastPrice, selectedContract)
        : null;

  const account = CQGEnvironment.Instance.accountsManager.getAccount(CQGEnvironment.Instance.selectedAccount?.id || "");

  return {
    side: orderSide,
    quantity,
    contract: selectedStrike ? selectedContract?.displayName : contractValue,
    orderType,
    limitPrice,
    stopPrice,
    takeProfit,
    stopLossPrice,
    contractSize: selectedContract?.contractSize,
    tickSize: selectedContract?.tickSize,
    lastPrice,
    tickValue: selectedContract?.tickValue,
    marginCommited: selectedContract?.marginInitialRate,
    tif: timeInForce,
    putCall,
    strikePrice,
    instrument: selectedInstrument,
    account,
  };
};
