
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  tableCell: {
    fontSize: '12px !important',
    fontFamily: 'Averta-regular !important',
    background: '#eee !important',
    lineHeight: '1 !important',
    padding: '6px !important',
    fontWeight: '500 !important',
    backgroundColor: '#F5F5F5 !important',
    color: '#081D37DE !important',
  },
  tableCellBody: {
    fontSize: '12px !important',
    fontFamily: 'Averta-regular !important',
    lineHeight: '1 !important',
    padding: '6px !important',
    color: '#081D3799',
    alignSelf: 'center',
  },
  fixedColumn: {
    position: 'sticky',
    fontSize: '12px !important',
    fontFamily: 'Averta-regular !important',
    lineHeight: '1 !important',
    background: 'white !important',
    paddingTop: '13px !important',
    paddingBottom: '13px !important',
    right: 0,
    zIndex: 1, // Ensures it stays above other cells
    borderLeft: '1px solid #ddd', // Optional: Adds a divider for better UX
  },
  tableCellBodyNoData: {
    fontSize: '14px !important',
    fontFamily: 'Averta-regular !important',
    lineHeight: '1 !important',
    padding: '40px !important',
    textAlign: 'center',
  },
  fixedColumnHead: {
    fontWeight: '600 !important',
    backgroundColor: '#eee !important', // Match the table header background
  },
  '@media (max-width: 600px)': {
    fixedColumn: {
      minWidth: 100,
    },
  },
  contractTitle: {
    color: '#081D37',
    fontFamily: 'Averta-Regular',
    fontWeight: 600,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: '13px',
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
  }
}));

export const iconButtonStyles = {
  "&.Mui-disabled": {
    opacity: 0.5,
  },
  "&.MuiIconButton-root": {
    padding: "0px 6px"
  }
};
export const flattenButtonStyles = {
  width: '2px',
  height: '18px',
  background: 'rgba(0, 0, 0, 0.12)',
};
export default useStyles;