import './MessageDialog.css';
import { useAppMessages } from '../../cqg-api/hooks/ServiceHooks';
import { useEffect, useState } from 'react';
import Notification from './notification/Notification';
import { AppMessageSubTypes, AppMessageTypes } from '../../cqg-api/utils/AppMessages';
import ConfirmCancelDialog from './ConfirmCancelDialog';

interface rejectDialogState {
  message: string;
}

const AppMessagesHost = () => {
  const { appMessage, setMessageHandled } = useAppMessages();
  const [showRejectDialog, setShowRejectDialog] = useState<rejectDialogState | null>(null);

  useEffect(() => {
    if (!appMessage) return;

    if (appMessage?.msgType === AppMessageTypes.INFO_MESSAGE) {
      if (appMessage?.msgSubType === AppMessageSubTypes.NEW_MSG_RECEIVED) {
        setMessageHandled(appMessage?.msgId);
      }
      return;
    }

    if (appMessage?.msgType === AppMessageTypes.ORDER_FILLED) {
      Notification.orderInfo(appMessage?.data);
      setMessageHandled(appMessage?.msgId);
    }

    if (appMessage?.msgType === AppMessageTypes.ORDER_CANCELLED) {
      Notification.orderInfo(appMessage?.data, "Cancelled");
      setMessageHandled(appMessage?.msgId);
    }

    if (appMessage?.msgType === AppMessageTypes.ORDER_AMENDED) {
      Notification.orderAmendInfo(appMessage?.data, "Amended");
      setMessageHandled(appMessage?.msgId);
    }

    if (appMessage?.msgType === AppMessageTypes.ORDER_REJECTED) {
      setShowRejectDialog({
        message: appMessage?.data.rejectMessage,
      });
      setMessageHandled(appMessage?.msgId);
    }

  }, [appMessage, setMessageHandled]);

  const setHandled = () => {
    appMessage && setMessageHandled(appMessage.msgId);
    setShowRejectDialog(null);
  };

  return (<>
    {showRejectDialog &&
      <ConfirmCancelDialog
        open={!!showRejectDialog}
        title={"Order Rejected"}
        message={
          <span>
            <p>Your order was rejected.</p>
            <p><b>Reject reason:</b></p>
            <p>{showRejectDialog.message}</p>
          </span>
        }
        onClose={() => setHandled()}
        onConfirm={() => setHandled()}
        cancelText=""
        confirmText="ok"
      />
    }
  </>)
};

export default AppMessagesHost;
