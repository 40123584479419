import { Box, TextField, Typography } from "@mui/material";
import ControlledCheckboxGroup from "./CheckboxGroup";
import ControlledRadioGroup from "./RadioGroup";
import { useState } from "react";
import { Controller } from "react-hook-form";

const TechnicalApproach = (props: { control: any, setType: (val: string) => void, type: string, isOther: boolean }) => {
    const { control, setType, type, isOther } = props;

    const [isOtherChecked, setIsOtherChecked] = useState<boolean>(isOther);

    const handleCheckboxChange = (value: string, checked: boolean) => {
        if (value === "Other") {
            setIsOtherChecked(checked);
        }
    };

    return (
        <>
            <ControlledRadioGroup control={control} title="Please select indicator or chart" options={["Indicator", "Chart", "Both"]} name="approachTechnicalType" onChange={(value: any) => setType(value)} />
            <Box
                marginTop="32px"
                display={type === "Both" ? "flex" : "block"}
                justifyContent="space-between"
                gap={10}
            >
                {(type === "Indicator" || type === 'Both') && (
                    <Box>
                        <ControlledCheckboxGroup
                            control={control}
                            title="Indicators used (Check all that apply)"
                            options={["Moving Average (MA)", "RSI", "Stochastic (STOCH)", "Fibonacci Retracements", "Other"]}
                            name="approachTechnicalIndicator"
                            onCheckboxChange={handleCheckboxChange}
                        />
                        {isOtherChecked && (
                            <Controller
                                name="approachTechnicalIndicatorOther"
                                control={control}
                                render={({ field }) => (
                                    <Box marginLeft="30px">
                                        <Typography variant="subtitle2" color="text.secondary" mb={0}>
                                            Enter your own indicator
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={0}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.target.value)}
                                            sx={{
                                                height: "42px",
                                                ".MuiInputBase-root": {
                                                    height: "36px",
                                                },
                                                ".MuiInputBase-multiline": {
                                                    padding: 0,
                                                },
                                                ".MuiInputBase-input": {
                                                    padding: "6px",
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            />
                        )}
                    </Box>
                )}

                {(type === "Chart" || type === "Both") && (
                    <Box marginBottom="32px">
                        <ControlledRadioGroup control={control} title="Chart used" options={["Intra-day", "Daily", "Pattern"]} name="approachTechnicalChartType" />
                    </Box>
                )}
            </Box>
        </>
    );
}

export default TechnicalApproach;