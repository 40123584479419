import "../widgets/widgets.css";
import MenuItem from "../../../../common/userinfo/Menutem";
import { Settings, Create, LiveHelp } from "@mui/icons-material";
import TradePlan from "../../tradePlan/TradePlan";
import { EducationIcon, TradeLogIcon } from "../../../../../images/Icons";
import TradeLogComponent from "../../TradeLog/TradeLogComponent";

const items = [
  { text: "Education", icon: EducationIcon },
  { text: "Trade Plan", icon: Create },
  { text: "Trade Log", icon: TradeLogIcon },
  { text: "Settings", icon: Settings },
  { text: "Help", icon: LiveHelp },
];

const ToolsList = ({ 
  onMenuItemClick, 
  enableMenuItems, 
  activeTabIndex 
}: { 
  onMenuItemClick: (component: JSX.Element | null, index: number | null) => void; 
  enableMenuItems: () => void; 
  activeTabIndex: number | null; 
}) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index} 
          onClick={() => {
            let component = null;
            if (item.text === "Trade Plan") {
              component = <TradePlan onHandleClose={enableMenuItems} />;
            }
            if (item.text === "Trade Log") {
              component = <TradeLogComponent onHandleClose={enableMenuItems} />;
            }
            onMenuItemClick(component, index); 
          }} 
          style={activeTabIndex === index ? { borderRight: "2px solid var(--primary-main-blue, #006EB6)" } : {}}
        >
          <MenuItem text={item.text} icon={item.icon} isActive={activeTabIndex === index} />
        </li>
      ))}
    </ul>
  );
};

const ToolsNav = ({
  onMenuItemClick,
  enableMenuItems,
  activeTabIndex,
}: {
  onMenuItemClick: (component: JSX.Element | null, index: number | null) => void;
  enableMenuItems: () => void;
  activeTabIndex: number | null;
}) => (
  <div className="productsNav">
    <h3 className="heading">TOOLS</h3>
    <ToolsList
      onMenuItemClick={onMenuItemClick}
      enableMenuItems={enableMenuItems}
      activeTabIndex={activeTabIndex}
    />
  </div>
);

export default ToolsNav;
