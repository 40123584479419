import React from "react";
import { Button } from "@mui/material";
import { cancelAllButtonStyles } from "./PriceLadderStyles";
import { useOrdersList } from "../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { cancelOrders } from "../orders/actions";
import useOrderCancel from "./hooks/useOrderCancel";
import ConfirmCancelDialog from "../../shared/ConfirmCancelDialog";

const CancelAllOrders: React.FC<{ contract: Instrument }> = ({ contract }) => {
  const { ordersList } = useOrdersList();
  const { isDialogOpen, requestCancel, confirmCancel, closeDialog } = useOrderCancel();

  const cancelAllOrdersHandler = () => {
    cancelOrders(ordersList, { contract });
  };

  const handleCancelClick = () => {
    requestCancel(cancelAllOrdersHandler);
  };

  return (
    <>
      <div className="price-ladder-width">
        <Button variant="text" sx={cancelAllButtonStyles} onClick={handleCancelClick}>
          CANCEL ALL
        </Button>
      </div>
      <ConfirmCancelDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onConfirm={confirmCancel}
        loading={false}
        title="Confirm Order Cancellation"
        message="Are you sure you want to cancel all orders?"
      />
    </>
  );
};

export default CancelAllOrders;
