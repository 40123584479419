import { Box, Button } from "@mui/material";
import clsx from "clsx";

const boxStyles = {
  width: "100%",
  height: "122px",
  gap: "8px",
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  paddingRight: "15px",
};

const buttonStyles = {
  height: "42px",
  fontSize: "12px",
  background: "#006EB6",
  padding: "8px 22px",
  float: "right",
};

const SaveButton = ({ isMobileView }: { isMobileView: boolean }) => {
  return (
    <Box
      sx={boxStyles}
      className={clsx({
        "trade-plan-save-button-mobile": isMobileView,
      })}
    >
      <Button
        type="submit"
        variant="contained"
        sx={{ ...buttonStyles, width: isMobileView ? "100%" : "84px" }}
        fullWidth={isMobileView}
      >
        Save
      </Button>
    </Box>
  );
};

export default SaveButton;
