import * as _ from "../../vendors/underscore-esm";
import { CQGEnvironment } from './CQGEnvironment';
import { CQGService } from './CQGService';
import { CQGServiceMessageEventManager } from '../message-managers/ServiceMessageEventManager';
import { AccountsManager } from "./AccountsManager";
import { Account } from "../models/Account";

class AccountSelectorManager {
  cqgEnvironment: CQGEnvironment;
  selectedAccount: Account | null;
  serviceMessageEventManager: any;
  accountsManager: AccountsManager;
  
  constructor(cqgEnvironment: CQGEnvironment, serviceMessageEventManager: CQGServiceMessageEventManager, accountsManager: AccountsManager) {
    this.cqgEnvironment = cqgEnvironment;
    this.selectedAccount = null;
    this.accountsManager = accountsManager;
    this.serviceMessageEventManager = serviceMessageEventManager; 

    // Register event listeners
    // this.cqgEnvironment.onLoggedOn.add(this.handleLoggedOn.bind(this));
    this.serviceMessageEventManager?.onServiceReady(() => {
        this.handleLoggedOn();
    });
  }

  // Retrieve all accounts
  getAccounts() {
    let accounts = [];
    _.forEach(this.accountsManager.fcms, (fcm: any) => {
      accounts = accounts.concat(fcm.getAccounts());
    });
    return accounts;
  }

  // Set the selected account
  setSelectedAccount(account: Account) {
    this.selectedAccount = account;
    if (this.selectedAccount) {
      this.cqgEnvironment.setSelectedAccount(this.selectedAccount);
      CQGEnvironment.Instance.cqgService.subscribeToAccountSummaryAndCurrencyRates()
    }
  }

  // Handle the accounts being loaded
  onAccountsLoaded() {
    const accounts = this.getAccounts();
    if (accounts && accounts.length) {
      // Retrieve previously selected account ID from storage (optional)
      // const selectedAccountId = localStorage.getItem('selectedAccountId');
      const selectedAccountId = null; // Replace with your storage mechanism if needed

      if (selectedAccountId) {
        const existingAccount = _.find(accounts, (account) => account.id === selectedAccountId);
        if (existingAccount) {
          this.setSelectedAccount(existingAccount);
          return;
        }
      }

      // Default to the first account if no previous selection exists
      this.setSelectedAccount(accounts[0]);
    }
  }

  // Handle the logged-on event
  handleLoggedOn() {
    if (this.cqgEnvironment.isLoggedOn) {
      this.accountsManager
        .getAccountsLoadedPromise()
        .then(() => this.onAccountsLoaded());
    }
  }

  // Clean up event listeners (optional)
  destroy() {
    // this.cqgEnvironment.onLoggedOn.remove(this.handleLoggedOn.bind(this));
  }
}

export default AccountSelectorManager;
