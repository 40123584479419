import { Typography } from "@mui/material";
import React, { useState } from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import TradeLogHeader from "./TradeLogHeader";

const MobileTradeLogHeader = ({ isMobileView }: { isMobileView: boolean }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        elevation={0}
        sx={{ boxShadow: "none", margin: "5px 0 10px 0", "&:before": { height: "0px" } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#1976d2" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ background: "#e5f6fd", borderRadius: "5px" }}
        >
          <Typography
            sx={{
              fontFamily: "Averta-Regular",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "17.16px",
              letterSpacing: "1px",
            }}
          >
            Practice Simulator Trade Log
          </Typography>
        </AccordionSummary>
        {expanded && (
          <AccordionDetails>
            <TradeLogHeader isMobileView={isMobileView} />
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default MobileTradeLogHeader;
