import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F5F5",
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0',
    minWidth: '20px',
    maxWidth: '200px',
    lineHeight: '32px',
    letterSpacing: '0.17px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '0px',
    padding: '0px 10px', 
    color: '#081D37DE',
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  height: 450,
  width: '100%',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#fff",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected-product-row": {
    backgroundColor: "#fff",
  },
  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    padding: '0',
  }
}));

export const StyledTableRowTitle = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "var(--_components-alert-info-background, #E5F6FD)",
  height: '29px',

  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  "&.selected-product-row": {
    backgroundColor: "var(--_components-alert-info-background, #fff)",
  },

  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    padding: '0',
    paddingLeft: "10px",
  },
  [`&.MuiTableCell-root`]: {
    Padding: 0,
    paddingLeft: "10px !important"
  },
}));
