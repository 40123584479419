import { Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import TooltipWithIcon from "../../../shared/TooltipWithIcon";

const InputBox = (props: any) => {
       
    const { control, title, name, tooltipText } = props;

    return (
        <>
            <Box display="flex" alignItems="center">
                <label className="label">{title}</label>
                {tooltipText && (
                    <TooltipWithIcon tooltipText={tooltipText} />
                )}
            </Box>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            style: { height: "36px" }
                        }}
                    />
                )}
            />
        </>
    );
};

export default InputBox;
