const SET_CHART_WIDGET_VALUE = 'SET_CHART_WIDGET_VALUE';
const CLEAR_CHART_WIDGET_VALUE = 'CLEAR_CHART_WIDGET_VALUE';
const SET_TRADE_TICKET_VALUE = 'SET_TRADE_TICKET_VALUE';
const CLEAR_TRADE_TICKET_VALUE = 'CLEAR_TRADE_TICKET_VALUE';
const SET_SELECTED_INSTRUMENT = 'SET_SELECTED_INSTRUMENT';
const CLEAR_SELECTED_INSTRUMENT = 'CLEAR_SELECTED_INSTRUMENT';
const SET_WIDGETDATA = 'SET_WIDGETDATA';
const CLEAR_WIDGETDATA = 'CLEAR_WIDGETDATA';

const initialState = {
  chartWidgetValue: null,
  tradeTicketValue: null,
  selectedInstrument: null,
  instrument: null,
  widgetDataValue: null,
};

const chartWidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_WIDGET_VALUE:
      return {
        ...state,
        chartWidgetValue: action.payload,
      };
    case CLEAR_CHART_WIDGET_VALUE:
      return {
        ...state,
        chartWidgetValue: null,
      };
    case SET_TRADE_TICKET_VALUE:
      return {
        ...state,
        tradeTicketValue: action.payload,
      }
    case SET_SELECTED_INSTRUMENT:
      console.log('action.payload', action.payload);
      return {
        ...state,
        selectedInstrument: action.payload.instrument,
        instrument: action.payload.selected,
      }
    case CLEAR_SELECTED_INSTRUMENT:
      return {
        ...state,
        selectedInstrument: null,
        instrument: null,
      }
    case SET_WIDGETDATA:
      return {
        ...state,
        widgetDataValue: action.payload,
      };
    case CLEAR_WIDGETDATA:
      return {
        ...state,
        widgetDataValue: null,
      };
    default:
      return state;
  }
};

export const setChartWidgetValue = (widget) => ({
  type: SET_CHART_WIDGET_VALUE,
  payload: widget,
});

export const clearChartWidgetValue = () => ({
  type: CLEAR_CHART_WIDGET_VALUE,
});
export const setTradeTicketValue = (widget) => ({
  type: SET_TRADE_TICKET_VALUE,
  payload: widget,
});
export const setSelectedInstrument = ({instrument, selected}) => ({
  type: SET_SELECTED_INSTRUMENT,
  payload: {
    instrument,
    selected,
  },
});
export const clearSelectedInstrument = () => ({
  type: CLEAR_SELECTED_INSTRUMENT,
});
export const clearTradeTicketValue = () => ({
  type: CLEAR_TRADE_TICKET_VALUE,
});

export const setWidgetDataValue = (widgetDataValue) => ({
  type: SET_WIDGETDATA,
  payload: widgetDataValue,
});

export const clearWidgetDataValue = () => ({
  type: CLEAR_WIDGETDATA,
});

export default chartWidgetReducer;
