import { CurrencyRatesSubscription } from "../models/CurrencyRatesSubscription";
import * as _ from "../../vendors/underscore-esm";
import * as WebAPI from "../proto/webapi_2";
import { Account } from "../models/Account";
import { CurrencyRate } from "../models/CurrencyRate";
import * as TradingAccount from "../proto/trading_account_2";
import { CQGEnvironment } from "./CQGEnvironment";

export class CurrencyRatesManager {
  subscription: CurrencyRatesSubscription | null = null;
  currencyRates: { [key: string]: CurrencyRate } = {}; //dictionary<{brokerageId-currency},currencyRate>

  subscribe = (requestId: number) => {
    return (this.subscription = new CurrencyRatesSubscription(requestId));
  };

  isSubscribed = () => {
    return this.subscription != null;
  };

  clear = () => {
    this.subscription = null;
    this.currencyRates = {};
  };

  processInformationReport = (informationReport: WebAPI.InformationReport[]) => {
    _.forEach(informationReport, (item: WebAPI.InformationReport) => {
      this.processCurrencyRatesReport(item);
    });
  };

  getCurrencyRate = (account: Account, currency: string) => {
    var currencyRateKey = this.getCurrencyRateKey(account.getFcm().id, currency);
    var currencyRate = this.currencyRates[currencyRateKey];
    return currencyRate ? currencyRate.rate : null;
  };

  processCurrencyRatesReport = (informationReport: WebAPI.InformationReport) => {
    if (!this.subscription || this.subscription.id != informationReport.id) {
      return;
    }

    this.subscription.statusCode = informationReport.statusCode;
    this.subscription.textMessage = informationReport.textMessage;
    if (
      this.subscription.statusCode == WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS ||
      this.subscription.statusCode == WebAPI.InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED ||
      this.subscription.statusCode == WebAPI.InformationReport_StatusCode.STATUS_CODE_UPDATE
    ) {
      var currencyRatesReport = informationReport.currencyRatesReport;
      _.forEach(currencyRatesReport?.brokerageCurrencyRates, (item: TradingAccount.BrokerageCurrencyRates) => {
        this.processBrokerageCurrencyRate(item);
      });
    }
  };

  processBrokerageCurrencyRate = (brokerageCurrencyRate: TradingAccount.BrokerageCurrencyRates) => {
    _.forEach(brokerageCurrencyRate.currencyRates, (item: TradingAccount.CurrencyRate) => {
      this.processCurrencyRate(brokerageCurrencyRate, item);
    });
  };

  processCurrencyRate = (
    brokerageCurrencyRate: TradingAccount.BrokerageCurrencyRates,
    currencyRate: TradingAccount.CurrencyRate,
  ) => {
    var currencyRateKey = this.getCurrencyRateKey(brokerageCurrencyRate.brokerageId, currencyRate.currency);
    var currencyRateToUpdate = this.currencyRates[currencyRateKey];
    if (!currencyRateToUpdate) {
      var fcm = CQGEnvironment.Instance.accountsManager.getFcm(brokerageCurrencyRate.brokerageId);
      if (!fcm) {
        return;
      }
      var newCurrencyRate = new CurrencyRate(brokerageCurrencyRate, currencyRate);
      this.currencyRates[currencyRateKey] = newCurrencyRate;
      fcm.getCurrencyRates().push(newCurrencyRate);
    } else {
      currencyRateToUpdate.update(brokerageCurrencyRate, currencyRate);
    }
  };

  getCurrencyRateKey = (brokerageId: number, currency: string) => {
    return brokerageId + "-" + currency;
  };
}
