import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, FormControl, Typography, SelectChangeEvent } from "@mui/material";
import { SimulatorOptionsControllers } from "../../cqg-api/controllers/SimulatorOptionsControllers";
import { Instrument } from "../../cqg-api/models/Instrument";

interface SimulatorOptionsProps {
  instrument: Instrument;
  putCall?: string;
  selectedStrike: number | null;
  onStrikeChange: (strikeDetails: { strike: number | null; callOption: any; putOption: any }) => void;
  onError?: (hasError: boolean) => void;
  hide?: boolean;
  widgetData?: { data?: { strikePrice?: number } };
}

const SimulatorOptions: React.FC<SimulatorOptionsProps> = ({
  instrument,
  putCall,
  selectedStrike,
  onStrikeChange,
  onError,
  hide,
  widgetData, 
}) => {
  const [controller, setController] = useState<SimulatorOptionsControllers | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [atmStrike, setAtmStrike] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const newController = new SimulatorOptionsControllers({
      addOrUpdate: (contractMetadata) => {},
      instruments: instrument,
    });
    setController(newController);
  }, [instrument]);

  useEffect(() => {
    if (controller && instrument) {
      controller.onShowOptions({ show: true, instrument });
    }
  }, [controller, instrument]);

  useEffect(() => {
    if (widgetData?.data?.strikePrice) {
      setLoading(false);
    } else if (controller?.strikeOptions && controller.strikeOptions.length > 0) {
      setLoading(false);
      setErrorMessage(null);

      const atmStrikeValue = controller.atmStrike;
      setAtmStrike(atmStrikeValue);

      if (selectedStrike === null || !controller.strikeOptions.find(option => option.strike === selectedStrike)) {
        const atmOption = controller.strikeOptions.find(option => option.strike === atmStrikeValue);
        onStrikeChange({
          strike: atmStrikeValue,
          callOption: putCall === "C" ? atmOption?.callOption : null,
          putOption: putCall === "P" ? atmOption?.putOption : null,
        });
      } else {
        const selectedOption = controller.strikeOptions.find((option) => option.strike === selectedStrike);
        if (selectedOption) {
          const { callOption, putOption } = selectedOption;
          onStrikeChange({
            strike: selectedStrike,
            callOption: putCall === "C" ? callOption : null,
            putOption: putCall === "P" ? putOption : null,
          });
        }
      }
      onError?.(false);
    } else {
      setErrorMessage(controller?.loadError ? controller.loadStatus || "No options available" : null);
      onError?.(!!controller?.loadError);
    }
  }, [controller, controller?.strikeOptions, putCall, selectedStrike, onStrikeChange, onError, widgetData]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const newStrike = event.target.value;
    const strikeAsNumber = typeof newStrike === "string" ? parseFloat(newStrike) : newStrike;

    if (!isNaN(strikeAsNumber)) {
      if (controller?.strikeOptions) {
        const selectedOption = controller?.strikeOptions.find((option) => option.strike === strikeAsNumber);

        if (selectedOption) {
          const { callOption, putOption } = selectedOption;
          onStrikeChange({
            strike: strikeAsNumber,
            callOption: putCall === "C" ? callOption : null,
            putOption: putCall === "P" ? putOption : null,
          });
        }
      }
    } else {
      console.warn("Selected strike value is not a number");
    }
  };

  const isWidgetStrikeAvailable = widgetData?.data?.strikePrice !== undefined;

  return (
    <Box sx={{ display: `${hide ? 'none' : 'flex'}` }}>
      {loading ? (
        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
          Loading Options for {instrument?.displayName}. Please wait...
        </Typography>
      ) : (
        <FormControl fullWidth>
          <Select
            value={errorMessage ? "Not available" : isWidgetStrikeAvailable ? widgetData?.data?.strikePrice : selectedStrike ?? atmStrike ?? ""}
            displayEmpty
            sx={{
              width: "100%",
              height: "38px",
              color: errorMessage ? "error.main" : "text.primary",
            }}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflow: "auto",
                },
              },
            }}
            disabled={!!errorMessage || isWidgetStrikeAvailable}  // Disable if errorMessage or strikePrice is available
          >
            {errorMessage ? (
              <MenuItem value="Not available">
                {errorMessage}
              </MenuItem>
            ) : isWidgetStrikeAvailable ? (
              <MenuItem value={widgetData?.data?.strikePrice}>
                {widgetData?.data?.strikePrice}
              </MenuItem>
            ) : (
              controller?.strikeOptions?.map((strike: any) => {
                return (
                  <MenuItem key={`strike-${strike.strike}`} value={strike.strike}>
                    {strike.strike}
                  </MenuItem>
                )
              })
            )}
          </Select>
        </FormControl>
      )}

      {controller?.loadError && controller.loadStatus === "This product does not have an options contract" && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {controller.loadStatus}
        </Typography>
      )}
    </Box>
  );
};

export default SimulatorOptions;
