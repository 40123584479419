import PlusIcon from "../../../../common/icons/PlusIcon";
import { Workspace } from "../../../../../types";
import "../Dashboard.css";
import { useCreateWorkspace } from "../../../../../hooks/api/workspace";
import Notification from "../../../../shared/notification/Notification";
import { useState, useCallback } from 'react';

type AddNewWorkspaceProps = {
  onNewWorkspaceCreated: (workspace: Workspace) => void;
  workspaceTabsLimit: number;
  workSpaces: Workspace[];
};
const AddNewWorkspace = ({
  onNewWorkspaceCreated,
  workspaceTabsLimit,
  workSpaces
}: AddNewWorkspaceProps) => {
  const { createWorkspaceAsync } = useCreateWorkspace();
  const [loading, setLoading] = useState(false);
  const [lastUsedNumber, setLastUsedNumber] = useState<number | null>(null);

  const onCreateWorkspace = useCallback(() => {
    if (loading) return; 
    setLoading(true);
    if (workSpaces?.length < workspaceTabsLimit) {
      const baseTitle = 'New Workspace';
      const existingTitles = workSpaces.map(workspace => workspace.title);
      const numbers = existingTitles
        .map(title => {
          const match = title.match(/New Workspace-(\d+)/);
          return match ? parseInt(match[1], 10) : null;
        })
        .filter(num => num !== null);
  
      let nextNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;

      if (lastUsedNumber !== null && workSpaces.length <= lastUsedNumber) {
        setLastUsedNumber(null);
        return setLoading(false);
      }

      if (nextNumber === lastUsedNumber) {
        return setLoading(false);
      }

      const newTitle = `${baseTitle}-${nextNumber}`;
  
      const workspaceDTO: Workspace = {
        title: newTitle,
      };
  
      createWorkspaceAsync(workspaceDTO, (workspace: Workspace) => {
        onNewWorkspaceCreated(workspace);
        setLastUsedNumber(nextNumber); 
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        console.log("Error while creating workspace:", error);
      });
    } else {
      setLoading(false);
      Notification.error(`You've reached your limit of ${workspaceTabsLimit} workspaces. To add more, please remove an existing one.`);
    }
  }, [createWorkspaceAsync, loading, lastUsedNumber, onNewWorkspaceCreated, workSpaces, workspaceTabsLimit]);
  return (
    <>
      <div className={`Tab newWorkspace loadingContainer`}>
        <button onClick={onCreateWorkspace} disabled={loading}>
          {loading ? (
            <></>
          ) : (
            <>
              {workSpaces.length === 0 && <span>New</span>}
              <PlusIcon />
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default AddNewWorkspace;
