import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tooltip: {
    width: '114px',                     
    padding: '4px 8px',                 
    gap: 0,                             
    borderRadius: '4px',
    opacity: 0,                         
    backgroundColor: '#607D8B',         
    fontFamily: 'Averta-Regular',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#607D8B',
    },
  },
});
type OptionsTabProps = {
  isLoading: string | null;
}
export const OptionsTab = ({ isLoading }: OptionsTabProps) => {
  const classes = useStyles();
  return (
  <Tooltip
      title={isLoading ? isLoading : "Not available for this Product"}
      placement="top"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    ><div className='optionsDisabled'>Options</div></Tooltip>
  );
};

export default OptionsTab;
