import { Control, Controller, FieldError, UseFormSetValue } from "react-hook-form";
import { StyledFormControl } from "../styles";
import SideButton from "./BuyButton";
import { IFormInput } from "../types";
import { CSSProperties } from "react";
import { OrderSide } from "../../../types";

type SideControllerProps = {
  control: Control<IFormInput>;
  side?: FieldError;
  setValue: UseFormSetValue<IFormInput>;
  disabled?: boolean;
};
const SideController = ({ control, side, setValue, disabled }: SideControllerProps) => {
  const disabledStyle: CSSProperties | undefined = disabled ? { pointerEvents: "none", opacity: 0.5 } : undefined;
  return (
    <StyledFormControl fullWidth>
      <label className="label">Side</label>
      <Controller
        name="side"
        control={control}
        rules={{
          required: "Side is required",
        }}
        render={({ field }) => (
          <>
            <div className="instrumentContainer" style={disabledStyle}>
              <SideButton
                side={side}
                value={field.value}
                onClick={() => setValue(field.name, OrderSide.Buy)}
                cssClass="instrumentAction-buy"
                text="BUY"
              />
              <SideButton
                side={side}
                value={field.value}
                onClick={() => setValue(field.name, OrderSide.Sell)}
                cssClass="instrumentAction-sell"
                text="SELL"
              />
            </div>
          </>
        )}
      />
    </StyledFormControl>
  );
};

export default SideController;
