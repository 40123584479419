import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, Checkbox, Typography } from '@mui/material';
interface ControlledCheckboxGroupProps {
  control: any;
  title: string;
  options: string[];
  name: string;
  onCheckboxChange?: (option: string, checked: boolean) => void;
}

const ControlledCheckboxGroup: React.FC<ControlledCheckboxGroupProps> = ({
  control,
  title,
  options,
  name,
  onCheckboxChange,
}) => {
  return (
    <FormControl component="fieldset">
      <label style={{ color:"#081D37", fontSize: "14px", lineHeight: "20.02px", letterSpacing: "0.17px"}} >{title}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={field.value?.includes(option) || false}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const newValue = checked
                        ? [...(Array.isArray(field.value) ? field.value : []), option]
                        : (Array.isArray(field.value) ? field.value : []).filter((item: string) => item !== option);
                      field.onChange(newValue);
                      onCheckboxChange?.(option, checked)
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        width: '19px',
                        height: '19px',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                      sx={{
                          fontFamily: "Averta-Regular",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                      }}
                  >
                      {option}
                  </Typography>
                }
                sx={{ paddingLeft: '8px'}}
              />
            ))}
          </>
        )}
      />
    </FormControl>
  );
};

export default ControlledCheckboxGroup;
