import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import './ordersModal.css';
import useStyles from './styles';
import { OrderSide } from '../../types';
import { OrderStatus_Status } from '../../cqg-api/proto/common/shared_1';
import { cancelOrders, capitalizeFirstLetter, getOrderStatusLabel } from '../widgets/orders/actions';
import { formatNumber } from '../../utils/getPrice';
import { convertSymbolToYear } from '../tree-view/utils/convertSymbolToDate';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type OrderDetailsProps = {
  open: boolean;
  onClose: () => void;
  handleAmmendOrder: () => void;
  data: any;
};
const getSide = (side: string) => {
  if (side === OrderSide.Buy) {
    return <span className='side buy'>B</span>
  }
  return <span className='side sell'>S</span>
}
export default function OrderDetails({ open, onClose, data, handleAmmendOrder }: OrderDetailsProps) {
  const classes = useStyles();

  const handleConfirmCancelOrder = React.useCallback(() => {
    cancelOrders([data?.order]);
    onClose();
  }, [data?.order, onClose]);

  if (!data) return null;
  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose();
  };
  const isOption = data.strike !== 0;
  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Box justifyContent={"space-between"} sx={{ padding: '2px 6px', display: 'flex' }} alignItems="center">
        <div className={classes.contractTitle}>
          <div className='text-sm contract'>{getSide(data.side)}</div>
          <div>{data.contract}</div>
        </div>
        <Button variant="text" onClick={toggleDrawer(false)}>
          <CloseIcon sx={{ color: '#5A6874' }} />
        </Button>
      </Box>
      <div className={clsx('tableData', { threeCol: !isOption })}>
        <div className={classes.tableCell}>Contract</div>
        <div className={classes.tableCell}>Month</div>
        <div className={classes.tableCell}>Type</div>
        {isOption && <div className={classes.tableCell}>C/P</div>}
        <div className={classes.tableCellBody}>{data.symbol}</div>
        <div className={classes.tableCellBody}>{convertSymbolToYear(data.symbol, data.month)}</div>
        <div className={classes.tableCellBody}>{data.type}</div>
        {isOption && <div className={classes.tableCellBody}>{data.cp}</div>}
        <div className={classes.tableCell}>Status</div>
        <div className={classes.tableCell}>QTY</div>
        <div className={classes.tableCell}>Price</div>
        {isOption && <div className={classes.tableCell}>Strike</div>}
        <div className={classes.tableCellBody}>
          <div className="orderStatus">
            <span
              className={clsx({
                "order-status-value": true,
                "order-status-value--expired":
                  data.status === OrderStatus_Status.EXPIRED ||
                  data.status === OrderStatus_Status.REJECTED ||
                  data.status === OrderStatus_Status.CANCELLED,
                "order-status-value--working": data.status === OrderStatus_Status.WORKING,
              })}
            >
              {capitalizeFirstLetter(getOrderStatusLabel(OrderStatus_Status, data.status))}
            </span>
          </div>
        </div>
        <div className={classes.tableCellBody}>{data.qty}</div>
        <div className={classes.tableCellBody}>{formatNumber(parseFloat(data.limitPX ?? data.fillPX))}</div>
        {isOption && <div className={classes.tableCellBody}>{data.strike}</div>}
      </div>
      <Divider />
      <div className='flex w-full justify-between p-2 ActionsButtons'>
        <button
            className="mobile-action-button cancel-all-orders"
          >
            AMEND
          </button>
          <button
            onClick={handleConfirmCancelOrder}
            className={`mobile-action-button cancel-all-orders active`} 
          >
          CANCEL
        </button>
      </div>
    </Box>
  );

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
    >
      {list("bottom")}
    </Drawer>
  );
}
