import React, { useEffect, useState, useRef } from 'react';

interface RenderColorCellProps {
  value: number | null | undefined;
}

const COLORS = {
  increaseText: '#00695C',
  decreaseText: '#C62828',
  initialText: '#081D37DE',
};

const RenderColorCell: React.FC<RenderColorCellProps> = React.memo(({ value }) => {
  const [textColor, setTextColor] = useState<string>(COLORS.initialText);
  const previousValueRef = useRef<number>(value ?? 0);

  useEffect(() => {
    const previousValue = previousValueRef.current;

    if (value != null && value !== previousValue) {
      const newTextColor = value > previousValue ? COLORS.increaseText : COLORS.decreaseText;
      setTextColor(newTextColor);
      previousValueRef.current = value;
    }
  }, [value]);

  return (
    <span style={{ color: textColor }}>{value ?? 0}</span>
  );
});

export default RenderColorCell;
