import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  Chip,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import { InstrumentMarketData } from "../../../types";
import { Contract } from "./types";
import { useState, useEffect } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";

interface MarketMobileModalProps {
  open: boolean;
  onClose: () => void;
  instrument?: InstrumentMarketData;
  contract: Contract[];
}

const MarketMobileModal = ({ open, onClose, instrument, contract }: MarketMobileModalProps) => {
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

  const handleContractSelect = (selected: Contract) => {
    setSelectedContract(selected);
  };

  const getContractName = (contract: Contract) => {
    return `${contract?.displayName} - ${contract?.month.toUpperCase()} ${contract?.title.substr(contract?.title.length - 2)}`;
  };

  useEffect(() => {
    if (contract?.length > 0) {
      setSelectedContract(contract[0]);
    }
  }, [contract]);

  const changeCell = (row: any) => {
    const color = row?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";

    if (row?.labelPriceNetChange) {
      return (
        <div style={{ color }}>
          <span>
            {row?.labelPriceNetChange?.toFixed(2)} {`(${row?.labelPriceNetChangePercent?.toFixed(2)}%)`}
          </span>
        </div>
      );
    }

    return <span></span>;
  };

  const rowData = [
    {
      labels: ["Last Price", "Change", "Volume"],
      values: [selectedContract?.lastPrice?.toFixed(2), changeCell(selectedContract), selectedContract?.labelTotalVolume],
    },
    {
      labels: ["Prior Settle", "High", "Low"],
      values: [selectedContract?.labelOpen, selectedContract?.labelHigh, selectedContract?.labelLow],
    },
  ];
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "background.paper",
          padding: 2,
          paddingTop: 0,
          border: "1px solid white",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>{instrument?.name}</Typography>
          <IconButton onClick={onClose} edge="end" color="inherit">
            <GridCloseIcon />
          </IconButton>
        </Box>

        <div className="contract-chip-container">
          {contract?.map((contractItem) => {
            const isSelected = selectedContract?.contractId === contractItem?.contractId;
            return (
              <Chip
              key={contractItem?.contractId}
              label={getContractName(contractItem)}
              onClick={() => handleContractSelect(contractItem)}
              sx={{
                cursor: 'pointer',
                backgroundColor: isSelected ? '#006EB6' : '#e0e0e0',
                color: isSelected ? 'white' : 'inherit',
                borderColor: isSelected ? '#006EB6' : 'transparent',
                '&:hover': {
                  backgroundColor: isSelected ? '#006EB6' : '#d4d4d4',
                },
              }}
            />
            );
          })}
        </div>

        {selectedContract && (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: "400px", overflowY: "auto" }}>
              <Table aria-label="instrument data">
                <TableBody>
                  {rowData.map((row, index) => (
                    <>
                      <TableRow
                        sx={{
                          backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#f5f5f5",
                          height: "35px",
                          border: "none",
                        }}
                      >
                        {row?.labels.map((label, i) => (
                          <TableCell sx={{ padding: "4px" }} key={`label-${i}`}>
                            <Typography variant="body1">{label}</Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow sx={{ height: "35px", border: "none" }}>
                        {row?.values.map((value, i) => (
                          <TableCell sx={{ padding: "2px" }} key={`value-${i}`}>
                            <Typography variant="body2">{value}</Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12} paddingTop={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="outlined" className="chart-price-ladder-button">
                    CHART
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" className="chart-price-ladder-button">
                    PRICE LADDER
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" className="trade-button">
                    TRADE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MarketMobileModal;
