import { useEffect, useState } from "react";
import "./DigitalTime.css";
import moment from "moment";
import "moment-timezone";

const TIME_ZONE = "America/Chicago";
const DATE_TIME_FORMAT = "hh:mm:ssa [CST] DD/MM/YYYY";

const DigitalTime = () => {
  moment.tz.setDefault("UTC");
  const [dateTime, setDateTime] = useState<string>(moment().tz(TIME_ZONE).format(DATE_TIME_FORMAT));

  useEffect(() => {
    const updateTime = () => {
      setDateTime(moment().tz(TIME_ZONE).format(DATE_TIME_FORMAT));
    };

    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="digital-time-container">
      <div className="digital-time">{dateTime}</div>
    </div>
  );
};

export default DigitalTime;
