import { DockviewGroupPanelFloatingChangeEvent, IDockviewHeaderActionsProps } from "dockview";
import { useEffect, useState } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ExpandIcon from "./ExpandIcon";
import PopupIcon from "./PopupIcon";
import PopinIcon from "./PopinIcon";

const iconStyle = { cursor: "pointer", height: "18px", width: "18px", fill: "rgb(8, 29, 55)", fillOpacity: "0.56" };

const RightHeaderControls = (props: IDockviewHeaderActionsProps) => {
  const [isMaximized, setIsMaximized] = useState(props.containerApi.hasMaximizedGroup());
  const [isFloating, setIsFloating] = useState(props.api.location.type === "floating");

  useEffect(() => {
    const handleMaximizedChange = () => setIsMaximized(props.containerApi.hasMaximizedGroup());
    const handleLocationChange = (event: DockviewGroupPanelFloatingChangeEvent) =>
      setIsFloating(event.location.type === "floating");

    const disposable = props.containerApi.onDidMaximizedGroupChange(handleMaximizedChange);
    const disposableLocation = props.api.onDidLocationChange(handleLocationChange);

    return () => {
      disposable.dispose();
      disposableLocation.dispose();
    };
  }, [props.containerApi, props.api]);

  const toggleMaximize = () => {
    if (isMaximized) {
      props.containerApi.exitMaximizedGroup();
    } else {
      props.activePanel?.api.maximize();
    }
  };

  const toggleFloating = () => {
    if (isFloating) {
      const group = props.containerApi.addGroup();
      props.group.api.moveTo({ group });
    } else {
      props.containerApi.addFloatingGroup(props.group, {
        position: { bottom: 150, right: 500 },
        height: 600,
        width: 800
      });
      props.group.locked = "no-drop-target";
    }
  };

  return (
    <div
      className="group-control"
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0px 8px",
        height: "100%",
        color: "var(--dv-activegroup-visiblepanel-tab-color)",
        backgroundColor: "#EBEDEE66",
        gap: "12px"
      }}
    >
      {!isFloating &&
        (isMaximized ? (
          <CloseFullscreenIcon style={iconStyle} onClick={toggleMaximize} />
        ) : (
          <span style={{cursor: 'pointer'}} onClick={toggleMaximize}><ExpandIcon /></span>        
        ))}
      {isFloating ? (
        <span style={{cursor: 'pointer'}} onClick={toggleFloating}><PopinIcon /></span>        
      ) : (
        <span style={{cursor: 'pointer'}} onClick={toggleFloating}><PopupIcon /></span>        
      )}
    </div>
  );
};

export default RightHeaderControls;
