import { Control, UseFormWatch } from "react-hook-form";
import TakeProfitandLossSection from "./TakeProfitandLossSection";
import { Instrument } from "../../../../../cqg-api/models/Instrument";
import { IFormInput } from "../../../../TradeTicketDrawer/types";

interface TakeProfitandLossProps {
  control: Control<IFormInput, any>;
  setValue: any;
  watch: UseFormWatch<IFormInput>;
  selectedContract: Instrument;
  limitPrice?: number;
}

const TakeProfitandLoss = ({ control, setValue, watch, selectedContract, limitPrice }: TakeProfitandLossProps) => {
  const orderSide = watch("side");
  const orderType = watch("orderType");

  return (
    <>
      <TakeProfitandLossSection
        control={control}
        setValue={setValue}
        watch={watch}
        orderSide={orderSide}
        label="Take Profit"
        sectionKey="takeProfit"
        key={orderSide + orderType + "takeProfit"}
        selectedContract={selectedContract}
        limitPriceValue={limitPrice}
      />
      <div className="dividerTrade"></div>
      <TakeProfitandLossSection
        control={control}
        setValue={setValue}
        watch={watch}
        orderSide={orderSide}
        label="Stop Loss"
        sectionKey="stopLoss"
        key={orderSide + orderType + "stopLoss"}
        selectedContract={selectedContract}
        limitPriceValue={limitPrice}
      />
      <div className="dividerTrade"></div>
    </>
  );
};

export default TakeProfitandLoss;
