import { Slide } from "@mui/material";
import "./ProfileSection.css";
import Mode from "./Mode";
import Footer from "./Footer";
import Pages from "./Pages";
import UserInfo from "./UserInfo";
import DigitalTime from "./DigitalTime/DigitalTime";
import { useState } from "react";
import { Mode_Type } from "../../../../types";

interface ProfileSectionProps {
  isMobileProfileViewVisible: boolean;
  onMenuItemClick: (component: JSX.Element | null) => void;
}

const ProfileSection = ({ isMobileProfileViewVisible, onMenuItemClick }: ProfileSectionProps) => {
  const [mode, setMode] = useState<any>(Mode_Type.PRACTICE_MODE);
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  const handleModeReset = () => {
    setMode(Mode_Type.PRACTICE_MODE);
  };

  return (
    <Slide direction="up" in={isMobileProfileViewVisible} mountOnEnter unmountOnExit>
      <div className="profile-slider-container">
        <UserInfo />
        <Mode mode={mode} onModeChange={handleModeChange} onModeReset={handleModeReset} />
        <hr />
        <Pages onMenuItemClick={onMenuItemClick} mode={mode} />
        <hr />
        <Footer />
        <DigitalTime />
      </div>
    </Slide>
  );
};

export default ProfileSection;
