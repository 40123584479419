import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import './positions.css';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import './flattenModal.css';
import clsx from "clsx";
import useStyles from './styles';
import { getPrice } from '../../utils/getPrice';
import { convertSymbolToYear } from '../tree-view/utils/convertSymbolToDate';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type FlattenPositionProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmFlatten: () => void;
  data: any;
};
export default function FlattenPosition({ open, onClose, data, handleConfirmFlatten }: FlattenPositionProps) {
  const classes = useStyles();

  if (!data) return null;
  const toggleDrawer =
    () =>
    () => {
      onClose();
    };
  const isOption = data.strike !== 0;
  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      onKeyDown={toggleDrawer()}
      sx={{ padding: '0 6px' }}
    >
      <Box justifyContent={"space-between"} sx={{ padding: '2px 6px', display: 'flex' }} alignItems="center">
        <div className={classes.contractTitle}>{data.contract}</div>
        <Button sx={{ minWidth: 0 }} variant="text" onClick={toggleDrawer()}>
          <CloseIcon sx={{ color: '#5A6874' }} />
        </Button>
      </Box>
      <div className={clsx('tableData', { threeCol: !isOption })}>
        <div className={classes.tableCell}>Contract</div>
        <div className={classes.tableCell}>Month</div>
        <div className={classes.tableCell}>PL</div>
        {isOption && <div className={classes.tableCell}>C/P</div>}
        <div className={classes.tableCellBody}>{data.symbol}</div>
        <div className={classes.tableCellBody}>{convertSymbolToYear(data.symbol, data.month)}</div>
        <div className={classes.tableCellBody}>{getPrice(data.realizedPL, data.unrealizedPL)}</div>
        {isOption && <div className={classes.tableCellBody}>{data.cp}</div>}
        <div className={classes.tableCell}>Position</div>
        <div className={classes.tableCell}>QTY</div>
        <div className={classes.tableCell}>Avg P/X</div>
        {isOption && <div className={classes.tableCell}>Strike</div>}
        <div className={classes.tableCellBody}>{data.direction}</div>
        <div className={classes.tableCellBody}>{data.size}</div>
        <div className={classes.tableCellBody}>{data.averagePX}</div>
        {isOption && <div className={classes.tableCellBody}>{data.strike}</div>}

      </div>

      <Divider />
      <div className='flex w-full justify-between p-2 ActionsButtons'>
        <button
            className="mobile-action-button cancel-all-orders"
          >
            TRADE
          </button>
          <button
            onClick={handleConfirmFlatten}
            className={`mobile-action-button cancel-all-orders ${data.direction === "" ? "disabled" : "active"}`} 
            disabled={data.direction === ""} 
          >
            FLATTEN
          </button>
        </div>
    </Box>
  );

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
    >
      {list("bottom")}
    </Drawer>
  );
}
