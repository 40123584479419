import { Grid, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import MinusIcon from "../../../../TradeTicketDrawer/MinusIcon";
import PlusIcon from "../../../../TradeTicketDrawer/PlusIcon";
import { onlyNumbersRules } from "../../utils";
import { inputStyles } from "../../../../TradeTicketDrawer/styles";
import { IFormInput } from "../../../../TradeTicketDrawer/types";
import { Instrument } from "../../../../../cqg-api/models/Instrument";
import { DisplayUtil } from "../../../../../cqg-api/utils/DisplayUtil";

interface PriceProps {
  control: Control<IFormInput, any>;
  setValue: (name: string, value: number) => void;
  name: any;
  tickSize: number;
  onPriceChange: (price: number) => void;
  selectedContract: Instrument;
}

const Price = ({ control, setValue, name, tickSize, onPriceChange, selectedContract }: PriceProps) => {
  const handleValueChange = (adjustment: number, currentValue: number) => {
    const newValue = currentValue + adjustment;
    const displayPrice = DisplayUtil.toDisplayPrice(newValue, selectedContract)
    if ( !!displayPrice ) {
      setValue(name, displayPrice);
      onPriceChange(displayPrice);
    }
  };

  return (
    <Grid item xs={6} sx={{ padding: 0 }}>
      <label className="label">Price</label>
      <div className="control">
        <Controller
          name={name}
          control={control}
          rules={onlyNumbersRules}
          render={({ field }) => (
            <>
              <button type="button" className="minusButton" onClick={() => handleValueChange(-tickSize, field.value)}>
                <MinusIcon />
              </button>

              <TextField
                {...field}
                type="number"
                fullWidth
                value={field.value}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  field.onChange(newValue);
                  onPriceChange(newValue);
                }}
                variant="outlined"
                sx={inputStyles}
              />

              <button type="button" className="minusButton" onClick={() => handleValueChange(tickSize, field.value)}>
                <PlusIcon />
              </button>
            </>
          )}
        />
      </div>
    </Grid>
  );
};

export default Price;
