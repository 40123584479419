import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#607D8B",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#607D8B",
    fontFamily: "Averta-Regular",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "14px",
    padding: "4px 8px 4px 8px",
    borderRadius: "4px",
  },
}));
