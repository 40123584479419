import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

export const selectStyle = {
  minWidth: "fit-content",
  width: '50%',
  fontSize: '14px',
  padding: '5px',
  fontFamily: 'Averta-Regular',
  '& .MuiSelect-select': {
    padding: '5px',
    fontSize: '12px',
    background: 'white',
  },
};
export type FilterProductsProps = {
  value: string;
  selectFilter: (event: string) => void;
};
const FilterProducts = ({ value, selectFilter }: FilterProductsProps) => {
  const handleFilterSelection = (event: SelectChangeEvent<string>) => {
    selectFilter(event.target.value);
  }
  return (
    <Select
      sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleFilterSelection}
    >
      {["All Positions", "Open", "Closed"].map(value => (
        <MenuItem key={value} value={value} sx={{ fontSize: "14px", fontFamily: "Averta-Regular" }}>
          {value}
        </MenuItem>
      ))}
    </Select>
)};

export default FilterProducts;
