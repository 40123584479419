import { useGetCurrentUser } from "../../hooks/api/authentication";
import useInitiateCQGEnvironment from "../../hooks/api/subscription";
import Layout from "./Layout";

const WidgetHome = (props: any) => {
  const currentUser = useGetCurrentUser();
  useInitiateCQGEnvironment(currentUser);

  return (
    <Layout {...props}>
    </Layout>
  );
};

export default WidgetHome;
